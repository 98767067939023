
import React, { useState, useContext, useEffect } from "react";

import Select from "react-select";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";

import { statusList } from "../../moondance/helpers/formatData";
import { fetchOwners } from "../../moondance/helpers/api";
import { MoondanceContext } from '../../App';
//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "./rightbar.scss";

//Import images
import layout1 from "../../assets/images/layouts/layout-1.png";
import layout2 from "../../assets/images/layouts/layout-2.png";
import layout3 from "../../assets/images/layouts/layout-3.png";

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";

const RightSidebar = props => {

  const { accountList} = useContext(MoondanceContext);
  const { selectedAccountList , setSelectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus = '', setSelectedStatus } = useContext(MoondanceContext);


  const [selectedStatusList, setSelectedStatusList] = useState([
    { label: "Open", value: 1 },

  ]);

  
  



  function handleSelectAccount(e) {
    var selected = ''
    var selectedList = []
    e.forEach(element => {
      selected += element.value + ','
      selectedList.push({ value: element.value, label: element.label })
    });
    //setSelectedOwner(selected)
  //  setSelectedAccountList(selectedList)

  }
  function handleSelectStatus(e) {
    var selected = ''
    var selectedList = []
    e.forEach(element => {
      selected += element.value + ','
      selectedList.push({ value: element.value, label: element.label })
    });
    
   // setSelectedStatus(selectedList)
   // setSelectedStatusList(selectedList)

  }

  return (
    <React.Fragment>
      <div className="right-bar" id="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  props.showRightSidebarAction(false);
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Filter</h5>
            </div>

            <hr className="my-0" />

            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Filter by account</span>
                <div className="position-relative">
                  <Select
                    value={selectedAccountList}
                    onChange={(f) => {
                      handleSelectAccount(f);
                    }}
                    placeholder="Select Account"
                    isMulti={true}
                    options={accountList}
                    classNamePrefix="select2-selection"
                  />

                </div>

              </div>
            </div>

            <hr className="mt-1" />
            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Filter by status</span>
                <div className="position-relative">
                  <Select
                    value={selectedStatusList}
                    onChange={(f) => {
                      handleSelectStatus(f);
                    }}
                    placeholder="Select Status"
                    isMulti={true}
                    options={statusList}
                    classNamePrefix="select2-selection"
                  />

                </div>

              </div>


            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  );
};

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  layoutType: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any,
  onClose: PropTypes.func,
};

const mapStateToProps = state => {
  return { ...state.Layout };
};

export default connect(mapStateToProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  showRightSidebarAction,
})(RightSidebar);
