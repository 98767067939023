
import React, {  useContext } from "react";
import {    Card, CardBody  } from "reactstrap";
import { MoondanceContext } from '../../App';

const PortraitAlert = () => {
    
    const { portraitMode } = useContext(MoondanceContext);

    return (
        (portraitMode ?
            <Card >
                <CardBody>
                    <span className=" fas fa-redo"></span> Rotate your device to display more data and options
                </CardBody>
            </Card>
            : '')
    )
}

export default PortraitAlert;