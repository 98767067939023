
import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, CardTitle, Col, Row, Container, Button } from "reactstrap";

import { isValidEmail } from "../helpers/formatData";
import UseMoondanceApi from "../helpers/api";
import MoondanceLayout from "../helpers/layout.js";

import { Link, useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';
import Select from "react-select";
import { last } from "lodash";
//MoondanceApi.exportAccount,createAccount, inviteUser, listAccountUsers, updateUserAccess,  fetchOwners,   updateAccountName } 


const formatOptions = ({ data, innerProps, isFocused }) => {
    const { label, description, value } = data;
    return (
        <div {...innerProps} value={value} style={{ padding: '0.4em', backgroundColor: isFocused ? '#deebff' : 'white' }}>
            <div>{label}</div>
            {(description ? <div style={{ fontSize: '90%', }}>{description}</div> : '')}
        </div>
    );
};
const countAdmins = (array) => {
    return array.filter(item => item && item.access_type === "admin").length;
}


const Accounts = () => {

    const[ user_data, setUserData] = useState([]);
    const moondanceApi = UseMoondanceApi();
    //const [id, setId] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [initialValue] = useState({});
    const [submitResult, setSubmitResult] = useState(false);
    const [showEditAccountName, setShowEditAccountName] = useState(0);
    const [showAddAccountName, setShowAddAccountName] = useState(0);
    const [accessRightTypes] = useState([{ label: 'Admin', id: 'admin' }, { label: 'User', id: 'user' }, { label: 'Read Only', id: 'read' }, { label: 'No Access', id: 'NO' }]);
    const [newAccessRights, setNewAccessRights] = useState({});
    const [showAddUser, setShowAddUser] = useState(0);
    const [addNewUser, setAddNewUser] = useState({ name: '', email: '', access: 'user', account_id: '' });
    const [addUserError, setAddUserError] = useState(false);
    const [activeAccountForm, setActiveAccountForm] = useState(0);
    const [newAccountName, setNewAccountName] = useState('Your Account');

    const { lastUpdate } = useContext(MoondanceContext);
    const [accountAccessRights, setAccountAccessRights] = useState({
        "1": [
            {
                // data for account 1
            }
        ],
        "2": [
            {
                // data for account 2
            }
        ],
    });
    // const [errors, setErrors] = useState({});

    function fetchUserProfile() {
        moondanceApi.getLoggedinUser().then(profile => {

            if (profile) {
                var initValue = initialValue;
                setUserData(profile.data);
              

            }   


        }).catch(errors => {
            console.log(errors)
        });
    }
    function fetchAccounts() {




        moondanceApi.fetchAccounts(Date.now()).then(response => {

            if (response.data.length > 0) {
                console.log(response.data);
                setAccounts(response.data);
                const tmpAccessRights = {}; // Initialize a temporary object
                const adminPromises = [];
                const newAccountAccessRights = [];
                response.data.forEach(element => {

                    if (element.access === 'admin') {
                        newAccountAccessRights[element.id.toString()] = newAccountAccessRights[element.id.toString()] || [];
                        tmpAccessRights[element.id.toString()] = tmpAccessRights[element.id.toString()] || [];
                        adminPromises.push(
                            moondanceApi.listAccountUsers(element.id)
                                .then(listAccountsUsers => {
                                    if (listAccountsUsers.data.length > 0) {
                                        listAccountsUsers.data.forEach(user => {
                                            tmpAccessRights[element.id.toString()][user.user_id] = user
                                            newAccountAccessRights[element.id.toString()][user.user_id.toString()] = user.access_type;
                                        });
                                    }
                                })
                                .catch(errors => {
                                    console.log(errors);
                                })
                        );
                    }
                });
                Promise.all(adminPromises).then(() => {
                    setAccountAccessRights(tmpAccessRights); // Update state after all promises are resolved
                    setNewAccessRights(newAccountAccessRights);
                    console.log(tmpAccessRights); // Now this should reflect the updated state
                });
            } else {
                console.log('No account found');
            }
        }).catch(error => {
            console.log(error);
        });
    }

    useEffect(() => {
        fetchUserProfile();
        fetchAccounts();
    }, [submitResult, lastUpdate]);






    function setAccountName(id, value) {
        //  console.log(id)
        // console.log(value)
        //console.log(accounts)
        var tmp = accounts
        tmp.map((account, index) => (
            account.id === id ? account.n = value : ''
        ))
        setAccounts(tmp)
        //console.log(tmp)
    }

    function saveAccountName(id) {
        var requestData = {
            id: id,
            name: accounts.find(item => item.id === id).n
        }


        moondanceApi.updateAccountName(requestData).then(response => {
            //console.log(response)
            setShowEditAccountName(0)

        }).catch(error => {
            setSubmitResult(error)
            console.log(submitResult)
            return false
        });

    }
    function setAccessType(account_id, user_id, value) {
        setNewAccessRights(prevRights => {
            const updatedRights = { ...prevRights };
            if (!updatedRights[account_id]) {
                updatedRights[account_id] = {};
            }
            updatedRights[account_id][user_id] = value;
            return updatedRights;
        });



    }

    function addNewUserData(id, value) {
        var tmpNewUser = addNewUser;
        tmpNewUser[id] = value;
        setAddNewUser(tmpNewUser);

    }

    function createNewAccount() {
        console.log(newAccountName)
        var requestData = {
            name: newAccountName
        }

        console.log(requestData)

        moondanceApi.createAccount(requestData).then(response => {
            //console.log(response)
            setShowEditAccountName(0)
            showAddAccountName(0)
            fetchAccounts();

        }).catch(error => {
            setSubmitResult(error)
            console.log(submitResult)
            return false
        });
    }

    function addNewuser(account_id) {
        var tmpNewUser = addNewUser;
        tmpNewUser['account_id'] = account_id;
        setAddNewUser(tmpNewUser);
        setActiveAccountForm(account_id)

        if (!isValidEmail(tmpNewUser['email'])) {
            setAddUserError('Please enter a valid email address !')
        } else {
            setAddUserError(false)

            var requestData = {
                account_id: account_id,
                user_name: (tmpNewUser['name'] !== '' ? tmpNewUser['name'] : tmpNewUser['email']),
                user_email: tmpNewUser['email'],
                user_access: tmpNewUser['access'],

            }


            moondanceApi.inviteUser(requestData).then(response => {

                response.accountId = account_id
                setSubmitResult(response)
                console.log(response)

                setShowAddUser(0)

            }).catch(error => {
                setSubmitResult(error)
                console.log(submitResult)
                return false
            });


        }
        console.log(tmpNewUser)

    }
    function confirmSetAccessType(account_id, user_id, value) {

        var requestData = {
            id: account_id,
            user_id: user_id,
            access_type: value

        }
        moondanceApi.updateUserAccess(requestData).then(response => {
            setSubmitResult(response)
            console.log(response)
            setNewAccessRights(prevRights => {
                const updatedRights = { ...prevRights };
                if (!updatedRights[account_id]) {
                    updatedRights[account_id] = {};
                }
                updatedRights[account_id][user_id] = 'confirmed';
                return updatedRights;
            });

        }).catch(error => {
            setSubmitResult(error)
            console.log(submitResult)
            return false
        });
    }

    function exportToExcel(id) {
        var requestData = {
            accountId: id
        }
        moondanceApi.exportAccount(requestData).then(response => {
            console.log(response)

        }).catch(error => {
            setSubmitResult(error)
            console.log(submitResult)
            return false
        });


    }


    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>

                        <form className="user">
                            <h1><i className="mdi mdi-account-group"></i> Your account(s)</h1> <hr />


                            {accounts.length > 0 ?
                                accounts.map((account, index) => (
                                    <Row className="mb-3" >

                                        <Card >
                                            <CardBody>

                                                {(account.access === 'admin' ?
                                                    <>
                                                        {(showEditAccountName === account.id ?

                                                            <Row style={{ marginBottom: '1em' }}>
                                                                <Col xl="6" sm="6">
                                                                    <input type="text" className="form-control "
                                                                        defaultValue={account.n}
                                                                        onChange={e => setAccountName(account.id, e.target.value)}
                                                                    />
                                                                </Col>
                                                                <Col xl="3" sm="3">
                                                                    <Button className="btn btn-outline-secondary " onClick={() => { saveAccountName(account.id) }}><i className="fas fa-edit"></i>{" "}Save</Button>

                                                                </Col>
                                                            </Row>
                                                            :
                                                            <div style={{ marginBottom: '1em', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <h3 >Account : {account.n}</h3>
                                                                <div>
                                                                    <Button className="btn btn-outline-primary" onClick={() => { setShowEditAccountName(account.id) }}>
                                                                        <i className="fas fa-edit"></i>{" "}Change Account Name
                                                                    </Button>&nbsp;
                                                                    <Button className="btn btn-outline-primary" onClick={() => { exportToExcel(account.id) }}><i className="mdi mdi-microsoft-excel"></i>{" "}Export data to Excel</Button>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {(accountAccessRights[account.id]) ? (
                                                            <div  ><hr />
                                                                <h4 >Users access</h4>
                                                                <MoondanceLayout.MoondanceTable
                                                                    rows={accountAccessRights[account.id]}
                                                                    defaultSort={{ key: 'user.last_name', direction: 'ascending' }}
                                                                    columns={[

                                                                        {
                                                                            label: 'Name',
                                                                            field: 'last_name',
                                                                            customFormat: (row) => { return (row.first_name + ' ' + row.last_name) }
                                                                        },
                                                                        {
                                                                            label: 'Email',
                                                                            field: 'email',
                                                                        },
                                                                        {
                                                                            label: 'Access',
                                                                            field: 'access_type',
                                                                            customFormat: (row) => {

                                                                                if (row.access_type !== 'admin' || countAdmins(accountAccessRights[account.id]) > 1) {

                                                                                    return (
                                                                                        <Select
                                                                                            menuPortalTarget={document.body}
                                                                                            menuPosition={'fixed'}
                                                                                            defaultValue={accessRightTypes.find(option => option.id === row.access_type)}
                                                                                            onChange={selectedOption => setAccessType(account.id, row.user_id, selectedOption.id)}
                                                                                            placeholder="Select Access"
                                                                                            options={accessRightTypes}
                                                                                            classNamePrefix="select2-selection"
                                                                                            styles={MoondanceLayout.select2customStyles}
                                                                                        />
                                                                                    )
                                                                                } else {
                                                                                    return (<p>{accessRightTypes.find(option => option.id === row.access_type).label} <span className="badge bg-light"><i className="fas fa-exclamation-circle"></i>{" "}At least one user must be admin !</span></p>)
                                                                                }
                                                                            }

                                                                        },
                                                                        {
                                                                            label: 'Action',
                                                                            customFormat: (row) => {

                                                                                const newAccessForUser = newAccessRights[account.id?.toString()]?.[row.user_id?.toString()];
                                                                                if (newAccessForUser && newAccessForUser !== row.access_type && newAccessForUser !== 'confirmed') {
                                                                                    return <Button className="btn btn-outline-danger " onClick={() => { confirmSetAccessType(account.id, row.user_id, newAccessForUser) }}><i className="fas fa-exclamation-circle"></i>{" "}Please confirm !</Button>;
                                                                                } else if (newAccessForUser && newAccessForUser === 'confirmed') {
                                                                                    return <span ><i className="fas fa-thumbs-up"></i>{" "} Change confirmed !</span>;
                                                                                }
                                                                            }


                                                                        }


                                                                    ]}

                                                                />



                                                                {((showAddUser === account.id || submitResult && submitResult.success) ? (

                                                                    <Row style={{ marginBottom: '1em' }}>
                                                                        <>
                                                                            <hr />
                                                                            <Col xl="3" sm="3"><input type="text" onChange={e => addNewUserData('name', e.target.value)} placeholder="Enter user name" className="form-control "></input></Col>
                                                                            <Col xl="3" sm="3"><input type="email" onChange={e => addNewUserData('email', e.target.value)} className="form-control " placeholder="Enter user email"></input></Col>
                                                                            <Col xl="3" sm="3">
                                                                                <Select
                                                                                    onChange={selectedOption => addNewUserData('access', selectedOption.id)}
                                                                                    defaultValue={accessRightTypes.find(option => option.id === 'user')}

                                                                                    placeholder="Select Access"
                                                                                    options={accessRightTypes}
                                                                                    classNamePrefix="select2-selection"
                                                                                    styles={MoondanceLayout.select2customStyles}
                                                                                />
                                                                            </Col>
                                                                            <Col xl="3" sm="3">
                                                                                <Button
                                                                                    className="btn btn-outline-primary "
                                                                                    onClick={() => { addNewuser(account.id) }}
                                                                                >
                                                                                    <i className="fas fa-exclamation-circle"></i>{" "}Invite user !
                                                                                </Button>
                                                                            </Col>
                                                                        </>

                                                                        {(addUserError ? <p className="text-danger">{addUserError}</p> : <p></p>)}
                                                                        {(submitResult && submitResult.error && activeAccountForm === account.id) && <p className="text-danger">An error occured while adding this user  :  {submitResult.error}</p>}


                                                                    </Row>
                                                                ) :
                                                                    <Row key={index}>
                                                                        {(submitResult && submitResult.success && submitResult.accountId === account.id) &&
                                                                            <p className="text-success">The invitation has been sent</p>

                                                                        }
                                                                        <div className="d-flex flex-wrap gap-2">
                                                                            <Button className="btn  btn-outline-primary" onClick={() => { setShowAddUser(account.id) }}><i className="fas fa-plus-circle"></i>{" "}Invite more user</Button>

                                                                        </div>
                                                                    </Row>
                                                                )}  </div>

                                                        ) : <p></p>}
                                                    </>
                                                    : <Row>
                                                        <div >
                                                            <p key={account.n} >
                                                                Account : {account.n} ( No admin access )                                                        </p>

                                                        </div>
                                                    </Row>


                                                )}
                                            </CardBody>
                                        </Card >

                                    </Row>
                                )) : (<p> You currently don't have access to any accounts. You can request an invitation from someone with admin rights, or you can create a new account.</p>)}


{( user_data.max_accounts_allowed <= user_data.current_accounts_count ? 
<p>You have reached the maximum number of accounts ({user_data.max_accounts_allowed}) included in your subscription. Please contact us if you wish to upgrade your subscription to add more accounts</p>

:                           (showAddAccountName !== 1) ?
<>
<p>Your subscription allows you to add {user_data.max_accounts_allowed - user_data.current_accounts_count} more accounts. Please <Link to="/contact" > contact us</Link> if you wish to upgrade your subscription to increase this limit </p>
                                <Button className="btn btn-primary " onClick={() => { setShowAddAccountName(1) }}>
                                    <i className="fas fa-edit"></i>{" "} Add an account
                                </Button>
                                </>
                                :

                                <Row style={{ marginBottom: '1em' }}>
                                    <Col xl="9" sm="9">
                                        <input type="text" className="form-control "
                                            defaultValue={newAccountName}
                                            onChange={e => setNewAccountName(e.target.value)}
                                        />
                                    </Col>
                                    <Col xl="3" sm="3">
                                        <Button className="btn btn-outline-secondary " onClick={() => { createNewAccount() }}><i className="fas fa-edit"></i>{" "}Save</Button>

                                    </Col>
                                </Row>

)}





                        </form>

                    </Row>
                </Container>
            </div >
        </React.Fragment >




    )

}

/*<input type="text" className="form-control "
                    defaultValue={account.n}
                    onChange={e => setAccountName(account.id, e.target.value)}
                />*/

/*
 <Row className="mb-3">
                    <label for="email">Email</label>
                    <input type="email" className="form-control "
                        id="signup_form_email" required />
 
                    <p id="signup_form_email-error" className="error mt-2 text-danger"></p>
                </Row>
                <Row className="mb-3">
 
                    <label for="email">Choose a password</label>
                    <input type="password" className="form-control "
                        id="signup_form_password" placeholder="Password" />
 
                    <p id="signup_form_password-error" className="error mt-2 text-danger"></p>
                </Row>
                <Row className="mb-3">
 
                    <label for="email">Repeat password</label>
                    <input type="password" className="form-control "
                        id="signup_form_confirm_password" placeholder="Password" />
 
                    <p id="signup_form_confirm_password-error" className="error mt-2 text-danger"></p>
                </Row>
                <Row className="mb-3">
 
                    <label for="email">Multiple factor authentication</label>
                    <select className="form-control" id="signup_form_MFA">
                        <option value="null">No Double factor authentication (NOT Recommended)</option>
                        <option value="sms">Send a confirmation code by SMS each time I try to login from an unknown device</option>
                        <option value="email">Send a confirmation code by EMAIL each time I try to login from an unknown device</option>
                    </select>
                </Row>
                */

export default Accounts;