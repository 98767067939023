
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, Col, Row, 
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { MoondanceContext } from '../../App';
import MoondanceLayout from "../helpers/layout.js";
import UseMoondanceApi from "../helpers/api";
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Select from "react-select";



const Logs = (props) => {
    const moondanceApi = UseMoondanceApi();
    const { lastUpdate } = useContext(MoondanceContext);
    const [date, setDate] = useState(MoondanceLayout.formatDate(new Date(), 'yyyy-mm-dd'))
    const [count, setCount] = useState(2000)
    const [log_keyword, set_log_keyword] = useState("");
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [logStatusList, setLogStatusList] = useState([
        { value: null, label: 'All' },

        { value: 'LOGIN_SUCCESS', label: 'Login' },
        { value: 'ALERT', label: 'Alert' },
        { value: 'BUG', label: 'Bug' },
        { value: 'SUSPICIOUS_ACTIVITY', label: 'Suspicious activities' },
        
    ]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userId, setUserId] = useState(false);
    
    const [logsData, setLogsData] = useState()

    const  [columns, setColumns] = useState([
        { label: 'Id', field: 'id' },
        { label: 'Status', field: 'status' },
        { label: 'user ID', field: 'user_id' },
        { label: 'user Name', field: 'name' },
        { label: 'Date', field: 'timestamp', customFormat: (row) => { return row.full_date } },
        { label: 'event', field: 'event' },


    ])
    const  [adminColumns, setAdminColumns] = useState([
        { label: 'Id', field: 'id' },
        { label: 'Status', field: 'status' },
        { label: 'user ID', field: 'user_id' },
        { label: 'user Name', field: 'name' },
        { label: 'Date', field: 'timestamp', customFormat: (row) => { return row.full_date } },
        { label: 'event', field: 'event' },
        { label: 'request', field: 'request' },
        { label: 'ip', field: 'user_ip' },
        { label: 'user_agent', field: 'user_agent' },

    ])
        


    useEffect(() => {
        const fetchUserProfile = async () => {
            const profile = await moondanceApi.getLoggedinUser();
            console.log('fetchUserProfile',profile)
            if (
                profile
                && profile.data
                && profile.data.access_level
                && profile.data.access_level === 'admin') {
                
                if(props && props.user_id){
                    setUserId(props.user_id)
                }
                setIsAdmin(true);
              
            } else  if (
                profile
                && profile.data
                && profile.data.id
            )   {
                setIsAdmin(false);
                setUserId(profile.data.id)
            }
        };
        fetchUserProfile();
    }, [props]);

    
    async function getLogs() {
        if (!date) {
            var newDate = MoondanceLayout.formatDate(new Date(), 'yyyy-mm-dd')
            setDate(newDate)
        }
        try {
            var returnData = {}

            var requestData = {
                date: date,
                count: count,
                keyword: log_keyword,
                status: (selectedStatus =='all' ? '' : selectedStatus),
                user_id: (userId  ? userId : 0)
            }
            var data = await moondanceApi.admin_fetchLogs(requestData);
            setLogsData(data.data)
            console.log('MoondanceApi.fetchLogs from /logs', data)

        } catch (error) {
            console.log('MoondanceApi.fetchLogs', error);
        }

    }
    function handleSelectLogStatus(f) {

        if (f) {
            setSelectedStatus(f.value)
            if (f.value === 'all') {
                setSelectedStatus(null)
            }
        }   
    }


    useEffect(() => {
        console.log('useEffect getLogs',props)
        getLogs();
    }, [log_keyword,selectedStatus, date, count,userId]);



    return (

        < Row >

            <Col xl={12} sm={12}>

                <Card>
                    <CardBody>
                        <Row style={{ marginBottom: '1em' }}>
                            <h5>Logs</h5>


                            <Col xl={12} sm={12}>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label  column sm="2">Keyword :</Form.Label>
                                    <Col sm="8">

                                        <input
                                            className="form-control"
                                            type="text"
                                            value={(log_keyword ? log_keyword : '')}
                                            onChange={(e) => { set_log_keyword(e.target.value); }}
                                            placeholder="Search by Keyword"
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label column sm="2">Select Position</Form.Label>
                                        <Col sm="8">
                                            <Select
                                                id="logStatus"
                                                value={selectedStatus ?
                                                    logStatusList.find(option => option.value === parseInt(selectedStatus))
                                                    : 'All'}
                                                onChange={(f) => {  handleSelectLogStatus(f) ; //setSelectedStatus(f.value)
                                                }}
                                                placeholder="All Status"
                                                options={logStatusList}
                                               
                                                styles={MoondanceLayout.select2customStyles}
                                            />
                                           

                                        </Col>
                                    </Form.Group>

                                    <hr />
                                </Row>
                        {(logsData && logsData.length > 0 ? (
                            <MoondanceLayout.MoondanceTable
                                key={`logs-${lastUpdate}-${selectedStatus}-${log_keyword}`}

                                rows={logsData}
                                defaultSort={{ key: 'id', direction: 'descending' }}
                                paginationSize={25}
                                columns={(isAdmin ? adminColumns :  columns )}


                            />
                        ) : < p>No Data</p>
                        )}
                    </CardBody>
                </Card>
            </Col>
        </Row >


    )



}
export default Logs;