import React, { useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink,
} from "reactstrap";
import TopBar from '../publicWebsite/topbar.js';
import { Link } from "react-router-dom";
const GeneralSalesConditions = () => {
    useEffect(() => {
        document.title = "General Sales Conditions | Moondance";
    }, []);

    return (
       
        <React.Fragment>
            <TopBar />
            <Container style={{marginTop:"2em"}}>
            <h1>General Sales Conditions of Moondance</h1>
        
            <ol>
                <li>
                    <strong>Scope and Application</strong>
                    <p>These General Sales Conditions (GSC) apply to all services provided by Moondance, located in Belgium, to its clients concerning the management of private equity investments. By using any of our services, you agree to be bound by these terms and conditions.</p>
                </li>
                <li>
                    <strong>Services Provided</strong>
                    <p>Moondance offers tools for tracking commitments, consolidating cash flows, visualizing budgets, and anticipating future capital calls and distributions for private equity investments. Detailed reporting charts by sectors, geographies, types of funds, and vintages are also provided.</p>
                </li>
                <li>
                    <strong>Fees and Payment</strong>
                    <p>Fees for Moondance services will be specified in the service agreement signed by both parties. All fees are due within 30 days of invoicing and are subject to late payment interests as per Belgian law.</p>
                </li>
                <li>
                    <strong>Client Responsibilities</strong>
                    <p>Clients are responsible for providing accurate and complete information necessary for Moondance to perform its services effectively. This includes the responsibility for the accuracy, reliability, and confidentiality of the data entered, models used, and hypotheses made by the client using Moondance tools. Moondance is not responsible for any errors in data provided by the client or the confidentiality and accuracy of such data once entered into the system.</p>
                </li>
                <li>
                    <strong>Intellectual Property</strong>
                    <p>All intellectual property rights in the tools, reports, and other materials provided by Moondance remain the property of Moondance. Clients are granted a non-exclusive, non-transferable license to use provided materials for their internal business purposes.</p>
                </li>
                <li>
                    <strong>Confidentiality</strong>
                    <p>Moondance commits to maintaining the confidentiality of all client information and data provided during the use of our services, in accordance with Belgian privacy laws and regulations.</p>
                </li>
                <li>
                    <strong>Limitation of Liability</strong>
                    <p>Moondance will not be liable for any indirect or consequential losses arising out of or in connection with the use of our services. Liability for direct damages shall not exceed the amount paid by the client for the services during the 12 months preceding the claim. Moondance is also not liable for errors in data entered by the client or the outcomes of decisions made based on such data.</p>
                </li>
                <li>
                    <strong>Termination</strong>
                    <p>Either party may terminate the service agreement with a written notice if the other party breaches these conditions and fails to correct the breach within a reasonable period.</p>
                </li>
                <li>
                    <strong>Dispute Resolution</strong>
                    <p>Any disputes arising out of or related to these GSC shall be resolved through mediation, and if necessary, legal proceedings will be conducted in Belgium under Belgian law.</p>
                </li>
                <li>
                    <strong>Modifications to the GSC</strong>
                    <p>Moondance reserves the right to modify these GSC at any time. All modifications will be effective immediately upon posting on our website.</p>
                </li>
                <li>
                    <strong>Contact Information</strong>
                    <p>For any queries regarding these GSC, please contact us using the contact form avilable on the home page.</p>
                </li>
            </ol>
            
            <p>Moondance is a trademark owned by the company Cogespar SA, located at Avenue de la Gazelle 105 bte 9, B-1180 Uccle, Belgium. Registered in Belgium with company number 0439662792.</p>

            <Link to="/home" class="btn btn-sm btn-outline-primary">Back to Home Page </Link>
            </Container>
        </React.Fragment>
    );
};

export default GeneralSalesConditions;
