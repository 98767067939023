
import React, { useState, useContext, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import {
  Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button, Table,
  NavItem,
  NavLink, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";

import { useMemo } from 'react';
import MoondanceLayout from "../helpers/layout.js";
import { Link } from 'react-router-dom';
import { MoondanceContext } from '../../App.js';

//simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example


export default function PositionsSummary(props) {
  const [summary, setSummary] = useState({ data: {}, columns: {} });
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  const [actionDropDown_row, set_actionDropDown_row] = useState(false)
  const [actionDropDown_col, set_actionDropDown_col] = useState(false)
  const [actionDropDown_value, set_actionDropDown_value] = useState(false)
  const [row_key, set_row_key] = useState('asset_vintage_year')
  const [row_key_label, set_row_key_label] = useState('Vintage')

  const [col_key, set_col_key] = useState('asset_currency')
  const [col_key_label, set_col_key_label] = useState('Currency')
  const [value_key, set_value_key] = useState('position_last_nav_in_user_currency')
  const [value_key_label, set_value_key_label] = useState('Nav')
  useEffect(() => {
    let newSummary = {
      data: {}, columns: {}, row: {}, total: {

        count_position: 0,
        position_last_nav_in_user_currency: 0,
        position_commited_in_user_currency_at_inception_rate: 0,
        position_cumulated_distributions_in_user_currency: 0,
        position_expected_remaining_callable_capital_in_user_currency: 0
      }
    };



    if (props.data && props.data.listPositions && Array.isArray(props.data.listPositions)) {
      props.data.listPositions.forEach((element) => {
        var rowKey_value = element[row_key] !== '' ? element[row_key] : 'Undefined';
        var colKey_value = element[col_key] !== '' ? element[col_key] : 'Undefined';

        if (row_key === 'asset_type') {
          if (MoondanceLayout.assetType[rowKey_value]) {
            rowKey_value = MoondanceLayout.assetType[rowKey_value].label
          }
        }
        if (col_key === 'asset_type') {
          if (MoondanceLayout.assetType[colKey_value]) {
            colKey_value = MoondanceLayout.assetType[colKey_value].label
          }
        }

        if (!newSummary.data[rowKey_value]) {
          newSummary.data[rowKey_value] = {};
        }
        if (!newSummary.data[rowKey_value][colKey_value]) {
          newSummary.data[rowKey_value][colKey_value] = {
            count_position: 0,
            position_last_nav_in_user_currency: 0,
            position_commited_in_user_currency_at_inception_rate: 0,
            position_cumulated_distributions_in_user_currency: 0,
            position_expected_remaining_callable_capital_in_user_currency: 0
          };
        }
        if (!newSummary.columns[colKey_value]) {
          newSummary.columns[colKey_value] = {
            count_position: 0,
            position_last_nav_in_user_currency: 0,
            position_commited_in_user_currency_at_inception_rate: 0,
            position_cumulated_distributions_in_user_currency: 0,
            position_expected_remaining_callable_capital_in_user_currency: 0
          };
        }
        if (!newSummary.row[rowKey_value]) {
          newSummary.row[rowKey_value] = {
            count_position: 0,
            position_last_nav_in_user_currency: 0,
            position_commited_in_user_currency_at_inception_rate: 0,
            position_cumulated_distributions_in_user_currency: 0,
            position_expected_remaining_callable_capital_in_user_currency: 0
          };
        }


        newSummary.data[rowKey_value][colKey_value].count_position += 1;
        newSummary.data[rowKey_value][colKey_value].position_last_nav_in_user_currency += parseFloat(element.position_last_nav_in_user_currency);
        newSummary.data[rowKey_value][colKey_value].position_commited_in_user_currency_at_inception_rate += parseFloat(element.position_commited_in_user_currency_at_inception_rate);
        newSummary.data[rowKey_value][colKey_value].position_cumulated_distributions_in_user_currency += parseFloat(element.position_cumulated_distributions_in_user_currency);
        newSummary.data[rowKey_value][colKey_value].position_expected_remaining_callable_capital_in_user_currency += parseFloat(element.position_expected_remaining_callable_capital_in_user_currency);

        newSummary.columns[colKey_value].count_position += 1;
        newSummary.columns[colKey_value].position_last_nav_in_user_currency += parseFloat(element.position_last_nav_in_user_currency);
        newSummary.columns[colKey_value].position_commited_in_user_currency_at_inception_rate += parseFloat(element.position_commited_in_user_currency_at_inception_rate);
        newSummary.columns[colKey_value].position_cumulated_distributions_in_user_currency += parseFloat(element.position_cumulated_distributions_in_user_currency);
        newSummary.columns[colKey_value].position_expected_remaining_callable_capital_in_user_currency += parseFloat(element.position_expected_remaining_callable_capital_in_user_currency);

        newSummary.row[rowKey_value].count_position += 1;
        newSummary.row[rowKey_value].position_last_nav_in_user_currency += parseFloat(element.position_last_nav_in_user_currency);
        newSummary.row[rowKey_value].position_commited_in_user_currency_at_inception_rate += parseFloat(element.position_commited_in_user_currency_at_inception_rate);
        newSummary.row[rowKey_value].position_cumulated_distributions_in_user_currency += parseFloat(element.position_cumulated_distributions_in_user_currency);
        newSummary.row[rowKey_value].position_expected_remaining_callable_capital_in_user_currency += parseFloat(element.position_expected_remaining_callable_capital_in_user_currency);


        newSummary.total.count_position += 1;
        newSummary.total.position_last_nav_in_user_currency += parseFloat(element.position_last_nav_in_user_currency);
        newSummary.total.position_commited_in_user_currency_at_inception_rate += parseFloat(element.position_commited_in_user_currency_at_inception_rate);
        newSummary.total.position_cumulated_distributions_in_user_currency += parseFloat(element.position_cumulated_distributions_in_user_currency);
        newSummary.total.position_expected_remaining_callable_capital_in_user_currency += parseFloat(element.position_expected_remaining_callable_capital_in_user_currency);

      });
    }
    //console.log('newSummary', newSummary)
    setSummary(newSummary);
  }, [props.data, row_key, value_key, col_key]);


  return (
    <Card >
      <CardBody>
        <div className="table-responsive" style={{ minHeight: "22em" }}>
          <Row className="flex" style={{ marginBottom: "1em" }}>
            <Col sm="12" xl="6" className="d-flex">
              <h2>{value_key_label} per {row_key_label} per {col_key_label}</h2>
            </Col>
            <Col sm="4" xl="6" className="d-flex justify-content-end">
         
                <Dropdown
                  isOpen={actionDropDown_value}
                  toggle={() => set_actionDropDown_value(!actionDropDown_value)}
                >
                  <DropdownToggle className="btn btn-link" caret={true}>{value_key_label} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => { set_value_key('position_last_nav_in_user_currency'); set_value_key_label('Nav') }} >
                      <Link >Nav</Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => { set_value_key('position_commited_in_user_currency_at_inception_rate'); set_value_key_label('Commitment') }}>
                      <Link >Commitment</Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => { set_value_key('position_cumulated_distributions_in_user_currency'); set_value_key_label('Distribution') }}>
                      <Link >Distribution</Link>

                    </DropdownItem>
                    <DropdownItem onClick={() => { set_value_key('position_expected_remaining_callable_capital_in_user_currency'); set_value_key_label('Remaining (Expected)') }}>
                      <Link >Remaining (expected) </Link>
                    </DropdownItem>


                  </DropdownMenu>
                </Dropdown>

                <Dropdown
                  isOpen={actionDropDown_col}
                  toggle={() => set_actionDropDown_col(!actionDropDown_col)}
                >
                  <DropdownToggle className="btn btn-link" caret={true}>{col_key_label} <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => { set_col_key('asset_intermediate'); set_col_key_label('Intermediate') }} >
                      <Link >Intermediate</Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => { set_col_key('asset_type'); set_col_key_label('Asset Type') }}>
                      <Link >Asset Type</Link>
                    </DropdownItem>
                    <DropdownItem onClick={() => { set_col_key('asset_vintage_year'); set_col_key_label('Vintage') }}>
                      <Link >Vintage</Link>

                    </DropdownItem>
                    <DropdownItem onClick={() => { set_col_key('asset_currency'); set_col_key_label('Currency') }}>
                      <Link >Currency</Link>
                    </DropdownItem>


                  </DropdownMenu>
                </Dropdown>
            </Col>
          </Row>

          <Table id={'summary-'+col_key_label+'-'+value_key_label} className={(MoondanceLayout.is_dark_mode ? 'darkMode MoondanceTable table-centered' : 'MoondanceTable table-centered')} style={{ width: '100%', maxWidth: '100%', }} >
            <thead className="thead-light">
              <tr style={{ backgroundColor: MoondanceLayout.colorScheme.secondary_backgroundColor }}>
                <th>
                  <Dropdown
                    isOpen={actionDropDown_row}
                    toggle={() => set_actionDropDown_row(!actionDropDown_row)}
                  >
                    <DropdownToggle className="btn btn-link" caret={true}>{row_key_label} <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => { set_row_key('position_account_name'); set_row_key_label('Account') }} >
                        <Link >Account</Link>
                      </DropdownItem>
                      <DropdownItem onClick={() => { set_row_key('asset_vintage_year'); set_row_key_label('Vintage Year') }}>
                        <Link >Vintage</Link>
                      </DropdownItem>
                      <DropdownItem onClick={() => { set_row_key('asset_type'); set_row_key_label('Type') }}>
                        <Link >Type</Link>

                      </DropdownItem>
                      <DropdownItem onClick={() => { set_row_key('asset_intermediate'); set_row_key_label('Intermediate') }}>
                        <Link >Intermediate </Link>
                      </DropdownItem>
                      <DropdownItem onClick={() => { set_row_key('asset_currency'); set_row_key_label('Currency') }}>
                        <Link >Currency</Link>
                      </DropdownItem>

                    </DropdownMenu>
                  </Dropdown></th>
                {summary.columns && Object.keys(summary.columns).map((key, index) => (
                  <th key={index} style={{ textAlign: 'right' }} >{key}</th>
                ))}
                <th style={{ textAlign: 'right' }} >Total</th>
              </tr>
            </thead>
            <tbody>
              {(summary && summary.data && Object.keys(summary.data).map((accountName, index) => (

                <tr key={index}>
                  <td>{accountName}</td>
                  {summary.columns && Object.keys(summary.columns).map((col_key, index) => (
                    <td key={index} style={{ textAlign: 'right' }}
                    >{(summary.data[accountName] && summary.data[accountName][col_key] && summary.data[accountName][col_key][value_key] ? MoondanceLayout.formatCurrency(summary.data[accountName][col_key][value_key], userCurrency) : '')}</td>
                  ))}
                  <td style={{ textAlign: 'right' }}
                  >{(summary.row[accountName] && summary.row[accountName][value_key] ? MoondanceLayout.formatCurrency(summary.row[accountName][value_key], userCurrency) : '')}</td>

                </tr>

              )))}

            </tbody>
            <tfoot>
              <tr style={{ backgroundColor: MoondanceLayout.colorScheme.secondary_backgroundColor }}>
                <th>Total</th>
                {summary.columns && Object.keys(summary.columns).map((key, index) => (
                  <th style={{ textAlign: 'right' }} key={index}>{MoondanceLayout.formatCurrency(summary.columns[key][value_key], userCurrency)}</th>
                ))}
                <th style={{ textAlign: 'right' }} >{summary.total && summary.total[value_key] ? MoondanceLayout.formatCurrency(summary.total[value_key], userCurrency) : 0}</th>
              </tr>

            </tfoot>
          </Table>
        </div>
      </CardBody>

    </Card>
  );
}
