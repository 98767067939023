
import React, { useState, useContext, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import {
  Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
  NavItem,
  NavLink, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  Modal
} from "reactstrap";

import { useMemo } from 'react';
import MoondanceLayout from "../helpers/layout.js";
import { Link } from 'react-router-dom';
import { MoondanceContext } from '../../App.js';
import AssetModel from '../position_detail/assetModel.js';
import { set } from "lodash";

//simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example

export default function PositionsCard(props) {

  const { portraitMode } = useContext(MoondanceContext);
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  const { anyModal, setAnyModal } = useContext(MoondanceContext);
  const [displayed_data, set_displayed_data] = useState([]);
  const [displayOptions, setDisplayOptions] = useState([
    { key: 'info', label: 'Summary' },
    { key: 'kpi', label: 'KPIs' },
    { key: 'balance_in_user_currency', label: 'Actual Net Cashflows' },
    { key: 'balance_in_commitment_pct', label: 'Actual cashflows in % of commitement' },
    { key: 'expectedBalanceInUserCurrency', label: 'Expected Cashflows' },
    { key: 'expectedBalanceInPctOfCommitment', label: 'Expected Cashflows in % of commitement' }
  ]);
  const [column_settings, set_column_settings] = useState([]);
  const [position_display, set_position_display] = useState('info');

  const [actionDropDown, set_actionDropDown] = useState(false)
  const [actionDropDown_order, set_actionDropDown_order] = useState(false)

  const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
  const [sortPositionBy, set_sortPositionBy] = useState('asset_name')
  const [sortPositionBy_label, set_sortPositionBy_label] = useState('Name')
  const [sortOrder, set_sortOrder] = useState('asc')
  const [ModelModalPropId, setModelModalPropId] = useState(0)

  const [position_list_as_table, set_position_list_as_table] = useState((props.showAsTable ? props.showAsTable : true))

  function sortByKey(array, key, order = 'asc') {
    return array.sort((a, b) => {
      let x = a[key];
      let y = b[key];

      // If the value is a string, convert to lower case to ensure case-insensitive comparison
      if (typeof x === "string") {
        x = x.toLowerCase();
      }
      if (typeof y === "string") {
        y = y.toLowerCase();
      }

      // Compare for ascending or descending order
      if (order === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      } else { // 'desc'
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  const sortedData = useMemo(() => {

    //console.log('props.data.listPositions ',props.data.listPositions)


    if (position_display == 'info' || position_display == 'kpi') {
      return sortByKey([...props.data.listPositions], sortPositionBy, sortOrder);
    } else if (position_display == 'balance_in_user_currency' || position_display == 'balance_in_commitment_pct') {
        let position_list = [...props.data.listPositions];
        position_list.forEach((item, index) => {
          if (props.data && props.data.positionCashFlowPerYear && props.data.positionCashFlowPerYear[item.position_id]) {
            let this_row = props.data.positionCashFlowPerYear[item.position_id][position_display];
            position_list[index]['matching_id'] = props.data.positionCashFlowPerYear[item.position_id].id
            position_list[index]['total'] = 0
            for (let i = props.data.positionCashFlowPerYear.meta_data.min_year; i <= props.data.positionCashFlowPerYear.meta_data.max_year; i++) {
              if (position_list[index]) {
                position_list[index][i + ''] = (this_row && this_row[i] ? this_row[i] : 0)
                position_list[index]['total'] += position_list[index][i + '']
              }
            }
          }
      });
        //console.log('new_position_list', position_list)
        return sortByKey(position_list, sortPositionBy, sortOrder);
     } else if (position_display == 'expectedBalanceInUserCurrency') {
        let position_list = [...props.data.listPositions];
      
        position_list.forEach((item, index) => {
          if (props.data && props.data.positionCashFlowPerYear && props.data.positionCashFlowPerYear[item.position_id]) {
            let this_row = props.data.positionCashFlowPerYear[item.position_id]['balance_in_user_currency'];


            position_list[index]['matching_id'] = props.data.positionCashFlowPerYear[item.position_id].id
            position_list[index]['total'] = 0
            for (let i = props.data.positionCashFlowPerYear.meta_data.min_year; i <= props.data.positionCashFlowPerYear.meta_data.max_year; i++) {
              if (position_list[index]) {
                position_list[index][i+'_actual' ] = (this_row && this_row[i] ? this_row[i] : 0)
                position_list[index]['total'] +=  position_list[index][i+'_actual' ] 
              }
            }
          }
        });
        //console.log('new_position_list  props.data',  props.data)
       
        position_list.forEach((item, index) => {
          if (props.data.expectedBalanceInUserCurrency[item.position_id]) {
            let this_row = props.data.expectedBalanceInUserCurrency[item.position_id] ;
            position_list[index]['matching_id'] = props.data.expectedBalanceInUserCurrency[item.position_id].id
            position_list[index]['total'] = 0
            for (let i = MoondanceLayout.currentYear(); i <=  MoondanceLayout.currentYear()+10; i++) {
              if (position_list[index]) {
                if( i == MoondanceLayout.currentYear()){
                  if(this_row && this_row[i] && position_list[index][i + '_actual']){
                        position_list[index][i + '_expected'] = this_row[i] - position_list[index][i + '_actual']
                        position_list[index]['total'] += position_list[index][i + '_expected']
                  } else {
                    position_list[index][i + '_expected'] = (this_row && this_row[i] ? this_row[i] : 0)
                    position_list[index]['total'] +=  position_list[index][i + '_expected']
                  }
                  
                } else {
                  position_list[index][i + '_expected'] = (this_row && this_row[i] ? this_row[i] : 0)
                  position_list[index]['total'] +=  position_list[index][i + '_expected']
                }
              }
            }
          }
        });
        console.log('new_position_list expectedBalanceInUserCurrency', position_list)
       
        return sortByKey(position_list, sortPositionBy, sortOrder);
      } else if (position_display == 'expectedBalanceInPctOfCommitment') {
        let position_list = [...props.data.listPositions];
        
        position_list.forEach((item, index) => {
          if (props.data.expectedBalanceInPctOfCommitment[item.position_id]) {
            let this_row = props.data.expectedBalanceInPctOfCommitment[item.position_id] ;
            position_list[index]['matching_id'] = props.data.expectedBalanceInPctOfCommitment[item.position_id].id
            position_list[index]['total'] = 0
            for (let i = MoondanceLayout.currentYear(); i <=  MoondanceLayout.currentYear()+10; i++) {
              if (position_list[index]) {
              
                position_list[index][i + ''] = (this_row && this_row[i] ? this_row[i] : 0)
                position_list[index]['total'] += (this_row && this_row[i] ? this_row[i] : 0)
              }
            }
          }
      });
         console.log('new_position_list', position_list)
     
        return sortByKey(position_list, sortPositionBy, sortOrder);
      }
  }, [props.data, sortPositionBy, sortOrder, lastUpdate, position_display]);



  useEffect(() => {
    if(portraitMode){
      set_position_list_as_table(false);
    }
  },[portraitMode])

  useEffect(() => {



    set_displayed_data(sortedData);
    //console.log('sortedData', sortedData)
  }, [sortedData, lastUpdate]);

  useEffect(() => {

    let new_column_settings = [
      {
        label: 'Name', field: 'asset_name', customFormat: (row) => {
          return (
            <Link className="success" to={'/position/' + row.position_id}>{row.asset_name}</Link>)
        },


      },

      { label: 'Account', field: 'position_account_name', hide: (position_display == 'info' ? false : true) },
      { label: 'Status', field: 'position_status_label', hide: (position_display == 'info' ? false : true) },
      {
        label: 'Type', hide: (position_display == 'info' ? false : true), field: 'asset_type',
        customFormat: (row) => {
          if (MoondanceLayout.assetType && MoondanceLayout.assetType[row.asset_type]) {
            return MoondanceLayout.assetType[row.asset_type].label;
          } else if (MoondanceLayout.assetType) {
            let explType = row.asset_type.split(',');
            let returnVal = '';
            explType.forEach((item) => {
              if (MoondanceLayout.assetType[item] && MoondanceLayout.assetType[item].label) {
                returnVal += MoondanceLayout.assetType[item].label + ', ';
              }
            });
            return returnVal.slice(0, -2);
          } else {
            return '';
          }

        }
      },
      { label: 'Vintage', format: 'number', field: 'asset_vintage_year', align: 'right', },
      { label: 'Currency', hide: (position_display == 'info' ? false : true), field: 'asset_currency', style: { borderRight: '1px dashed black' } },
      { label: 'Commitment', hide: (position_display == 'info' ? false : true), field: 'position_commited_in_user_currency_at_inception_rate', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_commited_in_user_currency_at_inception_rate'], userCurrency) }, align: 'right', showTotal: true },
      { label: 'Called', hide: (position_display == 'info' ? false : true), field: 'position_cumulated_capital_calls_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_cumulated_capital_calls_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },
      { label: 'Remaining', hide: (position_display == 'info' ? false : true), field: 'position_remaining_callable_capital_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_remaining_callable_capital_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },
      {
        label: 'Nav', hide: (position_display == 'info' ? false : true), field: 'position_last_nav_in_user_currency', format: 'currency', formatUnit: userCurrency,
        customFormat: (row) => {
          return (
            (parseInt(row['position_last_nav_in_user_currency']) === 0 && parseInt(row['position_cumulated_capital_calls_in_user_currency']) < 0)
              ? <span className="badge bg-danger"><i className="mdi mdi-alert"></i> NO NAV</span>
              : MoondanceLayout.formatCurrency(row['position_last_nav_in_user_currency'], userCurrency)
          )
        },
        align: 'right', showTotal: true
      },
      { label: 'Distribution', hide: (position_display == 'info' ? false : true), field: 'position_cumulated_distributions_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_cumulated_distributions_in_user_currency'], userCurrency) }, align: 'right', showTotal: true },
      { label: 'Gain & Losses', hide: (position_display == 'info' ? false : true), field: 'position_gain_and_losses_in_user_currency', format: 'currency', formatUnit: userCurrency, customFormat: (row) => { return MoondanceLayout.formatCurrency(row['position_gain_and_losses_in_user_currency'], userCurrency) }, align: 'right', style: { borderRight: '1px dashed black' }, showTotal: true },
      { label: 'IRR ' + userCurrency, hide: (position_display == 'kpi' ? false : true), field: 'position_irr_in_user_currency', customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['position_irr_in_user_currency'], 2, true)) }, align: 'right' },
      { label: 'DPI ' + userCurrency, hide: (position_display == 'kpi' ? false : true), field: 'position_dpi_in_user_currency', format: 'number' },
      { label: 'TVPI ' + userCurrency, hide: (position_display == 'kpi' ? false : true), field: 'position_tvpi_in_user_currency', format: 'number', style: { borderRight: '1px dashed black' }, },
      { label: 'Total Remaining calls', hide: (position_display == 'kpi' ? false : true), field: 'position_remaining_callable_capital_in_pct_of_commited', customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['position_remaining_callable_capital_in_pct_of_commited'], 2, true)) }, align: 'right' },

      { label: 'Expected Remaining calls', hide: (position_display == 'kpi' ? false : true), field: 'position_expected_remaining_callable_capital_in_pct_of_commited', customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['position_expected_remaining_callable_capital_in_pct_of_commited'], 2, true)) }, align: 'right' },
      {
        label: 'Modeled Remaining calls', hide: (position_display == 'kpi' ? false : true), field: 'position_model_future_call_in_pct_of_commited', customFormat: (row) => {
          let spread = Math.round((row.position_model_future_call_in_pct_of_commited - row.position_expected_remaining_callable_capital_in_pct_of_commited) * 100)
          if (spread < -0.1 || spread > 0.1) {
            return <span className="badge bg-danger"> {MoondanceLayout.formatPercentage(row['position_model_future_call_in_pct_of_commited'], 2, true)}</span>
          } else {
            return <span> {MoondanceLayout.formatPercentage(row['position_model_future_call_in_pct_of_commited'], 2, true)}</span>

          }

        },

        style: { borderRight: '1px dashed black' },
        align: 'right'
      },
      {
        label: 'Remark', field: null,
        hide: ((position_display == 'info' || position_display == 'kpi') ? false : true),
        style: { minWidth: '12em' },
        customFormat: (row) => {
          let returnValue;
          let insufficientDistrib = false
          let spread = Math.round((row.position_model_future_call_in_pct_of_commited - row.position_expected_remaining_callable_capital_in_pct_of_commited) * 100)
          if (row.position_expected_future_distribution_in_asset_currency < row.position_last_nav_in_asset_currency) {
            insufficientDistrib = true;
          }
          return (
            <>
              {(spread < 0 ? <p style={{ textAlign: "center" }} className=" badge bg-warning">Insufficient<br />Expected Calls</p> : '')}
              {(spread > 0 ? <p style={{ textAlign: "center" }} className=" badge bg-warning">Excessive<br />Expected Calls</p> : '')}
              {(insufficientDistrib ? <p style={{ textAlign: "center" }} className="badge bg-info">Future Distribution <br />below Current NAV </p> : '')}
            </>)


        },


      },


    ]


    if (props.data  && props.data.positionCashFlowPerYear && props.data.positionCashFlowPerYear.meta_data && position_display == 'balance_in_user_currency') {

      for (let i = props.data.positionCashFlowPerYear.meta_data.min_year; i <= props.data.positionCashFlowPerYear.meta_data.max_year; i++) {

        new_column_settings.push(
          {
            label: i,
            field: i,
            customFormat: (row) => { return (row[i] ? MoondanceLayout.formatCurrency(row[i], userCurrency) : '') }, align: 'right',
            showTotal: true,
            format: 'currency', formatUnit: userCurrency,
          })
      }
      new_column_settings.push(
        {
          label: 'Total',
          field: 'total',
          customFormat: (row) => { return (row['total'] ? MoondanceLayout.formatCurrency(row['total'], userCurrency) : '') }, align: 'right',
          showTotal: true,
          format: 'currency', formatUnit: userCurrency,
        })
    } else if (position_display == 'balance_in_commitment_pct') {
      for (let i = props.data.positionCashFlowPerYear.meta_data.min_year; i <= props.data.positionCashFlowPerYear.meta_data.max_year; i++) {

        new_column_settings.push(
          {
            label: i,
            field: i,
            customFormat: (row) => { return (row[i] ? MoondanceLayout.formatPercentage(row[i]/100, 2) : '') }, align: 'right',

            format: 'percentage',
          })
      }
      new_column_settings.push(
        {
          label: 'Total',
          field: 'total',
          customFormat: (row) => { return (row['total'] ? MoondanceLayout.formatPercentage(row['total']/100, 2) : '') }, align: 'right',

          format: 'percentage',
        })
      } else if (position_display == 'expectedBalanceInUserCurrency') {

        for (let i = props.data.positionCashFlowPerYear.meta_data.min_year; i <= props.data.positionCashFlowPerYear.meta_data.max_year; i++) {

          new_column_settings.push(
            {
              label:  (i == MoondanceLayout.currentYear() ? <>{i}<br/> (Actual)</> : i),
              field:  i+'_actual',
              customFormat: (row) => { return (row[i+'_actual'] ? MoondanceLayout.formatCurrency(row[i+'_actual'], userCurrency) : '') }, align: 'right',
              showTotal: true,
              format: 'currency', formatUnit: userCurrency,
            })
        }

        for (let i = MoondanceLayout.currentYear(); i <=  MoondanceLayout.currentYear()+10; i++) {
          new_column_settings.push(
            {
              label: (i == MoondanceLayout.currentYear() ? <>{i}<br/> (Remaining Expected)</> : i),
              field: i+'_expected',
              style:  (i == MoondanceLayout.currentYear() ? { borderLeft: '1px dashed black',fontStyle:'italic' }: {fontStyle:'italic'}), 
              customFormat: (row) => { return (row[i+'_expected'] ? MoondanceLayout.formatCurrency(row[i+'_expected'], userCurrency) : '') }, 
              align: 'right',
              showTotal: true,
              format: 'currency', formatUnit: userCurrency,
            })
        }
        new_column_settings.push(
          {
            label: 'Total',
            field: 'total',
            customFormat: (row) => { return (row['total'] ? MoondanceLayout.formatCurrency(row['total'], userCurrency) : '') }, align: 'right',
            showTotal: true,
            format: 'currency', formatUnit: userCurrency,
          })
        } else if (position_display == 'expectedBalanceInPctOfCommitment') {
          for (let i = MoondanceLayout.currentYear(); i <=  MoondanceLayout.currentYear()+10; i++) {
            new_column_settings.push(
              {
                label: i,
                field: i,
                customFormat: (row) => { return (row[i] ? MoondanceLayout.formatPercentage(row[i], 2) : '') }, align: 'right',
                showTotal: true,
                format: 'percentage',
              })
          }
          new_column_settings.push(
            {
              label: 'Total',
              field: 'total',
              customFormat: (row) => { return (row['total'] ? MoondanceLayout.formatPercentage(row['total'], 2) : '') }, align: 'right',
    
              format: 'percentage',
            })
        
        }

    set_column_settings(new_column_settings)

  }, [sortedData, lastUpdate, position_display]);

  return (
    <>
      {(position_list_as_table ?
        <Card >
          <CardBody>
            <Row style={{ marginBottom: "1em" }}>
              <Col xs="12" md="8">
                <h5>List Positions</h5>
              </Col>
              <Col xs="12" md="4" className="d-flex justify-content-end" >
                <Dropdown
                  key = {"position_display_dropdown_"+displayOptions}
                  isOpen={actionDropDown}
                  toggle={() => set_actionDropDown(!actionDropDown)}
                >
                  <DropdownToggle className="btn btn-outline-primary" caret={true}>
                    {displayOptions.find((item) => item.key === position_display).label}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {displayOptions.map((item) => {

                      if (item.key !== position_display) {
                        return (
                          <DropdownItem 
                            key = {"position_display_dropdown_"+item.key}
                            onClick={() => { set_position_display(item.key) }}>
                            <Link >{item.label}</Link>
                          </DropdownItem>
                        )
                      }
                    })}
                    <DropdownItem className="btn btn-outline-primary"
                      onClick={() => set_position_list_as_table(!position_list_as_table)}> <Link >Show as Cards</Link></DropdownItem>
                  </DropdownMenu>

                </Dropdown>
              </Col>

            </Row>
            <Row>
              <MoondanceLayout.MoondanceTable
                key={'position_table_' + position_display}
                rows={displayed_data}
                defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                paginationSize={20}
                columns={column_settings}

              />
            </Row>
          </CardBody>
        </Card>

        :
        <>
          <div className="flex" style={{ marginBottom: "1em" }}>
           
            <div className="btn-group ml-1">
              {(!portraitMode ? <Button
                className="btn btn-outline-primary"
                onClick={() => set_position_list_as_table(!position_list_as_table)}
              >Show as List</Button>:'')}
              <Dropdown
                isOpen={actionDropDown}
                toggle={() => set_actionDropDown(!actionDropDown)}
              >
                <DropdownToggle className="btn btn-outline-primary" caret={true}>
                  Sorted by {sortPositionBy_label} <i className="mdi mdi-chevron-down" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => { set_sortPositionBy('asset_name'); set_sortPositionBy_label('Name') }} >
                    <Link >Name</Link>
                  </DropdownItem>
                  <DropdownItem onClick={() => { set_sortPositionBy('asset_vintage_year'); set_sortPositionBy_label('Vintage') }}>
                    <Link >Vintage</Link>
                  </DropdownItem>
                  <DropdownItem onClick={() => { set_sortPositionBy('position_irr'); set_sortPositionBy_label('Irr') }}>
                    <Link >Irr </Link>

                  </DropdownItem>
                  <DropdownItem onClick={() => { set_sortPositionBy('position_cumulated_distributions_in_asset_currency'); set_sortPositionBy_label('Distribution') }}>
                    <Link >Distribution </Link>
                  </DropdownItem>
                  <DropdownItem onClick={() => { set_sortPositionBy('position_last_nav_in_eur'); set_sortPositionBy_label('Nav') }}>
                    <Link >Nav </Link>
                  </DropdownItem>

                </DropdownMenu>
              </Dropdown>
              <Button className="btn btn-outline-primary" caret='true'
                onClick={() => {
                  set_sortOrder((sortOrder == 'asc' ? 'desc' : 'asc'));
                }} >{(sortOrder === 'asc' ? 'Ascending' : 'Descending')}</Button>

            </div>
          </div>
          {(displayed_data ?
            (displayed_data.map((item, index) => {

              let spread = Math.round((item.position_model_future_call_in_pct_of_commited - item.position_expected_remaining_callable_capital_in_pct_of_commited) * 100)

              return (
                <Col key={"position_card_" + index} xs="12" sm="6" md="6" xxl="4" >
                  <Card  >
                    <CardBody>
                      <CardTitle className="d-flex justify-content-between">
                        <Link to={'/position/' + item.position_id}><i className=" far fa-folder-open"></i> {item.asset_name} </Link>
                        <Link to={'/position/' + item.position_id}><Button className="btn btn-sm btn-outline-primary"><span className="mdi mdi-eye
"></span> View</Button></Link>


                      </CardTitle>
                      <p>{(MoondanceLayout.assetType[item.asset_type] ? MoondanceLayout.assetType[item.asset_type].label : item.asset_type)} <br /> {item.asset_vintage_year} - {item.position_account_name}<br /></p>
                      <Row>
                        <Col md="6" xs="12"><b>Commitment</b></Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatCurrency(item.position_commited_in_asset_currency, item.asset_currency)}</Col>
                      </Row>
                      <Row>
                        <Col md="6" xs="12"><b>Called </b>({MoondanceLayout.formatPercentage(-item.position_cumulated_capital_calls_in_asset_currency / item.position_commited_in_asset_currency, 0)})</Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatCurrency(item.position_cumulated_capital_calls_in_asset_currency, item.asset_currency)}</Col>

                      </Row>


                      <Row>
                        <Col md="6" xs="12"><b>Remaining</b> ({MoondanceLayout.formatPercentage(1 + (item.position_cumulated_capital_calls_in_asset_currency / item.position_commited_in_asset_currency), 0)})</Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatCurrency(item.position_remaining_callable_capital_in_asset_currency, item.asset_currency)}</Col>

                      </Row>
                      <hr />

                      <Row>
                        <Col md="6" xs="12"><b>NAV</b></Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{item.position_last_nav_in_nav_currency ? MoondanceLayout.formatCurrency(item.position_last_nav_in_nav_currency, item.asset_currency) : <span className=" badge bg-info">No Data</span>}</Col>

                      </Row>
                      <Row>
                        <Col md="6" xs="12"><b>Distribution</b></Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatCurrency(item.position_cumulated_distributions_in_asset_currency, item.asset_currency)}</Col>

                      </Row>
                      <Row>
                        <Col md="6" xs="12"><b>Gain & Losses</b></Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatCurrency(item.position_last_nav_in_nav_currency + item.position_cumulated_distributions_in_asset_currency + item.position_cumulated_capital_calls_in_asset_currency, item.asset_currency)}</Col>

                      </Row>
                      <hr />
                      <Row>
                        <Col md="6" xs="12"><b>IRR</b>{item.asset_currency !== userCurrency ? ' (' + item.asset_currency + ')' : ''}</Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}> {(item.position_irr_in_asset_currency < 0 ? <span className=" badge bg-danger"> {MoondanceLayout.formatPercentage(item.position_irr_in_asset_currency)} </span> : MoondanceLayout.formatPercentage(item.position_irr_in_asset_currency))}</Col>

                      </Row>



                      <Row>
                        <Col md="6" xs="12"><b>DPI</b> {item.asset_currency !== userCurrency ? ' (' + item.asset_currency + ')' : ''}</Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatNumber((item.position_cumulated_distributions_in_asset_currency) / -item.position_cumulated_capital_calls_in_asset_currency, 2)}x</Col>

                      </Row>
                      <Row>
                        <Col md="6" xs="12"><b>TVPI</b> {item.asset_currency !== userCurrency ? ' (' + item.asset_currency + ')' : ''}</Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatNumber((item.position_last_nav_in_nav_currency + item.position_cumulated_distributions_in_asset_currency) / -item.position_cumulated_capital_calls_in_asset_currency, 2)}x</Col>

                      </Row>
                      <hr />
                      <Row>
                        <Col md="6" xs="12"><b>Expected total drawdown</b></Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatPercentage(item.asset_expected_drawdown)}</Col>

                      </Row>
                      <Row>
                        <Col md="6" xs="12"><b>Expected Remaining calls</b></Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatPercentage(item.position_expected_remaining_callable_capital_in_pct_of_commited)}</Col>

                      </Row>
                      <Row>
                        <Col md="6" xs="12"><b>Modeled Remaining calls</b></Col>
                        <Col md="6" xs="12" style={{ textAlign: 'right' }}>{MoondanceLayout.formatPercentage(item.position_model_future_call_in_pct_of_commited)}</Col>

                      </Row>
                      <hr />
                      <Row>

                        {(spread < 0) ?
                          <p className="text text-danger"><b>Model is <u>underestimating</u> calls !</b></p> : ''}
                        {(spread > 0) ?
                          <Col md="12" xs="12"> <p className="text text-info"><b>Model is overestimating calls !</b></p></Col> : ''}

                        {(spread !== 0 && item.user_can_edit_position) ?
                          <>
                            {(item && item.position_model_future_call_in_pct_of_commited == 0 ?

                              '' : <>
                                <Col md="4" xs="12"><Button className="btn btn-outline-primary" onClick={() => { setModelModalPropId(item.position_id); setAnyModal(true); }}>Adjust Model</Button></Col>
                                <Col md="8" xs="12"><p>This action will linearly adjust the modeled future capital calls to match the expected drawdown amount.</p></Col>
                              </>)}
                            <Col md="4" xs="12"><Link to={'/editPosition/' + item.position_id} className="btn btn-outline-primary">Edit Model</Link></Col>
                            <Col md="8" xs="12">Modify this position's details to manually update and refine the model's parameters.</Col>
                          </>
                          : ''}

                      </Row>


                    </CardBody>

                  </Card>


                </Col>)
            })) : '')}
          <Row>

            <p><span className="badge bg-danger">Model is underestimating calls</span><br />The expected capital calls for this position in the current and following years appear to be lower than the remaining expected capital calls needed to reach the anticipated drawdown for this position. <b>This discrepancy suggests a potential underestimation of future capital calls.</b>
              <br />Another possibility is that the expected capital calls for this position have not been updated to reflect new information. For instance, there might be a revised drawdown target for the position that hasn't been accurately reflected in the position details.
              To address this issue, you can either adjust the model parameters or update the expected capital calls in the position details accordingly. Doing so will help align the forecasts with the latest information and targets, thereby eliminating this warning.</p>


            <p><span className="badge bg-warning">Model is overestimating calls</span><br />The expected capital calls for this position in the current and following years exceed the model's projected remaining capital calls necessary to achieve the anticipated drawdown for this position.<b>This indicates a potential overestimation of future capital calls</b>.
              <br /> It's possible that the expected capital calls for this position have been updated based on new information, leading to a higher forecast than what the model predicts.
              <br />For instance, there may have been changes in the position's strategy or market conditions that are not yet fully accounted for in the model.
              <br />To resolve this discrepancy, consider reviewing and potentially recalibrating the model to ensure it accurately reflects the latest data and market insights.
              <br />Alternatively, if the updated expected capital calls are based on reliable and recent information, you might need to reassess the model's assumptions or inputs. Adjusting the model to align with the current expectations will help ensure more accurate and realistic forecasting, thereby mitigating the risk of overestimating future capital requirements.</p>
          </Row>




        </>
      )}
      <Modal isOpen={anyModal} className="largeModal">
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myModalLabel"
          >
            Update Model
          </h5>
          <button
            type="button"
            onClick={() => setAnyModal(false)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <AssetModel id={ModelModalPropId} />
        </div>
        <div className="modal-footer">

          <button
            type="button"
            onClick={() => setAnyModal(false)}

            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>

        </div>
      </Modal>
    </>
  )
}
/*
                      { label: 'Expected Remaining calls', field: 'position_expected_remaining_callable_capital_in_pct_of_commited',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['position_expected_remaining_callable_capital_in_pct_of_commited']))}, align: 'right' },
                { label: 'Modeled Remaining calls', field: 'position_model_future_call_in_pct_of_commited',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['position_model_future_call_in_pct_of_commited']))}, align: 'right' },
                { label: 'Remark',field: null,
                      customFormat: (row) => {
                        if(row.position_model_future_call_in_pct_of_commited*100 < Math.round(row.position_expected_remaining_callable_capital_in_pct_of_commited*100) ){
                          return (<span className="badge bg-danger">Model is <br/>underestimating calls</span>)
                        } else   if(row.position_model_future_call_in_pct_of_commited*100 > Math.round(row.position_expected_remaining_callable_capital_in_pct_of_commited*100) ){
                          return (<span className="badge bg-warning">Model is <br/>overestimating calls</span>)

                        }

                      }
                },*/