import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, CardBody, CardTitle, Col, Row, Container, Table, InputGroup } from "reactstrap";
import Selector from "../helpers/selector";
import { MoondanceContext } from '../../App';
import UseMoondanceApi from "../helpers/api";
import MoondanceLayout from "../helpers/layout";
import { buildAccountParameter, unixTimestampToDate, formatDate, formatDateToDMY, isValidDate, formatDate_DMY_to_YMD } from "../helpers/formatData";

import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { set } from "lodash";

const NavBulk = () => {


    const moondanceApi = UseMoondanceApi();
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const { addAlert } = useContext(MoondanceContext);
    const [flatpickrRefs, setFlatpickrRefs] = useState([]);
    const { accountList } = useContext(MoondanceContext);
    const [all_positions_list, set_all_positions_list] = useState([]);
    const [matching_position_list, set_matching_position_list] = useState([]);

    const formatOptions = ({ data, innerProps, isFocused }) => {
        const { label, description, value } = data;
        return (
            <div {...innerProps} value={value} style={{ padding: '0.4em', backgroundColor: isFocused ? '#deebff' : 'white' }}>
                <div><b>{label}</b></div>
                <div style={{ fontSize: '90%', }}>{description}</div>
            </div>
        );
    };
    const { selectedAccountList } = useContext(MoondanceContext);
    const { selectedStatus } = useContext(MoondanceContext);
    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
    const [filter_options, set_filter_options] = useState([]);

    const [selectedIntermediate, setSelectedIntermediate] = useState('all');
    const [positions_list, set_positions_list] = useState([]);
    const [selected_positions_list, set_selected_positions_list] = useState([]);
    const [formValues, setFormValues] = useState([]);
    const [initialFormValues, setInitialFormValues] = useState([]);
    const [sortByFeederName, setSortByFeederName] = useState(true);

    function sortListByFeederName(list) {
        console.log('sortListByFeederName', list)
        list.sort((a, b) => {
            // If both fund_feeders are empty, compare fund_name
            if (!a.asset_feeder_name && !b.asset_feeder_name) {
                return a.asset_name.localeCompare(b.asset_name);
            }

            // If one of the asset_feeder_names is empty, put it first
            if (!a.asset_feeder_name) return -1;
            if (!b.asset_feeder_name) return 1;

            // If none of the above, just compare the fund_feeders
            return a.asset_feeder_name.localeCompare(b.asset_feeder_name);
        });
        return list;
    }

    function convertDateFormat(dateString) {
        if (typeof dateString !== 'string') {
            return dateString;
        }

        const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
        const match = dateString.match(regex);

        if (match) {
            const year = match[1];
            const month = match[2];
            const day = match[3];
            return `${day}/${month}/${year}`;
        } else {
            return dateString;
        }
    }
    function SaveNav(position_id,saveToMatchingPositions=false) {
        var requestData = formValues[position_id];
        //   console.log('formValues',formValues)
          console.log('SaveNav',requestData)

        if (isValidDate(requestData['date'])) {
            requestData['date'] = formatDate(requestData['date'], 'yyyy-mm-dd')
        } else {
            requestData['date'] = requestData['date'].substring(0, 10)
        }

        console.log('SaveNav', requestData)
        if (formValues[position_id]['feeder'] !== initialFormValues[position_id]['feeder']) {
            console.log('update feeder name')
            moondanceApi.postUpdateFeederName(requestData).then(response => {
                if (response && response.success) {
                    setLastUpdate(Math.floor(Date.now() / 1000))
                    console.log("shares updated")
                    handleValueChange('changed', position_id, 'saved')
                    addAlert({
                        status: 'success',
                        title: 'Feeder Name Update',
                        message: 'Feeder Name  update successfull  ' + formValues[position_id]['asset_name'] + ' (position #' + position_id + ')',
                    })
                } else {
                    addAlert({
                        status: 'danger',
                        title: 'Feeder Name Update',
                        message: 'Feeder Name  WAS NOT successfull  ' + formValues[position_id]['asset_name'] + ' (position #' + position_id + ')',
                    })

                }
            })

        }
        moondanceApi.postNav(requestData).then(response => {
            if (response && response.success) {
                setLastUpdate(Math.floor(Date.now() / 1000))
                handleValueChange('changed', position_id, 'saved')
                addAlert({
                    status: 'success',
                    title: 'Nav Update',
                    message: 'NAV update successfull  ' + formValues[position_id]['asset_name'] + ' (position #' + position_id + ')',
                })
            } else {
                addAlert({
                    status: 'danger',
                    title: 'Nav Update',
                    message: 'NAV update WAS NOT successfull  ' + formValues[position_id]['asset_name'] + ' (position #' + position_id + ')',
                })

            }
        }).catch(error => {
          console.log('Error updating nav', error)
        });
        if(saveToMatchingPositions && matching_position_list[position_id].length>0){
            console.log('Form Values',formValues[position_id])
                
            matching_position_list[position_id].map((item, index) => {
                
                let  matching_position_data = Object.assign({},formValues[position_id]);
                matching_position_data['position_id'] = index;
                matching_position_data['shares'] = matching_position_data['shares']*item.ratio
                matching_position_data['nav'] =  matching_position_data['nav']*item.ratio
                console.log('Form Values',formValues[position_id])
                
                moondanceApi.postNav(matching_position_data).then(response => {
                    if (response && response.success) {
                        //setLastUpdate(Math.floor(Date.now() / 1000))
                        addAlert({
                            status: 'success',
                            title: 'Nav Update',
                            message: 'NAV update successfull  ' + formValues[position_id]['asset_name'] + ' (position #' +matching_position_data['position_id'] + ')',
                        })
                    } else {
                        addAlert({
                            status: 'danger',
                            title: 'Nav Update',
                            message: 'NAV update WAS NOT successfull  ' + formValues[position_id]['asset_name'] + ' (position #' + matching_position_data['position_id'] + ')',
                        })

                    }
                }).catch(error => {
                  console.log('Error updating nav', error)
                });
            })

        }
    }
    function handleIntermediateChange(selectedOption) {
        setSelectedIntermediate(selectedOption.value)
        let currentPositionList = positions_list;
        let filteredPositionList = currentPositionList.filter((item) => {
            return item.asset_intermediate === selectedOption.value || selectedOption.value === 'all';
        });

        if (sortByFeederName) {
            filteredPositionList = sortListByFeederName(filteredPositionList)
        }
        set_selected_positions_list(filteredPositionList);
        let flatpickrRef = []
        filteredPositionList.map((element) => { flatpickrRef[element.position_id] = React.createRef() })
        setFlatpickrRefs(flatpickrRef)

        console.log('positions_list:', filteredPositionList)
        /*        set_positions_list(positions.data);
                let flatpickrRef = []
                positions.data.map((element) =>{flatpickrRef[element.position_id] = React.createRef()})
                setFlatpickrRefs(flatpickrRef);*/
    }
    function handleValueChange(key, position_id, e) {
        console.log(`Option selected:`, key,e);
        var newValues = JSON.parse(JSON.stringify(formValues));
        if (!newValues[position_id]) {
            newValues[position_id] = {};
        }

        newValues[position_id]['asset_name'] = formValues[position_id]['asset_name'];
        if (key === 'nav') {
            newValues[position_id][key] = parseFloat(e);
            newValues[position_id]['navPerShare'] = Math.round(newValues[position_id][key] / (newValues[position_id]['shares'] !== 0 ? parseFloat(newValues[position_id]['shares']) : 1) * 100) / 100;
        } else if (key === 'navPerShare') {
            newValues[position_id][key] = parseFloat(e);
            console.log('navPerShare', newValues[position_id][key], newValues[position_id]['shares'])
            newValues[position_id]['nav'] = Math.round(newValues[position_id][key] * (newValues[position_id]['shares'] !== 0 ? parseFloat(newValues[position_id]['shares']) : 1) * 100) / 100;
        } else if (key === 'shares') {
            newValues[position_id][key] = parseFloat(e);
            newValues[position_id]['navPerShare'] = Math.round(newValues[position_id]['nav'] / (newValues[position_id][key] !== 0 ? parseFloat(newValues[position_id][key]) : 1) * 100) / 100;
        } else if (key === 'date') {
            console.log('date', e)
            if (isValidDate(e[0])) {
                newValues[position_id]['date'] = e[0]
            } else if (isValidDate(e)) {
                newValues[position_id]['date'] = e
            } else {
                console.log('invalid date')
            }
        } else if (key === 'changed') {
            newValues[position_id]['changed'] = e;
        } else if (key === 'feeder') {
            newValues[position_id]['feeder'] = e;
        }

        if (key !== 'changed') {
            newValues[position_id]['changed'] = 'unsaved';
        }
        console.log('newValues', newValues)
        setFormValues(newValues);

    }
    function storeValue(positions_list) {
        var newValues = [];

        (positions_list || []).map((item, key) => {

            if (item.asset_intermediate === selectedIntermediate || selectedIntermediate === 'all') {

                // console.log('item:',item);
                if (!newValues[item.position_id]) {
                    newValues[item.position_id] = {};
                }
                newValues[item.position_id]['position_id'] = item.position_id;
                newValues[item.position_id]['asset_id'] = item.asset_id;
                newValues[item.position_id]['asset_name'] = item.asset_name;
                newValues[item.position_id]['feeder'] = item.asset_feeder_name;
                newValues[item.position_id]['shares'] = (item.position_last_nav_shares !== 0 ? parseFloat(item.position_shares) : 1);
                newValues[item.position_id]['nav'] = (item.position_last_nav_in_nav_currency ? item.position_last_nav_in_nav_currency : 0);
                newValues[item.position_id]['navPerShare'] = Math.round(100 * item.position_last_nav_in_nav_currency / (item.position_shares !== 0 ? parseFloat(item.position_shares) : 1)) / 100;
                newValues[item.position_id]['date'] = (item.position_last_nav_date_timestamp && item.position_last_nav_date_timestamp > 0 ? new Date(item.position_last_nav_date_timestamp * 1000) : new Date());
                newValues[item.position_id]['changed'] = 'unchanged';
                newValues[item.position_id]['currency'] = (item.position_last_nav_currency ? item.position_last_nav_currency : 'EUR');
            }

        });

        //console.log('newValues:', newValues);
        setFormValues(newValues);
        setInitialFormValues(newValues);

    }
    useEffect(() => {
        async function fetchData() {
            var api_parameters = {
                account: buildAccountParameter(selectedAccountList),
                status: buildAccountParameter(selectedStatus),
                lastUpdate: lastUpdate,
                userCurrency: userCurrency,
            }

            try {
                var positions = await moondanceApi.fetchPositionsList(api_parameters);

                console.log('positions:', positions)
                storeValue(positions.data);

                if (sortByFeederName) {
                    positions.data = sortListByFeederName(positions.data)
                }
                // reomve item where the usre has no access
                positions.data = positions.data.filter((item) => {
                    return item.user_can_edit_position === 1 || item.user_can_update_nav === 1;
                });
                const fundIntermediateList = [...new Set(positions.data.map(item => item.asset_intermediate).filter(Boolean))];
                const optionsList = [{ value: 'all', label: 'All' }, ...fundIntermediateList.map(item => ({ value: item, label: item }))];
                set_filter_options(optionsList);


                set_positions_list(positions.data);
                set_selected_positions_list(positions.data);
                let flatpickrRef = []
                positions.data.map((element) => { flatpickrRef[element.position_id] = React.createRef() })
                setFlatpickrRefs(flatpickrRef);
                find_matching_position(positions.data);


            } catch (error) {
                console.log(error);
            }

        }
        fetchData();
    }, [selectedAccountList, selectedStatus]);

    async function fetch_all_position_list() {
        //console.log('fetch_all_position_list', accountList)
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }
        console.log('api_parameters', api_parameters)

        try {
            const response = await moondanceApi.fetchPositionsList(api_parameters)

            if (response.data.length > 0) {
                set_all_positions_list(response.data)
                //console.log('fetch_all_position_list', response.data)
                return response.data

            } else {
                return []
            }

        } catch (error) {
            console.log(error);
            return []
        }


        return []

    }
    async function find_matching_position(positions) {

        const all_positions_list = await fetch_all_position_list();
        let matching_position_list = []
        let this_matching_position_update

        positions.map((element) => {
            console.log('this_position_details', element)
            let this_matching_position_list = MoondanceLayout.findMatchingPositions(all_positions_list, element.position_id)
            console.log('find_matching_position', all_positions_list, this_matching_position_list);

            if (this_matching_position_list && this_matching_position_list.length > 0) {
                let this_matching_position_update = []

                this_matching_position_list.map((item, index) => {
                    this_matching_position_update[item.position_id] = {
                        ratio: item.position_commited_in_asset_currency / element.position_commited_in_asset_currency
                    }
                })

                matching_position_list[element.position_id] = this_matching_position_update

            }
        })
        console.log('matching_position_list', matching_position_list)
        set_matching_position_list(matching_position_list)

    }


    return (

        <React.Fragment >
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <h1><i className="fas fa-truck-moving"></i> Bulk Nav update</h1>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="3">
                            Select intermediate:
                            <Select
                                options={filter_options}
                                onChange={(e) => {
                                    handleIntermediateChange(e);
                                }}
                                styles={MoondanceLayout.select2customStyles}
                                placeholder="Select Intermediate"
                            />
                        </Col>
                    </Row><br />
                    <Row>

                        <Col xl={12} sm={12}>

                            <Card>
                                <CardBody>
                                    <Row>

                                        <MoondanceLayout.MoondanceTable
                                            key={`positions_list_${selectedIntermediate}`}
                                            rows={selected_positions_list}
                                            defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                                            columns={[

                                                {
                                                    label: 'Position',
                                                    field: 'asset_name',
                                                    style: { maxWidth: '6em', },
                                                    customFormat: (row) => {
                                                        return (
                                                            <><Link className="success" to={'/position/' + row.position_id}>{row.asset_name}</Link><br />
                                                                <span className="card-title-desc">{row.position_account_name} Ref:#{row.position_id}</span>
                                                                {(matching_position_list[row.position_id] && matching_position_list[row.position_id].length > 0 ? <><br /><span className="card-title-desc">Matching positions in other account : {Object.keys(matching_position_list[row.position_id]).length}</span></> : '')}
                                                            </>)
                                                    }
                                                },
                                                /*{
                                                    label: 'Feeder',
                                                    field: 'asset_feeder_name',

                                                    style: { maxWidth: '6em', borderRight: '1px dashed black' },
                                                    customFormat: (row) => {
                                                        if (row.user_can_edit_position === 1) {
                                                            return (
                                                                <input
                                                                    className="form-control "
                                                                    type="string"
                                                                    size="30"
                                                                    onChange={(e) => { handleValueChange('feeder', row.position_id, e.target.value); }}
                                                                    value={(formValues[row.position_id] && formValues[row.position_id]['feeder'] ? formValues[row.position_id]['feeder'] : row.asset_feeder_name)}
                                                                />
                                                            )
                                                        } else {
                                                            return (<p>{row.asset_feeder_name}</p>)
                                                        }
                                                    }
                                                },*/

                                                {
                                                    label: 'Date',
                                                    field: 'position_last_nav_date',
                                                    style: { maxWidth: '4em' },
                                                    customFormat: (row) => {
                                                        if (row.user_can_update_nav === 1) {

                                                            return (
                                                                <InputGroup>
                                                                    <Flatpickr
                                                                        className="form-control "
                                                                        onChange={(e) => { handleValueChange('date', row.position_id, e); }}
                                                                        value={(formValues[row.position_id] && formValues[row.position_id]['date'] ? convertDateFormat(formValues[row.position_id]['date']) : row.position_last_nav_date)}
                                                                        size="14"
                                                                        options={{
                                                                            clickOpens: false,  // ensure this is true
                                                                            allowInput: true,   // ensure direct input is allowed

                                                                            dateFormat: "d/m/Y",
                                                                        }}
                                                                        ref={flatpickrRefs[row.position_id]}

                                                                    /> <div className="input-group-append" onClick={() => { console.log('click', row.position_id, flatpickrRefs[row.position_id]); flatpickrRefs[row.position_id]?.current.flatpickr.open() }}>
                                                                        <span className="input-group-text">
                                                                            <i className="mdi mdi-calendar"></i>
                                                                        </span>
                                                                    </div>
                                                                </InputGroup>
                                                            )
                                                        }
                                                    }
                                                },
                                                {
                                                    label: 'Shares',
                                                    field: 'position_shares',
                                                    style: { maxWidth: '2em' },
                                                    customFormat: (row) => {
                                                            return ( MoondanceLayout.formatNumber(row.position_shares, 2))
                                                            
                                                        
                                                    }
                                                },
                                                {
                                                    label: 'Nav/Share',
                                                    field: 'position_last_shares',
                                                    style: { maxWidth: '4em' },
                                                    customFormat: (row) => {
                                                        if (row.user_can_update_nav === 1) {
                                                            return (
                                                                <InputGroup>
                                                                    <input
                                                                        className="form-control "
                                                                        type="number"
                                                                        onChange={(e) => { handleValueChange('navPerShare', row.position_id, e.target.value); }}
                                                                        value={(formValues[row.position_id] && formValues[row.position_id]['navPerShare'] ? formValues[row.position_id]['navPerShare'] : row.fund_last_nav_in_nav_currency / row.position_shares)}
                                                                    />

                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text">
                                                                            {(row.asset_currency !== userCurrency ? <span className="text-danger"><b>{row.asset_currency}</b></span> : row.asset_currency)}
                                                                        </span>
                                                                    </div>  </InputGroup>
                                                            )
                                                        }
                                                    }
                                                },
                                                {
                                                    label: 'Nav',
                                                    field: 'position_last_nav_in_nav_currency',
                                                    style: { maxWidth: '5em' },
                                                    customFormat: (row) => {
                                                        if (row.user_can_update_nav === 1) {
                                                            return (
                                                                <InputGroup>
                                                                    <input
                                                                        className="form-control "
                                                                        type="number"
                                                                        size="8"
                                                                        onChange={(e) => { handleValueChange('nav', row.position_id, e.target.value); }}
                                                                        value={(formValues[row.position_id] && formValues[row.position_id]['nav'] ? formValues[row.position_id]['nav'] : row.position_last_nav_in_nav_currency)}
                                                                    />

                                                                    <div className="input-group-append" >
                                                                        <span className="input-group-text">
                                                                            {(row.asset_currency !== userCurrency ? <span className="text-danger"><b>{row.asset_currency}</b></span> : row.asset_currency)}
                                                                        </span>
                                                                    </div>  </InputGroup>
                                                            )
                                                        }
                                                    }
                                                },
                                                {
                                                    label: 'Action',
                                                    field: 'position_id',
                                                    style: { maxWidth: '5em' },
                                                    customFormat: (row) => {
                                                        return (<>
                                                            {(formValues[row.position_id] && formValues[row.position_id]['changed'] === 'unsaved' ?
                                                                <>
                                                                    <button className="btn  btn-success" style={{width:'8em'}} onClick={() => { SaveNav(row.position_id) }}>Save</button>
                                                                    {(matching_position_list[row.position_id] && matching_position_list[row.position_id].length > 0 ? <>&nbsp;<button className="btn  btn-success" onClick={() => { SaveNav(row.position_id,true) }}>Save to All </button></> : '')}
                                                                </>                                                                     
                                                               :
                                                            (formValues[row.position_id] && formValues[row.position_id]['changed'] === 'saved' ? <button style={{width:'8em'}} disabled className="btn  btn-info" > Saved !</button> : <button className="btn btn-light" style={{width:'8em'}} disabled>Unchanged</button>)
                                                            )}
                                                        </>
                                                        )
                                                    }
                                                },





                                            ]}

                                        />

                                        <p>If a position exists in another account to which you have access, you can click on "Save to All" to update this position as well.</p>
                                    </Row>

                                    

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>




                </Container>
            </div>
        </React.Fragment>
    )
}


export default NavBulk;


/*<button className="btn btn-sm btn-outline-primary" onClick={() => {handleValueChange('date', item.position_id, new Date())}}>Now!</button>*/


/*  <Table className="table" striped={false}>
                                            <thead>
                                                <tr>
                                                    <th>Fund</th>
                                                    <th>Feeder</th>
                                                    <th>Shares</th>
                                                    <th>Nav Date</th>
                                                    <th>Nav/share</th>
                                                    <th>Nav (in currency)</th>
                                                    <th></th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {(positions_list || []).map((item, key) => {

                                                    if (item.asset_intermediate === selectedIntermediate || selectedIntermediate === 'all') {
                                                        // console.log(item);
                                                        // console.log(formValues[item.position_id]);

                                                        return (
                                                            <tr>
                                                                <td><a href={"/position/" + item.position_id}>{item.asset_name}</a></td>
                                                                <td>
                                                                    <input
                                                                        className="form-control "
                                                                        type="string"
                                                                        size="30"
                                                                        onChange={(e) => { handleValueChange('feeder', item.position_id, e.target.value); }}
                                                                        value={(formValues[item.position_id] && formValues[item.position_id]['feeder'] ? formValues[item.position_id]['feeder'] : item.asset_feeder_name)}
                                                                    />
                                                                </td>
                                                                <td className="no-wrap">
                                                                    <input
                                                                        className="form-control "
                                                                        type="number"
                                                                        size="2"
                                                                        onChange={(e) => { handleValueChange('shares', item.position_id, e.target.value); }}
                                                                        value={(formValues[item.position_id] && formValues[item.position_id]['shares'] ? formValues[item.position_id]['shares'] : item.position_last_shares)}
                                                                    />
                                                                </td>
                                                                <td className="no-wrap">
                                                                    <InputGroup>
                                                                        <Flatpickr

                                                                            className="form-control "
                                                                            onChange={(e) => { handleValueChange('date', item.position_id, e); }}
                                                                            value={(formValues[item.position_id] && formValues[item.position_id]['date'] ? convertDateFormat(formValues[item.position_id]['date']) : item.asset_last_nav_date)}
                                                                            size="10"
                                                                            options={{
                                                                                clickOpens: false,  // ensure this is true
                                                                                allowInput: true,   // ensure direct input is allowed

                                                                                dateFormat: "d/m/Y",
                                                                            }}
                                                                            ref={flatpickrRefs[key]}

                                                                        /> <div className="input-group-append" onClick={() => flatpickrRefs[key]?.current.flatpickr.open()}>
                                                                            <span className="input-group-text">
                                                                                <i className="mdi mdi-calendar"></i>
                                                                            </span>
                                                                        </div>
                                                                    </InputGroup>
                                                                </td>
                                                                <td className="no-wrap">
                                                                    <InputGroup>
                                                                        <input
                                                                            className="form-control "
                                                                            type="number"
                                                                            onChange={(e) => { handleValueChange('navPerShare', item.position_id, e.target.value); }}
                                                                            value={(formValues[item.position_id] && formValues[item.position_id]['navPerShare'] ? formValues[item.position_id]['navPerShare'] : item.fund_last_nav_in_nav_currency / item.fund_last_shares)}
                                                                        />

                                                                        <div className="input-group-append" >
                                                                            <span className="input-group-text">
                                                                                {item.asset_currency}
                                                                            </span>
                                                                        </div>  </InputGroup>
                                                                </td>
                                                                <td className="no-wrap">
                                                                    <InputGroup>
                                                                        <input
                                                                            className="form-control "
                                                                            type="number"
                                                                            size="8"
                                                                            onChange={(e) => { handleValueChange('nav', item.position_id, e.target.value); }}
                                                                            value={(formValues[item.position_id] && formValues[item.position_id]['nav'] ? formValues[item.position_id]['nav'] : item.fund_last_nav_in_nav_currency)}
                                                                        />
                                                                        <div className="input-group-append" >
                                                                            <span className="input-group-text">
                                                                                {item.asset_currency}
                                                                            </span>
                                                                        </div>  </InputGroup>


                                                                </td>
                                                                <td className="no-wrap">
                                                                    {(formValues[item.position_id] && formValues[item.position_id]['changed'] === 'unsaved' ? <button className="btn btn-sm btn-primary" onClick={() => { SaveNav(item.position_id) }}>Save</button> : '')}
                                                                    {(formValues[item.position_id] && formValues[item.position_id]['changed'] === 'saved' ? <p>Saved !</p> : '')}

                                                                </td>
                                                            </tr>

                                                        );
                                                    }
                                                })
                                                }</tbody>
                                        </Table>*/