import React from "react";
import { Navigate } from "react-router-dom";

//Moondance
import MoonDanceDashboard from "../moondance/dashboard/index";

import MoonDanceYearly from "../moondance/yearly/index";
import PositionDetail from "../moondance/position_detail/index";
import ListPositions from "../moondance/list_positions/index";
import AddPosition from  "../moondance/position_detail/addPositionWizard.js";
import EditPositionOld from  "../moondance/position_detail/editPosition_old.js";
import EditPosition from  "../moondance/position_detail/editPosition.js";
import EditModel from  "../moondance/position_detail/editModel.js";
import DuplicatePosition from  "../moondance/position_detail/duplicatePosition.js";
import Transactions from  "../moondance/list_transactions/index";
import AddTransaction from  "../moondance/transactions/index.js";
import UpdateNav from  "../moondance/nav/index.js";
import NavBulk from  "../moondance/nav/NavBulk.js";
import Logs from "../moondance/logs/index.js";

import Notes from  "../moondance/notes/listNotes.js";
import AddNotes from  "../moondance/notes/addNotes.js";
import EditNotes from  "../moondance/notes/editNotes.js";

import AccountBudget from "../moondance/profile/budget";
import AssetModel from "../moondance/position_detail/assetModel";

//admin page
import MoonBase from "../moondance/admin/index.js";
import Users from "../moondance/admin/users.js";

// Import Authentication pages
import MoondanceLogin from "../moondance/login/login";
import MoondanceLostPassword from "../moondance/login/lostPassword";
import MoondanceResetPassword from "../moondance/login/resetPassword";
import Logout from "../Pages/Authentication/Logout";
import MoondanceRegister from "../moondance/login/register";
import ConfirmSignup from "../moondance/login/confirm-signup";
import UserProfile from "../moondance/profile/userProfile";
import Accounts from "../moondance/profile/accounts";
import SelectAccount from "../moondance/helpers/selectAccount.js";
import PublicWebsite from "../moondance/publicWebsite/index.js";


import GeneralSalesConditions from "../moondance/publicWebsite/gsc.js";
import ConfirmContactForm from "../moondance/publicWebsite/confirmContactForm.js";

import Contact from "../moondance/contact/index.js";

import ExcelReader  from "../moondance/importXls/index.js";
import { useParams } from 'react-router-dom';



const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: <MoonDanceDashboard /> },
  { path: "/dashboard/:id", component: <MoonDanceDashboard /> },
  { path: "/yearly", component: <MoonDanceYearly /> },
  { path: "/position/:id", component: <PositionDetail /> },
  { path: "/positions", component: <ListPositions /> },
  { path: "/addPosition", component: <AddPosition /> },
  { path: "/duplicatePosition/:id", component: <DuplicatePosition /> },
  { path: "/addTransaction", component: <AddTransaction /> },
  { path: "/addTransaction/:id", component: <AddTransaction /> },
  { path: "/addNav", component: <UpdateNav /> },
  { path: "/editPosition/:id", component: <EditPosition /> },
  { path: "/editModel/:id", component: <EditModel  /> },
  { path: "/editPositionOld/:id", component: <EditPositionOld /> },
  { path: "/transactions", component: <Transactions /> },
  { path: "/navbulk", component: <NavBulk /> },
  { path: "/logs", component: <Logs /> },
  { path: "/AccountBudget", component: <AccountBudget /> },
  { path: "/assetModel/:id", component: <AssetModel /> },
  { path: "/selectAccount", component: <SelectAccount /> },
  { path: "/importXls", component: <ExcelReader /> },


  { path: "/admin", component: <MoonBase /> },
  { path: "/admin/users/:id", component: <Users /> },

  { path: "/contact", component: <Contact /> },
  { path: "/notes", component: <Notes /> },
  { path: "/notes/new/:id", component: <AddNotes /> },
  { path: "/notes/edit/:id", component: <EditNotes /> },
  { path: "/notes/new", component: <AddNotes /> },


  
  // Profile
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/accounts", component: <Accounts /> },



  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/home" />,
  },
  
];

const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <MoondanceLogin /> },
  { path: "/forgot-password", component: <MoondanceLostPassword /> },
  { path: "/reset-password/:code", component: <MoondanceResetPassword /> },
  { path: "/register", component: <MoondanceRegister /> },
  { path: "/confirm-signup/:token", component: <ConfirmSignup /> },
  { path: "/home", component: <PublicWebsite /> },
  { path: "/confirm_contact_form/:token", component: <ConfirmContactForm /> },
  { path: "/generalSalesConditions", component: <GeneralSalesConditions/> },
  

  
];

export { authProtectedRoutes, publicRoutes };
