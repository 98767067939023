import React, { useState,  useEffect } from "react";
import {  Col, Row } from "reactstrap";
import BarChart from '../helpers/barchartcanvas';
import VerticalBarChart from '../helpers/verticalbarchartcanvas';

function PositionChart(props) {
  const [DATA, setData] = useState(null)

  useEffect(() => {

       
    async function fetchData() {
      console.log(props.data)
      try {
        var sourceData = props.data
        console.log(sourceData)
        var dd = {}
        dd.label = [];
        dd.s1 = {};
        dd.s1.name = (props.serie ? props.serie :'Serie')
        dd.s1.data = []
       
       
        for (var key in  sourceData) {
          //console.log(sourceData[key])
          if(sourceData[key].groupName){
            dd.label.push(String(sourceData[key].groupName))
          } else {
            dd.label.push(sourceData[key].n)
            
          }
          if (props.serie === 'position_irr') {
            dd.s1.data.push(parseFloat(sourceData[key].irr_avg, 4))
          } else  if (props.serie === 'tvpi') {
            dd.s1.data.push(parseFloat(sourceData[key].tvpi_avg, 4))
          } else  if (props.serie === 'dpi') {
            dd.s1.data.push(parseFloat(sourceData[key].dpi_avg, 4))
          } else  if (props.serie === 'position_commited_in_user_currency_at_inception_rate') {
            dd.s1.data.push(parseFloat(sourceData[key].position_commited_in_user_currency_at_inception_rate, 4))
          }  else  if (props.serie === 'position_expected_remaining_callable_capital_in_user_currency') {
            dd.s1.data.push(parseFloat(sourceData[key].position_expected_remaining_callable_capital_in_user_currency, 4))
          }
        
        }

        dd.series = [];
        dd.series.push(dd.s1)
        setData(dd);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [props.data, props.serie]);

  return (
    <React.Fragment>
      {DATA && DATA.series && DATA.label && (
        <Row>
          <Col xl={12} sm={12}>
            {props.orientation && props.orientation === 'vertical' ? (
            <VerticalBarChart  series={DATA.series} label={DATA.label} colors={[ "#0bb197"]}  unit = {(props.unit ? props.unit :'')} formatX={(props.formatX ? props.formatX : 'percentage')} />
            ): 
            <BarChart  series={DATA.series} label={DATA.label} colors={[ "#0bb197"]} unit = {(props.unit ? props.unit :'')} formatX={(props.formatX ? props.formatX : 'percentage')} />
           
            }
            </Col>
        </Row>

      )}
    
        </React.Fragment >

      )
  }

export default PositionChart
/* 
         */