
import React from "react";
import {  Row, Container, Col } from "reactstrap";

import AddTransactionShortForm from './addTransactionShortForm.js';

const MoonDanceDashboard = () => {
    document.title = "Dashboard | Moondance";

    
    
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>

        <Row>
            <Col sm={12} md={12} xl={12}>

                    <AddTransactionShortForm  plainPage= {true}/>
            </Col>

        </Row>
          
          </Container>
        </div>
      </React.Fragment>
    );
  };
  
  export default MoonDanceDashboard;
  