import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, } from "reactstrap";
import UseMoondanceApi from "../helpers/api";
import { buildAccountParameter, formatCurrency } from "../helpers/formatData";
import { MoondanceContext } from '../../App';


function DashboardYearly() {

  const moondanceApi = UseMoondanceApi();
  const { selectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);
  const { userCurrency } = useContext(MoondanceContext);

  const [loading, setLoading] = useState(true);
  //console.log(selectedOwner);
  const [DBY, setDashboardYearlyData] = useState({
    source: {},
    calculated: {}
  });

  useEffect(() => {
    async function fetchData() {
      try {
        var api_parameters = {
          account: buildAccountParameter(selectedAccountList),
          status: buildAccountParameter(selectedStatus),
          userCurrency: userCurrency,
        }
        if (api_parameters.account && api_parameters.status) {

          const data = await moondanceApi.fetchPositionCurrentYear(api_parameters);
          //console.log('fetchPositionCurrentYear', data)
          


          //console.log('data.display', data)
          setLoading(false)
          setDashboardYearlyData(data);
        }

      } catch (error) {
        console.log(error);
      }
    }
   fetchData();
  }, [selectedAccountList, selectedStatus, userCurrency]);

  return (
    <React.Fragment>
{(loading ? 'loading' :
      
        <Card >
          <CardBody>

            <table className="table table-condensed MoondanceTable ">
              <thead>
                <tr >
                  <th>Current year ({DBY.data.year})</th>
                  <th style={{ textAlign: 'right' }} className="hideOnMobile">Capital Calls</th>
                  <th style={{ textAlign: 'right' }} className="hideOnMobile">Distribution</th>
                  <th style={{ textAlign: 'right' }}>Cash Flow</th>



                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>Actual (year to date)</td>
                  <td style={{ textAlign: 'right' }} className="hideOnMobile">{formatCurrency(DBY.data.sum_real_capital_call_in_user_currency, userCurrency)}</td>
                  <td style={{ textAlign: 'right' }} className="hideOnMobile">{formatCurrency(DBY.data.sum_real_distribution_in_user_currency, userCurrency)}</td>
                  <td style={{ textAlign: 'right' }}>{formatCurrency(DBY.data.sum_real_capital_call_in_user_currency + DBY.data.sum_real_distribution_in_user_currency, userCurrency)}</td>

                </tr>
                <tr >
                  <td>Expected  (until end of year {DBY.data.year})</td>
                  <td style={{ textAlign: 'right' }} className="hideOnMobile">{formatCurrency(DBY.data.sum_model_capital_call_in_user_currency - DBY.data.sum_real_capital_call_in_user_currency, userCurrency)}</td>
                  <td style={{ textAlign: 'right' }} className="hideOnMobile">{formatCurrency(DBY.data.sum_model_distribution_in_user_currency - DBY.data.sum_real_distribution_in_user_currency, userCurrency)}</td>
                  <td style={{ textAlign: 'right' }}>{formatCurrency((DBY.data.sum_model_capital_call_in_user_currency - DBY.data.sum_real_capital_call_in_user_currency) + (DBY.data.sum_model_distribution_in_user_currency - DBY.data.sum_real_distribution_in_user_currency), userCurrency)}</td>
                </tr>


                <tr>
                  <td>Total {DBY.data.year}</td>
                  <td style={{ textAlign: 'right' }} className="hideOnMobile">{formatCurrency(DBY.data.sum_model_capital_call_in_user_currency, userCurrency)}</td>
                  <td style={{ textAlign: 'right' }} className="hideOnMobile">{formatCurrency(DBY.data.sum_model_distribution_in_user_currency, userCurrency)}</td>
                  <td style={{ textAlign: 'right' }}>{formatCurrency(DBY.data.sum_model_capital_call_in_user_currency + DBY.data.sum_model_distribution_in_user_currency, userCurrency)}</td>

                </tr>

              </tbody>
            </table>
            <span></span>
          </CardBody>
        </Card>   
)}

    </React.Fragment >



  )
}


export default DashboardYearly


/*   <Row>

       {MoondanceLayout.card(
         {
           title: <span> {(loading ? 'loading' : 'Expected call before 31/12/' + DBY.data.year)}</span>,
           value: (loading ? 'loading' :
             
           ),

           subText:
             (loading ? 'loading' :
               <span>Already called in {DBY.data.year} :<br />{(loading ? 'loading' : formatCurrency(DBY.data.sum_real_capital_call_in_user_currency, userCurrency))}</span>
             ),

           xlWidth: 4,
           smWidth: 4,
         }
       )}
       {MoondanceLayout.card(
         {
           title: <span>{(loading ? 'loading' : 'Expected distribution before 31/12/' + DBY.data.year)}</span>,
           value: (loading ? 'loading' :
             (DBY.data.sum_model_distribution_in_user_currency - DBY.data.sum_real_distribution_in_user_currency > 0
               ? <span className="badge bg-success me-1">{formatCurrency(DBY.data.sum_model_distribution_in_user_currency - DBY.data.sum_real_distribution_in_user_currency, userCurrency)}</span>
               : '-'
             )
           ),

           subText:
             (loading ? 'loading' :
               <span>Already distributed in {DBY.data.year} :<br />{(loading ? 'loading' : formatCurrency(DBY.data.sum_real_distribution_in_user_currency, userCurrency))}</span>
             ),

           xlWidth: 4,
           smWidth: 4,
         }
       )}
       {MoondanceLayout.card(
         {
           title: <span>{(loading ? 'loading' : 'Expected Balance before 31/12/' + DBY.data.year)}</span>,
           value: (loading ? 'loading' :
             (DBY.data.sum_model_distribution_in_user_currency - DBY.data.sum_real_distribution_in_user_currency
               - DBY.data.sum_real_capital_call_in_user_currency + DBY.data.sum_model_capital_call_in_user_currency > 0
               ? <span className="badge bg-success me-1">{formatCurrency(
                 DBY.data.sum_model_distribution_in_user_currency - DBY.data.sum_real_distribution_in_user_currency
                 - DBY.data.sum_real_capital_call_in_user_currency + DBY.data.sum_model_capital_call_in_user_currency
                 , userCurrency)}</span>
               : <span className="badge bg-danger me-1">{formatCurrency(
                 DBY.data.sum_model_distribution_in_user_currency - DBY.data.sum_real_distribution_in_user_currency
                 - DBY.data.sum_real_capital_call_in_user_currency + DBY.data.sum_model_capital_call_in_user_currency
                 , userCurrency)}</span>
             )
           ),

           subText:
             (loading ? 'loading' :
               <span>Current balance YTD ({DBY.data.year}) :<br />{(loading ? 'loading' : formatCurrency((DBY.data.sum_real_distribution_in_user_currency + DBY.data.sum_real_capital_call_in_user_currency), userCurrency))}</span>
             ),

           xlWidth: 4,
           smWidth: 4,
         }
       )}


     </Row>*/