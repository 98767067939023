
import React, { useEffect, useState } from "react";
import logodark from "../../assets/images/moondance_logo_sm.png";
import logolight from "../../assets/images/moondance_logo_sm.png";
import { Link } from 'react-router-dom';
import { Card, CardBody, Alert, Col, Container, Form, Input, Label, Row } from "reactstrap";
import UseMoondanceApi from "../helpers/api";


const MoondanceLogin = () => {

    const moondanceApi = UseMoondanceApi();
    const [errors, setErrors] = useState({});
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [mfa, setMfa] = useState();
    const [submitResult, setSubmitResult] = useState(false);
    const [requireMFA, setRequireMfa] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);


    function handleEmailChange(e) {
        setErrors(false)
        setSubmitResult(false)
        setEmail(e.target.value);
    }
    function handleMfa(e) {
        setErrors(false)
        setSubmitResult(false)
        setMfa(e.target.value);
    }
    function handlePasswordChange(e) {
        setErrors(false)
        setSubmitResult(false)
        setPassword(e.target.value);
    }
    const validateEmail = (email) => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    };

    function handleSubmit() {
        const newErrors = {};
        const requestData = {}
        var isError = false
        if (!validateEmail(email)) {
            newErrors.email = ' Please enter a valid email'
            isError = true
        } else {
            requestData.username = email
        }
        if (!password) {
            newErrors.password = ' Please enter your password'
            isError = true
        } else {
            requestData.password = password
        }
        if (requireMFA && !mfa) {
            newErrors.mfa = ' Please enter your mult factor authentication cofde'
            isError = true
        } else if (requireMFA && mfa) {
            requestData.mfa = mfa
        } else {
            requestData.mfa = null
        }
        if (rememberMe && mfa) {
            requestData.rememberMe = true
        } else {
            requestData.rememberMe = false
        }
        if (isError) {
            setErrors(newErrors)

            console.log(newErrors)
        } else {
            moondanceApi.loginToApi(requestData).then(response => {
                setErrors({})
                setSubmitResult(response)
                console.log('login response', response)

                if (!response.token && response.success !== false) {
                    console.log('MFA!')
                    setRequireMfa(true)

                } else if (response.success && response.token) {
                    console.log('login!')
                    localStorage.setItem('authUser', response.token);
                    window.location.href = '/dashboard';
                }
            })
                .catch(error => {
                    newErrors.alert = 'Invalid Login or password !'
                    setErrors(newErrors)
                    setSubmitResult(error)


                });
        }


    }
    useEffect(() => {
        document.body.className = "bg-pattern";

    });

    return (
        <React.Fragment>

            <div className="bg-overlay"></div>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={4}>
                            <Card>
                                <CardBody className="p-4">
                                    <div>
                                        <div className="text-center">
                                            <Link to="/">
                                                <img
                                                    src={logodark}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-dark mx-auto"
                                                />
                                                <img
                                                    src={logolight}
                                                    alt=""
                                                    height="50"
                                                    className="auth-logo logo-light mx-auto"
                                                />
                                            </Link>
                                        </div>
                                        <h4 className="font-size-18 text-muted mt-2 text-center">
                                            Welcome to Moondance !
                                        </h4>
                                        <p className="mb-5 text-center">
                                            Sign in to continue.
                                        </p>
                                        <Form
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col md={12}>
                                                    <div className="mb-4">
                                                        <Label className="form-label">Email</Label>
                                                        <Input
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter email"

                                                            onChange={handleEmailChange}
                                                            type="email"

                                                        />
                                                        {errors.email && (<label className="error mt-2 text-danger">{errors.email}</label>)}
                                                    </div>
                                                    <div className="mb-4">
                                                        <Label className="form-label">Password</Label>
                                                        <Input
                                                            name="password"
                                                            value={password || ""}
                                                            type="password"
                                                            placeholder="Enter Password"
                                                            onChange={handlePasswordChange}

                                                        />

                                                        {errors.password && (<label className="error mt-2 text-danger">{errors.password}</label>)}

                                                        {submitResult.error && (<label className="error mt-2 text-danger">Invalid Login or password !</label>)}
                                                    </div>
                                                        {requireMFA && (
                                                        <div className="mb-4"><br />
                                                            <div>
                                                                <Alert color="warning">
                                                                    <b>Multi-Factor Authentication Required:</b><br />
                                                                    To log in from an unrecognized device, a confirmation code is needed.<br /><br />
                                                                    <b>If you have TOTP configured, please enter the code from your authenticator app.</b><br /><br />
                                                                    <b>If you choose to receive codes via email, enter the code sent to your email address.</b><br />
                                                                    Make sure to check your spam folder if you can't find the email.</Alert>
                                                                <Input
                                                                    name="MFA"
                                                                    className="form-control"
                                                                    placeholder="Enter MFA code"

                                                                    onChange={handleMfa}
                                                                    type="text"

                                                                />
                                                            </div><br/>
                                                            <div>
                                                            <Label className="form-label">
                                                                <input
                                                                    onClick={() => (setRememberMe(!rememberMe))}
                                                                    type="checkbox"
                                                                    checked={rememberMe}
                                                                />&nbsp; Remember me !</Label>
                                                                    <p>  By selecting this option, the system will not require you to re-enter two-factor authentication when logging in from the same browser for the next 15 days</p>
                                                            </div>
                                                        </div>



                                                        )}


                                                    <Row>

                                                        <Col className="col-12">
                                                            <div className="text-md-end mt-3 mt-md-0">
                                                                <Link
                                                                    to="/forgot-password"
                                                                    className="text-muted"
                                                                >
                                                                    <i className="mdi mdi-lock"></i> Forgot your
                                                                    password?
                                                                </Link>
                                                            </div>
                                                        </Col>


                                                    </Row>
                                                    <div className="d-grid mt-4">
                                                        <button
                                                            className="btn btn-primary waves-effect waves-light"
                                                            type="submit"
                                                        >
                                                            Log In
                                                        </button>
                                                    </div>
                                                    <div className="mt-5 text-center">
                                                        <p >
                                                            Don't have an account ?{" "}
                                                            <Link to="/register" className="fw-medium text-primary">
                                                                {" "}
                                                                Register{" "}
                                                            </Link>{" "}
                                                        </p>

                                                    </div>

                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );


}


export default MoondanceLogin;