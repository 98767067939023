
import React, { useState, useContext, useEffect } from "react";
import { Row, Container } from "reactstrap";

import { buildAccountParameter  } from "../helpers/formatData";

import UseMoondanceApi from "../helpers/api";
import MoondanceLayout from "../helpers/layout";
import { MoondanceContext } from '../../App';
import { min } from "lodash";



const MoonDanceDashboard = () => {
  document.title = "Dashboard | Moondance";

  const [loading, setLoading] = useState(true);
  const moondanceApi = UseMoondanceApi();
  const { selectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);
  const { userCurrency } = useContext(MoondanceContext);

  const [yearly_transaction_per_position, set_yearly_transaction_per_position] = React.useState([]);
  const [min_year, set_min_year] = React.useState(0);
  const [max_year, set_max_year] = React.useState(0);
  
  async function fetchYearlyData() {
    try {
      var api_parameters = {
        account: buildAccountParameter(selectedAccountList),
        status: buildAccountParameter(selectedStatus),
        userCurrency: userCurrency,
      }
      if (api_parameters.account && api_parameters.status) {
        const return_data = await moondanceApi.fetchYearlyTransactionPerPosition(api_parameters);
        // Extract the year values and convert them to numbers
        const years = return_data.data.map(item => Number(item.year));
        // Find the minimum and maximum year values
        let min_year= Math.min(...years)
        set_min_year(min_year);

        let max_year= Math.max(...years)
        set_max_year(min_year);
                
        let positionFlow = {};
        return_data.data.forEach((item) => {  
          if(!positionFlow[item.position_id]){

              positionFlow[item.position_id] = {
              id: item.position_id,
              name: item.name,
              balance_in_user_currency:{},
              cash_in_in_user_currency:{},
              cash_out_in_user_currency:{},
              balance_in_commitment_pct:{},
              cash_in_in_commitment_pct:{},
              cash_out_in_commitment_pct:{}, 
            }
          }
          console.log('positionFlow',positionFlow)
          if (item.year !== undefined && item.year !== null) {
            positionFlow[item.position_id].balance_in_user_currency[item.year] = item.balance_in_user_currency;
            positionFlow[item.position_id].cash_in_in_user_currency[item.year] = item.cash_in_in_user_currency;
            positionFlow[item.position_id].cash_out_in_user_currency[item.year] = item.cash_out_in_user_currency;
            positionFlow[item.position_id].balance_in_commitment_pct[item.year] = item.balance_in_commitment_pct;
            positionFlow[item.position_id].cash_in_in_commitment_pct[item.year] = item.cash_in_in_commitment_pct;
            positionFlow[item.position_id].cash_out_in_commitment_pct[item.year] = item.cash_out_in_commitment_pct;
          }
        });

        console.log('fetchYearlyTransactionPerPosition', return_data.data,min_year,max_year,positionFlow)
        setLoading(false)
        set_yearly_transaction_per_position(return_data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchYearlyData();
  }, [selectedAccountList, selectedStatus, userCurrency]);


  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid={true}>
          <Row style={{ marginBottom: '1em' }}>

            <div className="flex">
              <h6><i className="mdi mdi-desktop-mac-dashboard"></i> Transaction per position per year</h6>
              
            </div>
          </Row>
          <Row>
            <hr />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MoonDanceDashboard;
