
import React, { useState, useContext, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Row, Col, Input, InputGroup, Button, CardTitle } from "reactstrap";
import Select from "react-select";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import MoondanceLayout from "../helpers/layout.js";
import UseMoondanceApi from "../helpers/api.js";
import { buildAccountParameter, formatOptionLabel, groupAndSortPositions, } from "../helpers/formatData.js";
import { MoondanceContext } from '../../App.js';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { use } from "i18next";

const DuplicatePosition = () => {

    const moondanceApi = UseMoondanceApi();
    const { addAlert } = useContext(MoondanceContext);
    const [source_position_id, set_source_position_id] = useState(0)
    const [new_position_account_id, set_new_position_account_id] = useState(0)
    const [new_position_account_name, set_new_position_account_name] = useState('')
    const [new_position_commitment, set_new_position_commitment] = useState(1)
    const [source_asset_name, set_source_asset_name] = useState('')
    const [new_position_subscription_date, set_new_position_subscription_date] = useState(null);
    const [new_position_shares, set_new_position_shares] = useState(0)
    const [new_position_duplicate_transaction, set_new_position_duplicate_transaction] = useState(true)
    const [new_position_id, set_new_position_id] = useState(null)
    const [show_position_already_exist, set_show_position_already_exist] = useState(false)
    const [source_asset_currency, set_source_asset_currency] = useState('EUR')
    const [source_commitment, set_source_commitment] = useState(1)
    const [source_shares, set_source_shares] = useState(0)
    const { accountList } = useContext(MoondanceContext);
    const [filtered_account_list, set_filtered_account_list] = useState(accountList);
    const [select_position_list, set_select_position_list] = useState([]);
    const [position_list, set_position_list] = useState([]);
    const [invalid_field, set_invalid_field] = useState([])

    const flatpickrRef = useRef(null);

    const params = useParams();
    const id = params.id;

    //Fetch position List
    useEffect(() => {
        set_filtered_account_list(accountList)
        async function fetchData() {
            var api_parameters = {
                account: buildAccountParameter(accountList),
                status: 'All',
            }
            if (accountList) {
                try {
                    moondanceApi.fetchPositionsList(api_parameters).then(response => {

                        if (response.data.length > 0) {
                            set_select_position_list(groupAndSortPositions(response.data))
                            set_position_list(response.data)

                        }
                    })
                } catch (error) {
                    console.log(error);
                }
            }
        }
        fetchData();
    }, [accountList,]);

    useEffect(() => {
        if (params.id && position_list.length > 0) {
            set_source_position_id(params.id);
            let selected_source_position = position_list.find(position_list => position_list.position_id === Number(params.id))
            let new_filtered_account_list = accountList.filter(account => account.value !== selected_source_position.asset_account_id);
            set_filtered_account_list(new_filtered_account_list)
            set_source_asset_currency(selected_source_position.asset_currency)
            set_source_asset_name(selected_source_position.asset_name)
            set_source_commitment(selected_source_position.position_commited_in_asset_currency)
            set_source_shares(selected_source_position.position_shares)
            
            set_new_position_commitment(selected_source_position.position_commited_in_asset_currency)
            set_new_position_shares(selected_source_position.position_shares)
            set_new_position_account_name(selected_source_position.asset_name)
            set_new_position_subscription_date(selected_source_position.position_subscription_date);

        }
    }, [position_list])

    /// look select2  position
    const formatOptionLabel = (option, { context }) => {
        if (context === "menu") {
            return (
                <div>
                    <div>{option.label}</div>
                    {option.description ? <div style={{ fontSize: '80%', }}>{option.description}</div> : null}
                </div>); // customize your option label for the dropdown menu here
        }
        return <div>{option.label}</div>; // for singleValue (the label when an option is selected)
    };
    const groupStyles = {
        backgroundColor: '#212529',
        padding: '10px 15px',
        marginLeft: '-12px',
        marginRight: '-12px',
        fontSize: '1.25em',
        color: '#FFF',
        //... add more styles as per your design requirements
    };
    const formatGroupLabel = (data) => (
        <div style={groupStyles}>
            {data.label}
        </div>
    );
    function handleSelectPosition(selected_position) {
        set_source_position_id(selected_position.value)

        let selected_source_position = position_list.find(x => x.position_id === selected_position.value)
        let new_filtered_account_list = accountList.filter(account => account.value !== selected_source_position.asset_account_id);
        set_filtered_account_list(new_filtered_account_list)
        set_source_asset_currency(selected_source_position.asset_currency)
        set_source_asset_name(selected_source_position.asset_name)
        set_source_commitment(selected_source_position.position_commited_in_asset_currency)
        set_source_shares(selected_source_position.position_shares)
        set_new_position_commitment(selected_source_position.position_commited_in_asset_currency)
        set_new_position_shares(selected_source_position.position_shares)
        set_new_position_account_name(selected_source_position.asset_name)
        set_new_position_subscription_date(selected_source_position.position_subscription_date);
        console.log('selected_source_position', selected_source_position)
        if (new_position_account_id) {
            let same_position_in_selected_account = position_list.find(x => x.asset_name === selected_source_position.asset_name)
            if (same_position_in_selected_account) {
                set_show_position_already_exist(true)
            } else {
                set_show_position_already_exist(false)
            }
        }
        //check if position is already present in destination account

    }
    function handle_select_destination_account(selected_account_id) {
        set_new_position_account_id(selected_account_id);
        //check if this position alrrady exist in this account
        let selected_source_position = position_list.find(x => x.position_id === Number(source_position_id))
        let same_position_in_selected_account = position_list.find(x => x.asset_name === selected_source_position.asset_name && x.asset_account_id === Number(selected_account_id))
        if (same_position_in_selected_account) {
            set_show_position_already_exist(true)
        } else {
            set_show_position_already_exist(false)
        }
    }
    function handleSelectDate(selectedDates, dateStr, instance) {
        let newDate = MoondanceLayout.formatDate_DMY_to_YMD(dateStr);
        set_new_position_subscription_date(newDate);
    }
    function handle_commitment_change(new_commitment) {
        set_new_position_commitment(new_commitment)
        
        let adapted_share_count = Math.round(100*new_commitment / source_commitment * source_shares)/100
        set_new_position_shares(adapted_share_count)
        
    }
    async function handle_save_form() {
        //Check if all fields are filled
        var newAlert = []
        if (source_position_id === 0) {
            newAlert['source_position'] = 'Please select a position to duplicate'
        }

        if (parseInt(new_position_account_id) === 0) {
            newAlert['destination_account'] = 'Please select a destination account'
        }
        if (parseInt(new_position_commitment) === 0) {
            newAlert['destination_commitment'] = 'Please set commitment'
        }
       /*if (parseInt(new_position_shares) === 0) {
            newAlert['destination_shares'] = 'Please set number of shares'
        }*/

        if (Object.keys(newAlert).length > 0) {
            set_invalid_field(newAlert)
            return false;
        }
        var post_parameters = {}
        post_parameters.source_position_id = source_position_id
        post_parameters.destination_account_id = new_position_account_id
        post_parameters.destination_commitment = new_position_commitment

        post_parameters.duplicate_transaction = new_position_duplicate_transaction
        post_parameters.destination_transaction_ratio = new_position_commitment / source_commitment
        post_parameters.destination_subscription_date = new_position_subscription_date
        post_parameters.destination_shares = new_position_shares


        try {
            var result = await moondanceApi.duplicateAsset(post_parameters)
            console.log('result', result)
            if (result.success) {
                set_new_position_id(result.id)
                addAlert({
                    status: 'success',
                    title: 'Position duplicated',
                    message: 'The position ' + source_asset_name + ' has  been duplicated to account ' + new_position_account_name,
                })
                return true;
            } else {
                addAlert({
                    status: 'danger',
                    title: (result.error ? ' ' + result.error : ''),
                    message: 'The position ' + source_asset_name + ' has NOT been duplicated to account ' + new_position_account_name,
                })
                return false
            }
        } catch {
            addAlert({
                status: 'danger',
                title: (result.error ? ' ' + result.error : 'Error'),
                message: 'The position ' + source_asset_name + ' has NOT been duplicated to account ' + new_position_account_name,
            })
            return false
        }
        console.log('result', result)


    }

    return (
        <React.Fragment>

            <div className="page-content">
                <div className="flex">
                    <h1 >Duplicate position</h1>
                    <div>
                        <Link to={'/position/' + source_position_id} className="btn btn-outline-primary form-control">View position</Link>
                    </div>
                </div>


                <Card>
                    <CardBody>
                        <Row>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Source Position</Form.Label>
                                <Col sm="8">
                                    <Select

                                        menuPortalTarget={document.body}
                                        onChange={(f) => {
                                            handleSelectPosition(f);
                                        }}
                                        placeholder="Select a position to duplicate"
                                        options={select_position_list}
                                        classNamePrefix="select2-selection"
                                        formatOptionLabel={formatOptionLabel}
                                        value={select_position_list.flatMap(group => group.options).find(option => option.value === parseInt(source_position_id))}
                                        formatGroupLabel={formatGroupLabel}

                                        styles={MoondanceLayout.select2customStyles}
                                    />

                                </Col>
                            </Form.Group>

                        </Row>

                        <hr />
                        {(source_position_id > 0) ?

                            <Row>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label column sm="2">Destination Account</Form.Label>
                                    <Col sm="8">
                                        <Select

                                            onChange={(e) => { handle_select_destination_account(e.value) }}

                                            value={(new_position_account_id ? accountList.find(option => option.value === new_position_account_id) : null)}
                                            placeholder="Select Account"
                                            formatOptionLabel={formatOptionLabel}
                                            styles={MoondanceLayout.select2customStyles}

                                            options={filtered_account_list}
                                            classNamePrefix="select2-selection"
                                        />
                                        {(invalid_field && invalid_field['destination_account']) ? <p>{invalid_field['destination_account']}</p> : ''}
                                        {show_position_already_exist ? <p className="alert alert-warning" style={{ marginTop: "1em" }}><b>Warning : A position with the same name  already exist in this account !</b></p> : ''}

                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label column sm="2">Destination Commitment</Form.Label>


                                    <Col sm="8">
                                        <InputGroup>
                                            <input
                                                className="form-control"
                                                type="number"

                                                value={
                                                    new_position_commitment
                                                        ? new_position_commitment
                                                        : 1}
                                                onChange={(e) => { handle_commitment_change(parseInt(e.target.value)) }}
                                                placeholder="Commitment"
                                            />
                                            <div className="input-group-append" >
                                                <span className="input-group-text">
                                                    {source_asset_currency}
                                                </span>
                                            </div>
                                        </InputGroup>
                                        {invalid_field['commitment'] ? <div className="alert alert-danger" role="alert">{invalid_field['commitment']}</div> : ''}
                                    </Col>


                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label column sm="2">Shares</Form.Label>
                                    <Col sm="8">
                                        <InputGroup>
                                            <input
                                                className="form-control"
                                                type="number"

                                                value={new_position_shares ?
                                                    new_position_shares
                                                    : 1}
                                                onChange={(e) => { set_new_position_shares(e.target.value); }}
                                                placeholder="Shares"
                                            />


                                        </InputGroup>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" >
                                    <Form.Label column sm="2">Subscription date</Form.Label>
                                    <Col sm="8">
                                        <InputGroup>
                                            <Flatpickr
                                                className="form-control "
                                                onChange={handleSelectDate}
                                                value={new_position_subscription_date}
                                                options={{
                                                    clickOpens: false,  // ensure this is true
                                                    allowInput: true,   // ensure direct input is allowed
                                                    dateFormat: "d/m/Y",
                                                }}
                                                ref={flatpickrRef}

                                            />
                                            <div className="input-group-append" onClick={() => flatpickrRef.current.flatpickr.open()}>
                                                <span className="input-group-text">
                                                    <i className="mdi mdi-calendar"></i>
                                                </span>
                                            </div>
                                        </InputGroup>

                                        {(invalid_field && invalid_field['destination_shares']) ? <p>{invalid_field['destination_shares']}</p> : ''}
                                    </Col>
                                </Form.Group>
                                < Form.Group as={Row} className="mb-3" >

                                    <Form.Label column sm="2"> </Form.Label>
                                    <Form.Label column sm="8">
                                        <input
                                            type="checkbox"
                                            checked={new_position_duplicate_transaction}
                                            onChange={(e) => { set_new_position_duplicate_transaction(!new_position_duplicate_transaction) }} />&nbsp; Calculate transactions based on source position and new position commitment</Form.Label >
                                </Form.Group>

                                < Form.Group as={Row} className="mb-3" >
                                    <Col lg="12">

                                        <label id="add_transaction''label" >&nbsp;</label>
                                        <button
                                            type="button"
                                            className="btn btn-primary form-control"
                                            onClick={() => { handle_save_form() }}
                                        >
                                            Duplicate position
                                        </button>
                                    </Col>
                                </Form.Group>


                                {(new_position_id ?
                                    < Form.Group as={Row} className="mb-3" >
                                        <Col lg="12">
                                            <p>This position has been duplicated</p>
                                            <Link to={'/position/' + new_position_id} className="btn btn-outline-primary form-control">View new position</Link>

                                        </Col>
                                    </Form.Group>
                                    : '')}
                            </Row>
                            : ''}
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default DuplicatePosition;