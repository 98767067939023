
import React, { useState, useContext, useEffect } from "react";


import * as XLSX from 'xlsx';
import MoondanceLayout from '../helpers/layout';
import Select from "react-select";
import Form from 'react-bootstrap/Form';
import { MoondanceContext } from '../../App.js';
import UseMoondanceApi from "../helpers/api.js";
import {
  Card, Container, CardBody, Row, Col, InputGroup, Table, NavItem,
  NavLink, Nav, TabPane, TabContent
} from "reactstrap";
import { buildAccountParameter } from "../helpers/formatData.js";

const formatOptionLabel = (option, { context }) => {
  if (context === "menu") {
    return (
      <div>
        <div>{option.label}</div>
        {option.description ? <div style={{ fontSize: '80%' }}>{option.description}</div> : null}
      </div>
    );
  }
  return <div>{option.label}</div>;
};

function ExcelReader() {

  const { addAlert } = useContext(MoondanceContext);
  const moondanceApi = UseMoondanceApi();
  const { accountList } = useContext(MoondanceContext);
  const [all_positions_list, set_all_positions_list] = useState([]);
  const [activeTab, setActiveTab] = useState('import')
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  const [fileName, setFileName] = useState('');
  const [sheetNames, setSheetNames] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState('');
  const [passwordNeeded, setPasswordNeeded] = useState(false);
  const [importType, setImportType] = useState('transaction');
  const [startingRow, setStartingRow] = useState(1);
  const [startingColumn, setStartingColumn] = useState(1);
  const [endingColumn, setEndingColumn] = useState(1000);
  const [endingRow, setEndingRow] = useState(1000);
  const [selectType, setSelectedType] = useState('transaction');
  const [importTypeList] = useState([
    { id: 'transaction', value: 'transaction', label: 'Transactions' },
    { id: 'position', value: 'position', label: 'Positions' },
    { id: 'nav', value: 'nav', label: 'Net Asset Values' }
  ]);
  const [asset_id, set_asset_id] = useState()
  const [selectedAccount, setSelectedAccount] = useState(false);
  const [dataPreview, setDataPreview] = useState('<></>');
  const [cellData, setCellData] = useState({});
  const [cellMatching, setCellMatching] = useState({});
  const [importTemplateId, setImportTemplateId] = useState();
  const [importTemplateName, setImportTemplateName] = useState('importTemplate');
  const [importTemplateAccount, setImportTemplateAccount] = useState();
  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [positionAlreadyExistInAccount, setPositionAlreadyExistInAccount] = useState(false);
  const [importPosition, setImportPosition] = useState(true);
  const [importTransaction, setImportTransaction] = useState(true);
  const [importTemplateList, setImportTemplateList] = useState([]);
  const [importTypeRequestData, setImportTypeRequestData] = useState([
    // Assets
    {
      id: 'asset_name',
      value: 'name',
      label: 'Name',
      type: 'string',
      table: 'position',
      required: true
    },
    {
      id: 'asset_emitter',
      table: 'position',
      label: 'General Partner',
      type: 'string'
    },

    {
      id: 'asset_type',
      table: 'position',
      label: 'Type',
      type: 'string',
      required: true,
      default: 'others'
    },
    {
      id: 'asset_description',
      label: 'Description',
      table: 'position',
      type: 'string'
    },
    {
      id: 'asset_currency',
      label: 'Currency',
      table: 'position',
      type: 'string',
      default: 'EUR'
    },
    {
      id: 'asset_inception_date',
      label: 'Subscription date',
      table: 'position',
      type: 'date',
      required: true
    },
    {
      id: 'asset_vintage',
      label: 'Vintage (First Year)',
      table: 'position',
      type: 'string',
      required: true
    },
    {
      id: 'asset_investment_period',
      label: 'Investment Period',
      table: 'position',
      type: 'int',
      default: 5,
      required: true
    },
    {
      id: 'asset_detention_period',
      label: 'Detention Period',
      table: 'position',
      type: 'int',
      default: 5,
      required: true
    },
    {
      id: 'asset_expected_drawdown',
      label: 'Expected Drawdown',
      table: 'position',
      type: 'float',
      default: 1,
      required: true
    },
    {
      id: 'asset_expected_irr',
      label: 'Expected IRR',
      table: 'position',
      type: 'float',
      default: 0.10,
      required: true
    },
    {
      id: 'asset_expected_tvpi',
      label: 'Expected TVPI',
      table: 'position',
      type: 'float',
      default: 1.60,
      required: true
    },
    {
      id: 'asset_intermediate',
      label: 'Intermediate',
      table: 'position',
      type: 'string',
      default: ''
    },
    {
      id: 'asset_feederName',
      label: 'Feeder Name',
      table: 'position',
      type: 'string',
      default: ''
    },
    {
      id: 'asset_feederType',
      label: 'Legal Structures',
      table: 'position',
      type: 'string',
      default: ''
    },
    {
      id: 'position_shares',
      label: 'Initial Shares at Subscription',
      table: 'position',
      type: 'float',
      default: 0
    },
    {
      id: 'position_commitment',
      label: 'Committed Amount',
      table: 'position',
      type: 'float',
      default: 0,
      required: true
    },
    {
      id: 'position_id',
      label: 'Position ID',
      table: 'transaction',
      type: 'int',
      multipleAllowed: true
    },
    {
      table: 'transaction',
      id: 'date',
      label: 'Date',
      type: 'date',
      multipleAllowed: true
    },
    {
      table: 'transaction',
      id: 'value',
      label: 'Value',
      type: 'float',
      multipleAllowed: true
    },
    {
      table: 'transaction',
      id: 'shares',
      label: 'Shares',
      type: 'float',
      multipleAllowed: true
    },
    {
      table: 'transaction',
      id: 'type',
      label: 'Type',
      type: 'string',
      multipleAllowed: true
    },
    {
      table: 'transaction',
      id: 'currency',
      label: 'Currency',
      type: 'string',
      multipleAllowed: true
    },
    {
      table: 'transaction',
      id: 'notes',
      label: 'Notes',
      type: 'string',
      multipleAllowed: true
    },
    {
      table: 'transaction',
      id: 'bank',
      label: 'Bank',
      type: 'string',
      multipleAllowed: true
    },
  ]);
 
  const assignCellToValue = (value, cellKey) => {
    console.log('assignCellToValue start', value, cellKey, cellMatching);

    setCellMatching(prevCellMatching => {
      let newCellMatching = { ...prevCellMatching };
      console.log('newCellMatching', prevCellMatching, newCellMatching);

      let [column, row] = cellKey.split('_').map(Number);

      newCellMatching[value] = {
        cell: convertToLetter(column) + (row + 1),
        row: row,
        column: column,
        isAuto: true,
        value: (data && data[row] && data[row][column] ? data[row][column] :
          (importTypeRequestData.find(option => option.id === value).default ? importTypeRequestData.find(option => option.id === value).default : null))
      };

      console.log('assignCellToValue end', newCellMatching);
      return newCellMatching;
    });
  };
  // set subscription date to 1/1 of vintage year if empty
  useEffect(() => {
    console.log('cellMatching changed', cellMatching);
    /*  if (cellMatching['first_year'] && cellMatching['first_year']['value'] && (!cellMatching['inception_date'] || cellMatching['inception_date'].value === '')) {
        let newCellMatching = { ...cellMatching }
        if (!newCellMatching['inception_date']) { newCellMatching['inception_date'] = {} }
        newCellMatching['inception_date'].value = cellMatching['first_year']['value'] + '-01-01'
        setCellMatching(newCellMatching)
      }
      if (cellMatching['name'] && cellMatching['name'].value) {
        console.log('check if position already exist in account')
        setPositionAlreadyExistInAccount(isPositionAlreadyExisting())
      }
      console.log('cellMatching', cellMatching)*/

  }, [cellMatching, selectedAccount]);

  useEffect(() => {
    if (!accountList || accountList.length === 0) return;
    let newImportTypeRequestData = [];
    importTypeRequestData.forEach((option) => {
      let newOptions = option;
      if (option.id === 'position_account') {
        newOptions.options = accountList;
      }
      newImportTypeRequestData.push(newOptions);
    });
    setImportTypeRequestData(newImportTypeRequestData);
    getImportTemplate();
  }, [accountList]);


  const getImportTemplate = async () => {
    console.log('getImportTemplate')
    try {
      const response = await moondanceApi.getImportTemplate();
      if (response.data) {
        console.log('getImportTemplate', response.data)
        setImportTemplateList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveTemplate = async (asNew) => {
    if (asNew) {
      setImportTemplateId(null);
    }
    let template = {};
    Object.entries(cellMatching).forEach(([key, cell]) => {
      if (cell.cell) {
        template[key] = cell.cell;
      }
    });
    let jsonTemplate = JSON.stringify(template);

    let saveTemplate = { id: asNew ? false : importTemplateId, name: importTemplateName, template: jsonTemplate, account_id: importTemplateAccount };
    try {
      const response = await moondanceApi.saveImportTemplate(saveTemplate);
      if (response.data) {
        setImportTemplateId(response.data);
        addAlert({
          status: 'success',
          title: 'Import template has been saved!',
          message: 'The template has been saved successfully'
        })
      }
    } catch (error) {
      addAlert({
        status: 'danger',
        title: 'Import template was not saved !',
        message: (error ? error : 'An error occured')
      })
      console.log(error);
    }
  };

  const convertToLetter = (num) => {
    let letter = '';
    while (num >= 0) {
      letter = String.fromCharCode(num % 26 + 65) + letter;
      num = Math.floor(num / 26) - 1;


    }
    return letter;
  };

  const sortByLabel = (data) => {
    return data.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
  };

  const [data, setData] = useState([]);

  const handleButtonClick = () => {
    document.getElementById('fileInput').click();
  };

  function excelReferenceToIndex(reference) {
    if (!reference || reference.length < 2) return null;

    function columnToNumber(column) {
      let number = 0;
      for (let i = 0; i < column.length; i++) {
        number = number * 26 + (column.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
      }
      return number - 1;
    }

    function parseCell(cell) {
      const match = cell.match(/([A-Z]+)(\d+)/);
      if (!match) return null;
      const column = match[1];
      const row = parseInt(match[2], 10);
      return { col: columnToNumber(column), row: row - 1 };
    }

    const parts = reference.split(':');
    const startCell = parseCell(parts[0]);
    console.log('parts',parts)
    if (parts.length === 1) {
      return startCell;
    } else if (parts.length === 2) {
      const endCell = parseCell(parts[1]);
      return { start: startCell, end: endCell };
    } else {
      return null;
    }
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFileName(file ? file.name : 'Sélectionner le fichier');
    await handleFile(event);
  };

  const handleFile = async (e) => {
    return new Promise((resolve, reject) => {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          console.log('file read');
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          setSheetNames(workbook.SheetNames);
          resolve();
        } catch (error) {
          console.error('Error reading file:', error);
          if (error.message.includes('password')) {
            console.error('File is password-protected');
            const password = await promptPassword();
            if (password !== '' && password !== null) {
              console.log('try password');
              try {
                const data = event.target.result;
                const workbook = XLSX.read(data, { type: 'binary', password });
                setSheetNames(workbook.SheetNames);
                resolve();
              } catch (error) {
                console.error('Error reading file with password:', error);
                alert('Failed to read the file with the provided password. Please try again.');
              }
            } else {
              setPasswordNeeded(true);
              alert('Password is required to open the file.');
            }
          } else {
            console.error('Error reading file:', error);
            alert('An error occurred while reading the file. Please try again.');
          }
        }
      };

      reader.readAsBinaryString(file);
    });
  };

  function promptPassword() {
    return new Promise((resolve) => {
      const password = prompt('This file is password-protected. Please enter the password:');
      resolve(password);
    });
  }

  const handleSheetSelection = (sheetName) => {
    setSelectedSheet(sheetName);
    loadFile(sheetName)
  };

  const loadFile = (sheetName) => {
    const file = document.querySelector('input[type=file]').files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setData(jsonData);
    };

    reader.readAsBinaryString(file);

  }

  const handleCellReferenceChange = (optionId, reference) => {
    let decodedCellRef = excelReferenceToIndex(reference);
    let newCellMatching = { ...cellMatching };
    newCellMatching[optionId] = {
      cell: reference,
      endCell: decodedCellRef?.end ?? null,
      row: decodedCellRef?.row ?? null,
      column: decodedCellRef?.col ?? null,
      isAuto: true,
      value: data?.[decodedCellRef?.row]?.[decodedCellRef?.col] ?? importTypeRequestData.find(option => option.id === optionId).default ?? null,
    };
    setCellMatching(newCellMatching);
  };

  const selectImportTemplate = (selectedTemplateId) => {

    if (importTemplateList && importTemplateList.length > 0 && selectedTemplateId) {
      let selectedTemplate = importTemplateList.find(template => template.id === parseInt(selectedTemplateId));
      let loadedTemplate = JSON.parse(selectedTemplate.template);
      let newCellMatching = {};

      Object.entries(loadedTemplate).forEach(([key, value]) => {
        let decodedCellRef = excelReferenceToIndex(value);
        newCellMatching[key] = {
          cell: value,
          row: decodedCellRef.row !== null ? decodedCellRef.row : null,
          column: decodedCellRef.col !== null ? decodedCellRef.col : null,
          isAuto: true,
          value: (data && data[decodedCellRef.row] && data[decodedCellRef.col] ? data[decodedCellRef.row][decodedCellRef.col] :
            ((importTypeRequestData.find(option => option.id === key) && importTypeRequestData.find(option => option.id === key).default) ? importTypeRequestData.find(option => option.id === key).default : null))
        };
      });

      setCellMatching(newCellMatching);
      setImportTemplateId(selectedTemplateId)
      setImportTemplateName(selectedTemplate.name)


      loadFile(selectedSheet)

    }

  };


  const savePosition = () => {
    if (isPositionAlreadyExisting()) {
      console.log('position already exist in this account')
    } else {

      console.log('position does not exist in this account')
    }


  }

  const isPositionAlreadyExisting = () => {
    console.log('isPositionAlreadyExisting')
    if (!cellMatching) { return true }
    if (!selectedAccount) {
      console.log('no account id set for this position ')
      return false
    }
    else {
      fetch_all_position_list().then((result) => {
        console.log('list', result)

      })
      //list position in this account
      // compare them with matchingKeys['position_name']

    }



  }
  async function fetch_all_position_list() {
    //console.log('fetch_all_position_list', accountList)
    var api_parameters = {
      account: buildAccountParameter(accountList),
      status: 'All',
      userCurrency: userCurrency,
    }
    //console.log('api_parameters', api_parameters)

    try {
      const response = await moondanceApi.fetchPositionsList(api_parameters)

      if (response.data.length > 0) {
        set_all_positions_list(response.data)
        //console.log('fetch_all_position_list', response.data)
        return response.data

      } else {
        return []
      }

    } catch (error) {
      console.log(error);
      return []
    }


    return []

  }

  const isMissingData = (table) => {
    let missingData = false;
    if (!importTypeRequestData) { return false }
    importTypeRequestData.map((item, index) => {
      if (item.table === table && item.required && !item.default) {
        if (!cellMatching[item.id] || !cellMatching[item.id].value) {
          missingData = true;
        }
      }
    })
    return missingData


  }

  useEffect(() => {
    renderTable();
    console.log('import template id changed')

  }, [importTemplateId]);

  useEffect(() => {
    data.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        const matchingKey = findMatchingKey(convertToLetter(colIndex) + (parseInt(rowIndex) + 1));
        if (matchingKey) {
          assignValueToKey(matchingKey.id, data[rowIndex][colIndex]);
        }
      });
    });
  }, [data, importTypeRequestData]);

  useEffect(() => {
    setDataPreview(renderTable());
  }, [startingRow, endingRow, startingColumn, endingColumn, data,cellMatching]);

  const findMatchingKey = (cell) => {
    let found = null;
    Object.entries(cellMatching).forEach(([key, value]) => {
      if (value.cell === cell) {
        found = importTypeRequestData.find(option => option.id === key);
      }
    });
    return found;
  };
  


  const assignValueToKey = (key, value) => {
    let newCellMatching = { ...cellMatching };
    newCellMatching[key].value = value;
    // setCellMatching(newCellMatching);
  };


  async function asset_() {

    var asset_post_data = {
      'asset_status': cellMatching['asset_status'].value,
      'asset_name': cellMatching['asset_name'].value,
      'asset_emitter': cellMatching['asset_emitter'].value,
      'asset_account_id': selectedAccount,
      'asset_type': cellMatching['asset_type'].value,
      'asset_currency': cellMatching['asset_currency'].value,
      'asset_description': cellMatching['asset_description'].value,
      'asset_inception_date': MoondanceLayout.formatDate_DMY_to_YMD(cellMatching['asset_inception_date']),
      'asset_investment_period': cellMatching['asset_investment_period'].value,
      'asset_detention_period': cellMatching['asset_detention_period'].value,
      'asset_expected_irr': cellMatching['asset_expected_irr'].value / 100,
      'asset_expected_tvpi': cellMatching['asset_expected_tvpi'].value,
      'asset_expected_drawdown': cellMatching['asset_expected_drawdown'].value / 100,
      'asset_intermediate': String(cellMatching['asset_asset_intermediate'].value),
      'asset_feeder_name': String(cellMatching['asset_expected_drawdown'].value),
      'asset_feeder_type': String(cellMatching['asset_feederName'].value),
      'asset_vintage_year': cellMatching['asset_first_year'].value,
      'asset_geographies': [],
      'asset_sectors': [],
      'asset_model_capitalcall': [],
      'asset_model_distribution': []
    };

    console.log('saveAsset', asset_post_data)

    /* var updateMode = false
     try {
        
 
 
             //NEW ASSET
             console.log('NEW ASSET', asset_post_data)
             var result = await moondanceApi.createAsset(asset_post_data)
             //console.log('saveAsset', result)
             //console.log('saveAsset: Result ID', result.id)
             set_asset_id(result.id)
             savePosition(0, result.id)
         
 
         var accountName = accountList.find(option => option.value === selectedAccount)
 
         if (result.success) {
             addAlert({
                 status: 'success',
                 title: 'Position ' + (updateMode ? 'Update' : 'Created'),
                 message: 'The asset ' + cellMatching['asset_name'].value + ' has been created in account ' + accountName.label 
             });
 
             return true;
         } else {
             addAlert({
                 status: 'danger',
                 title: (result.error ? ' ' + result.error : ''),
                 message: 'The asset ' + cellMatching['asset_name'].value + ' has  NOT been created in account ' + accountName.label 
               });
             return false;
         }
     } catch (error) {
         addAlert({
             status: 'danger',
             title: ((result && result.error) ? 'Undefined Error ' + result.error : ''),
             message: 'The asset ' + cellMatching['asset_name'].value + ' has  NOT been created in account ' + accountName.label 
           });
         return false;
     }
 */

  }

  const renderTable = () => {
    if (data.length === 0) return <p>Sheet is empty</p>;
    if (data.length < endingRow) { setEndingRow(data.length); }

    const maxColumnCount = data.reduce((max, row) => Math.max(max, row.length), 0);
    const getColumnHeaders = () => {
      let headers = [];
      for (let i = 0; i < maxColumnCount; i++) {
        headers.push(convertToLetter(i));
      }
      return headers;
    };

    const Dropdown = ({ cellKey, label, options, cellValue }) => {
      const [showDrop, setShowDrop] = useState(false);

      const handleShowDropdownButtonClick = (key) => {
        setShowDrop(!showDrop);
      };

      if (showDrop) {
        let [column, row] = cellKey.split('_').map(Number);
        return (
          <div>
            <button className="btn btn-sm btn-link" onClick={() => handleShowDropdownButtonClick(cellKey)}>
              <span className="bx bx-caret-down-square"></span>
            </button>
            {cellValue}
            <br />
            <Row>
              <Col md={6}>
                <select
                  styles={MoondanceLayout.select2customStyles}
                  onChange={(e) => {
                    assignCellToValue(e.target.value, cellKey);
                    handleCellReferenceChange(e.target.value, `${convertToLetter(column)}${row + 1}`);
                  }}
                >
                  <option value="">Assign Cell to value</option>
                  <option value="">Not assigned</option>
                  {options.map((option, index) => (
                    (( importPosition)) &&
                    <option
                      key={index}
                      value={option.id} disabled={
                        (cellMatching[option.id] && cellMatching[option.id].column !== column && cellMatching[option.id].row !== row)
                      }>
                      {option.label} - ({MoondanceLayout.capitalizeFirstLetter(option.table)})
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </div>
        );
      } else {
        return (
          <button className="btn btn-sm btn-link" onClick={() => handleShowDropdownButtonClick(cellKey)}>
            <span className="bx bx-caret-down-square"></span>{cellValue}
          </button>
        );
      }
    };

    const renderRow = (row, rowIndex) => (
      <tr key={rowIndex}>
        <th className="tableXlsImportColHead">{rowIndex + 1}</th> {/* Row number */}
        {Array.from({ length: maxColumnCount }).map((_, index) => (
          <td className={data[rowIndex] && data[rowIndex][index] ? 'tableXlsImportCell' : 'tableXlsImportCellDisabled'} key={index}>
            <div className="d-flex justify-content-between">
              {data[rowIndex] && data[rowIndex][index] && (
                <>
                  <Dropdown
                    cellKey={`${index}_${rowIndex}`}
                    label="Cell Type"
                    options={importTypeRequestData}
                    cellValue={data[rowIndex][index]}
                  />
                </>
              )}
              {findMatchingKey(convertToLetter(index) + (rowIndex + 1)) && (
                <><span className="badge bg-success" style={{ margin: '1em' }}>
                  {findMatchingKey(convertToLetter(index) + (rowIndex + 1)).label}
                </span>
                <p> {(findMatchingKey(convertToLetter(index) + (rowIndex + 1)).multipleAllowed ? 'extend' :'')}</p>
                </>
              )}
            </div>
          </td>
        ))}
      </tr>
    );

    return (
      <div style={{ marginTop: '2em' }}>
        <p className="mt-0 card-title">Sheet Preview</p>
        <p>Click on a cell to assign it to a data field.</p>

        <div className="scrollable-table">
          <table className="tablexlsImportHead">
            <thead>
              <tr>
                <th className="tableXlsImportHead"></th>
                {getColumnHeaders().map((header, index) => (
                  <th className="tableXlsImportHead" key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => renderRow(row, rowIndex))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <h1>Import Data from Excel File</h1>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={(activeTab === 'import' ? "active nav-link " : "")}
                onClick={() => { setActiveTab('import') }}

              >Import File
              </NavLink>
            </NavItem>
            {(selectedSheet ? <>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={(activeTab === 'position' ? "active nav-link " : "")}
                  onClick={() => { setActiveTab('position') }}
                >Position
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={(activeTab === 'transaction' ? "active nav-link " : "")}
                  onClick={() => { setActiveTab('transaction') }}

                >Transaction
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={(activeTab === 'Nav' ? "active nav-link " : "")}
                  onClick={() => { setActiveTab('Nav') }}

                >Nav
                </NavLink>
              </NavItem> </> : '')}
            {(cellMatching ? <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={(activeTab === 'template' ? "active nav-link " : "")}
                onClick={() => { setActiveTab('template') }}

              >Save Template
              </NavLink>
            </NavItem> : '')}
          </Nav>

          <TabContent activeTab={activeTab} className="p-3">
            <TabPane tabId="import" id="import">
              <br />
              <p className="mt-0 card-title">Select file to import (.xls or .csv)</p>
              <Form.Group as={Col} className="mb-3">
                {fileName ? (
                  <p>
                    File Selected: <b>{fileName}</b>
                    <button style={{ marginLeft: '1em' }} className="btn btn-sm btn-outline-primary" onClick={handleButtonClick}>Select Another File</button>
                  </p>
                ) : (
                  <button className="btn btn-primary" onClick={handleButtonClick}>Select Excel File</button>
                )}
                <input
                  type="file"
                  id="fileInput"
                  onChange={handleFileChange}
                  accept=".xls,.xlsx"
                  style={{ display: 'none' }}
                />
              </Form.Group>

              <Form.Label >Select the account :</Form.Label>
              <Form.Group as={Row}>
                <Select
                  onChange={(e) => {
                    setSelectedAccount(e.value)
                  }}
                  placeholder="Select Account"
                  formatOptionLabel={formatOptionLabel}
                  styles={MoondanceLayout.select2customStyles}
                  options={accountList}
                  classNamePrefix="select2-selection"
                />
              </Form.Group>
              {sheetNames.length > 0 && importTemplateList.length > 0 && (
                <div style={{ marginTop: '1em' }}>
                  <p className="mt-0 card-title">Use an import template</p>
                  <select className="form-control" onChange={(e) => selectImportTemplate(e.target.value)}>
                    <option value="">Do not use a template</option>
                    {importTemplateList.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name} - {item.created_by_first_name} {item.created_by_last_name} - {MoondanceLayout.timestampToDateString(item.timestamp)}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {sheetNames.length > 0 && (
                <div><br />
                  <p className="mt-0 card-title">Select the active sheet</p>
                  <select className="form-control" styles={MoondanceLayout.select2customStyles} onChange={(e) => handleSheetSelection(e.target.value)}>
                    <option value="">Not assigned</option>
                    {sheetNames.map((name, index) => (
                      <option key={index} value={name}>{name}</option>
                    ))}
                  </select>
                </div>
              )}
              {dataPreview}


              {((selectedSheet || selectedAccount) ?
                <div style={{ marginTop: '2em' }}>
                  <button
                    className='btn btn-primary'
                    onClick={() => { setActiveTab('position') }}
                  >Import Position</button>

                  <button
                    style={{ marginLeft: '1em' }}
                    className='btn btn-primary'
                    onClick={() => { setActiveTab('transaction') }}
                  >Import Transaction</button>

                </div>
                : '')}



            </TabPane>

            {selectedSheet && (
              <TabPane tabId="position" id="position">

                {selectedSheet && (
                  <Card>
                    <CardBody>
                      <p className="mt-0 card-title">Import Position</p>



                      <Table className="MoondanceTable table-centered">
                        <tbody>
                          {importTypeRequestData.map((option, index) => (
                            (option.table === 'position' ?
                              <tr key={index}>
                                <td>{option.label} {option.id}</td>
                                <td>
                                  <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={cellMatching[option.id]?.cell || ''}
                                    style={{ maxWidth: '5em' }}
                                    onChange={(e) => handleCellReferenceChange(option.id, e.target.value)}
                                  />
                                </td>
                                <td>
                                  <InputGroup>
                                    {option.options ? (
                                      <Form.Group as={Row}>
                                        <Select
                                          onChange={(e) => {
                                            let newCellMatching = { ...cellMatching };
                                            newCellMatching[option.id] = { ...newCellMatching[option.id], value: e.value };
                                            //setCellMatching(newCellMatching);
                                          }}
                                          placeholder="Select Account"
                                          formatOptionLabel={formatOptionLabel}
                                          styles={MoondanceLayout.select2customStyles}
                                          options={option.options}
                                          classNamePrefix="select2-selection"
                                        />
                                      </Form.Group>
                                    ) : (
                                      <input
                                        className="form-control form-control-sm"
                                        type={option.type === 'string' ? 'text' : option.type === 'int' ? 'number' : option.type === 'float' ? 'number' : 'date'}
                                        value={cellMatching[option.id]?.value ?? option.default ?? ''}
                                        onChange={(e) => {
                                          let newCellMatching = { ...cellMatching };
                                          newCellMatching[option.id] = { ...newCellMatching[option.id], value: e.target.value };
                                          //setCellMatching(newCellMatching);
                                        }}
                                      />
                                    )}
                                  </InputGroup>
                                </td>
                                <td>
                                  {option.required && !option.default && (!cellMatching[option.id] || !cellMatching[option.id].value) && (
                                    <span className="badge bg-danger" style={{ fontSize: '0.8em' }}>Required</span>
                                  )}
                                </td>
                              </tr>
                              : '')
                          ))}

                        </tbody>
                      </Table>
                      {(isMissingData('position') ? <p className="badge bg-danger" style={{ fontSize: '1em' }}>Some required data are missing. Please complete all necessary fields before importing the data. </p> : <p ><span className="mdi mdi-thumb-up"></span> All required data are present !</p>)}
                      {(positionAlreadyExistInAccount ? <p> There is  already a position with the same name ({(cellMatching && cellMatching['position_name'] && cellMatching['position_name'].value ? cellMatching['position_name'].value : '')} ) in this account !!!</p> :
                        <p><span className="mdi mdi-thumb-up"></span> There is no position with the same name in this account</p>)}
                      {(!isMissingData('position') ? <button className="btn btn-primary" onClick={() => { savePosition() }}>Import this position</button> : '')}
                    </CardBody>
                  </Card>
                )}

              </TabPane>
            )}
            {selectedSheet && (
              <TabPane tabId="transaction" id="transaction">

                {selectedSheet && (
                  <Card>
                    <CardBody>
                      <p className="mt-0 card-title">Import Transaction</p>



                      <Table className="MoondanceTable table-centered">


                        <thead>
                          {importTypeRequestData.map((option, index) => (
                            (option.table === 'transaction' ?
                              <th key={index} >{option.label}</th> : '')
                          ))}

                        </thead>

                        <tbody>
                          {importTypeRequestData.map((option, index) => (
                            (option.table === 'transaction' ?
                              <th  > <input
                                className="form-control form-control-sm"
                                type="text"
                                value={cellMatching[option.id]?.cell || ''}
                                style={{ maxWidth: '8em' }}
                                onChange={(e) => handleCellReferenceChange(option.id, e.target.value)}
                              /></th> : '')
                          ))}

                          {importTypeRequestData.map((option, index) => (
                            (option.table === 'transaction' ?
                              <tr key={index}>
                                <td>{option.label}</td>
                                <td>
                                  <input
                                    className="form-control form-control-sm"
                                    type="text"
                                    value={cellMatching[option.id]?.cell || ''}
                                    style={{ maxWidth: '5em' }}
                                    onChange={(e) => handleCellReferenceChange(option.id, e.target.value)}
                                  />
                                </td>
                                <td>
                                  <InputGroup>
                                    {option.options ? (
                                      <Form.Group as={Row}>
                                        <Select
                                          onChange={(e) => {
                                            let newCellMatching = { ...cellMatching };
                                            newCellMatching[option.id] = { ...newCellMatching[option.id], value: e.value };
                                            //setCellMatching(newCellMatching);
                                          }}
                                          placeholder="Select Account"
                                          formatOptionLabel={formatOptionLabel}
                                          styles={MoondanceLayout.select2customStyles}
                                          options={option.options}
                                          classNamePrefix="select2-selection"
                                        />
                                      </Form.Group>
                                    ) : (
                                      <input
                                        className="form-control form-control-sm"
                                        type={option.type === 'string' ? 'text' : option.type === 'int' ? 'number' : option.type === 'float' ? 'number' : 'date'}
                                        value={cellMatching[option.id]?.value ?? option.default ?? ''}
                                        onChange={(e) => {
                                          let newCellMatching = { ...cellMatching };
                                          newCellMatching[option.id] = { ...newCellMatching[option.id], value: e.target.value };
                                          //setCellMatching(newCellMatching);
                                        }}
                                      />
                                    )}
                                  </InputGroup>
                                </td>
                                <td>
                                  {option.required && !option.default && (!cellMatching[option.id] || !cellMatching[option.id].value) && (
                                    <span className="badge bg-danger" style={{ fontSize: '0.8em' }}>Required</span>
                                  )}
                                </td>
                              </tr>
                              : '')
                          ))}

                        </tbody>
                      </Table>
                      {(isMissingData('position') ? <p className="badge bg-danger" style={{ fontSize: '1em' }}>Some required data are missing. Please complete all necessary fields before importing the data. </p> : <p ><span className="mdi mdi-thumb-up"></span> All required data are present !</p>)}
                      {(positionAlreadyExistInAccount ? <p> There is  already a position with the same name ({(cellMatching && cellMatching['position_name'] && cellMatching['position_name'].value ? cellMatching['position_name'].value : '')} ) in this account !!!</p> :
                        <p><span className="mdi mdi-thumb-up"></span> There is no existing position with the same name in this account</p>)}
                      {(!isMissingData('position') ? <button className="btn btn-primary" onClick={() => { savePosition() }}>Import this position</button> : '')}
                    </CardBody>
                  </Card>
                )}

              </TabPane>
            )}
            <TabPane tabId="template" id="template">
              {cellMatching && Object.keys(cellMatching).length > 0 && (
                <div>

                  <Form.Label>Template name</Form.Label>
                  <Form.Group as={Row}>

                    <input
                      style={{ marginLeft: '12px', marginBottom: '1em' }}
                      type="text"
                      className="form-control"
                      value={importTemplateName}
                      onChange={(e) => setImportTemplateName(e.target.value)}
                    />

                  </Form.Group>

                  <Form.Label>Linked to Account</Form.Label>
                  <Form.Group as={Row}>
                    <Select
                      onChange={(e) => setImportTemplateAccount(e.value)}
                      placeholder="Select Account"
                      formatOptionLabel={formatOptionLabel}
                      styles={MoondanceLayout.select2customStyles}
                      options={accountList}
                      classNamePrefix="select2-selection"
                    />

                  </Form.Group>
                  <div style={{ marginTop: '2.1em' }}>
                    {importTemplateId && (
                      <button className="btn btn-outline-primary" onClick={() => saveTemplate()}>Save</button>
                    )}
                    &nbsp;
                    <button className="btn btn-primary" onClick={() => saveTemplate(true)}>Save as New</button>
                  </div>

                </div>

              )}
            </TabPane>

          </TabContent>

        </Container>
      </div>
    </React.Fragment>
  );
}

export default ExcelReader;