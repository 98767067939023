import React, { useState, useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import UseMoondanceApi from "../../../moondance/helpers/api";
import withRouter from "../withRouter";
import { MoondanceContext } from '../../../App';

const ProfileMenu = props => {

  // Declare a new state variable, which we'll call "menu"

  const moondanceApi = UseMoondanceApi();
  const [menu, setMenu] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [username, setusername] = useState("User");
  const [showAdminMenu, setShowAdminMenu] = useState(false);

  const { darkMode, toggleDarkMode } = useContext(MoondanceContext);
  useEffect(() => {
    const fetchUserProfile = async () => {
      const profile = await moondanceApi.getLoggedinUser();

      if (
        profile
        && profile.data
        && profile.data.first_name
        && profile.data.last_name) {
        setusername(profile.data.first_name + ' ' + profile.data.last_name);
      }
      if (
        profile
        && profile.data
        && profile.data.access_level
        && profile.data.access_level === 'admin') {
        setShowAdminMenu(true);
      }
    };
    fetchUserProfile();
  }, []);



  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="bx bxs-user" />
          <span className="d-none d-xl-inline-block ms-2 me-2">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="/userprofile">
            {" "}
            <i className="ri-user-line align-middle me-2" />
            {props.t("Profile")}{" "}
          </DropdownItem>

          <DropdownItem tag="a" href="accounts">
            <i className="mdi mdi-account-group align-middle me-2" />
            {props.t("Accounts")}
          </DropdownItem>

          <DropdownItem tag="a" onClick={() => {
            toggleDarkMode(!darkMode);
          }}>
            <i className="mdi mdi-theme-light-dark align-middle me-2" />
            {props.t("Toggle Dark Mode")}
          </DropdownItem>
          {(showAdminMenu && (
            <>            
            <div className="dropdown-divider" />
            <DropdownItem tag="a" href="/admin">
              <i className="mdi mdi-security align-middle me-2" />Admin Functions
            </DropdownItem>
            </>

          ))}

          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
