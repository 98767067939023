import React, { useState, useEffect, useContext, useRef } from "react";
import Alert from "./Alert";
import { Link } from "react-router-dom";
import { MoondanceContext } from '../../App';

const MemoizedAlert = React.memo(Alert);

const AlertContainer = (props) => {
    const { alertContent } = useContext(MoondanceContext);
    const [currentAlerts, setCurrentAlerts] = useState([]);
    const alertContainerRef = useRef(null);
    const icon = {
        success: <i className="mdi mdi-check-all"></i>,
        danger: <i className="mdi mdi-block-helper"></i>,
        info: <i className="mdi mdi-alert-circle"></i>,
        warning: <i className="mdi mdi-alert"></i>,
    };
    useEffect(() => {
        // Assign a unique key to each alert when updating currentAlerts
        const updatedAlerts = alertContent.map((alert, index) => ({
            ...alert,
            id: alert.id || index + 1,
        }));
  
  
        setCurrentAlerts(updatedAlerts.reverse());
    }, [alertContent]);

    const removeAlert = (idToRemove) => {
        // Filter out the alert with the given key
        const updatedAlerts = currentAlerts.filter((alert) => alert.id !== idToRemove);
        setCurrentAlerts(updatedAlerts);
    };

    return (
        <div>
            <div className="alert-container" id="alert-container"   >
                {(currentAlerts ? currentAlerts.map((alert) => (
                    <Link to="" className="text-reset notification-item" key={alert.id}>
                        <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                                <div className="avatar-xs">
                                    <span className={`avatar-title bg-${alert.status} rounded-circle font-size-16`}>
                                        {icon[alert.status]}
                                    </span>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <h6 className="mb-1">{(alert.title ? alert.title : 'Notification')}</h6>
                                <div className="font-size-12 text-muted">
                                    <p className="mb-1">{alert.message}</p>
                                    {(alert.timeStamp ? <p className="mb-0"><i className="mdi mdi-clock-outline"></i>{alert.timeStamp}</p> : '')}
                                </div>
                            </div>
                        </div>
                    </Link>
                )) : '')}
            </div>
        </div>
    );
}

export { AlertContainer };
