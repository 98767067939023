import React, { useState, useContext, useEffect, useRef } from "react";
import { Button, Modal, Card, CardBody, Col, Container, FormGroup, Input, InputGroup, Label, Row } from "reactstrap";

import Form from 'react-bootstrap/Form';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { MoondanceContext } from '../../App';
import UseMoondanceApi from "../helpers/api";
import MoondanceLayout from "../helpers/layout";
import { groupAndSortPositions, customSelectPattern, unixTimestampToDate, testurl, formatOptionLabel, formatGroupLabel, formatDate, buildCurrencyList, buildSelectpositionList, buildAccountParameter } from "../helpers/formatData";
import { use } from "i18next";
import { set } from "lodash";
//{ ,MoondanceApi.postUpdateNav,postUpdateExistingNav, fetchFundsList, fetchFundDetails, fetchFundsListForUser }

function UpdateNav(props) {

    const { selectedAccountList } = useContext(MoondanceContext);
    const moondanceApi = UseMoondanceApi();
    const { addAlert } = useContext(MoondanceContext);
    const { isOpen, closeModal } = useContext(MoondanceContext);
    //const [fundDefaultValue, setFundDefaultValue] = useState((props.data ? { id: props.data.fund_id, label: props.data.fund_name } : null));
    //const { selectedStatus = '', setSelectedStatus } = useContext(MoondanceContext);
    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const { darkMode } = useContext(MoondanceContext);
    const { accountList } = useContext(MoondanceContext);

    const flatpickrRef = useRef(null);
    const [submitResult, setSubmitResult] = useState(false);
    const [positionList, setPositionList] = useState(null);
    const [navId, setNavId] = useState(null);
    const [currencyList, setCurrencyList] = useState([{ id: 'EUR', label: 'EUR' }]);
    const [shares, setShares] = useState(1);
    const [calculatedShares, setCalculatedShares] = useState(0);
    const [calculatedSharesDate, setCalculatedSharesDate] = useState(0);
    const [amount, setAmount] = useState(0)
    const [amountPerShare, setAmountPerShare] = useState(0);
    const [selected_position, set_selected_position] = useState((props && props.data && props.data.position_id ? props.data.position_id : null));
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCurrency, setSelectedCurrency] = useState('EUR');
    const [errors, setErrors] = useState({});
    const [deleteStep, setDeleteStep] = useState(0);

    const [transaction_history, set_transaction_history] = useState([]);
    const [matching_position_update, set_matching_position_update] = useState([]);
    const [all_positions_list, set_all_positions_list] = useState([]);
    const [selected_position_details, set_selected_position_details] = useState(null);
    const [matching_position_list, set_matching_position_list] = useState([]);
    const [resetNav, set_resetNav] = useState(false);

    function handleResetNavChange(e) {
        setSubmitResult(false)
        set_resetNav(e.target.checked);
    }
    function handleDelete() {
        if (deleteStep == 0) {
            setDeleteStep(1)
        } else if (deleteStep == 1) {

            setDeleteStep(2)
            const requestData = {}
            requestData.position_id = parseInt(selected_position)

            requestData.nav_id = parseInt(navId)

            moondanceApi.deleteNav(requestData).then(response => {
                if (response.success) {
                    setErrors({})
                    setSubmitResult(response)
                    addAlert({
                        status: 'success',
                        title: 'Update NAV',
                        message: 'Net Asset Value has been deleted  !'
                    })
                } else {
                    setErrors({})
                    setSubmitResult(response)
                    addAlert({
                        status: 'danger',
                        title: 'Failed to Delete Net Asset Value for Position:' + requestData.position_id,
                        message: (response.error ? response.error : 'An error occured')
                    })
                }
            })
                .catch(error => {
                    setErrors({})
                    setSubmitResult(error)
                    console.log(submitResult)
                    addAlert({
                        status: 'danger',
                        title: 'Failed to Delete Net Asset Value for Position:' + requestData.position_id,
                        message: error
                    })

                });
        }

    }
    function handleChangeSimilarPositionCheck(id) {
        const updatedMatchingPositionUpdate = matching_position_update.map(item => {
            if (item.id === id) {
                return { ...item, update: !item.update };
            }
            return item;
        });
        console.log('updatedMatchingPositionUpdate', updatedMatchingPositionUpdate)
        set_matching_position_update(updatedMatchingPositionUpdate);
    }
    function handleCloseModal() {
        set_selected_position(null)
        setSelectedCurrency(userCurrency)
        setSelectedDate(new Date())
        setAmount(null)
        setSubmitResult(false)
        setDeleteStep(0)
        if (props && props.isModal) { props.closeModal(); }
    }
    function handleSelectDate(selectedDates, dateStr, instance) {
        setSubmitResult(false)
        setSelectedDate(dateStr);
        if(selected_position)
        get_transaction_history(selected_position)
        console.log('selectedDates', selectedDates)
    }    
    function get_total_shares_on_date(date) {
        var total_shares = 0
        var timestamp =0
        var lastDate=new Date(date)
        

        if (!MoondanceLayout.isDate(date)) {
            timestamp = MoondanceLayout.dateStringToTimestamp(date)
            
        } else {
            timestamp = Math.floor(new Date(date).getTime() / 1000)
        }
        transaction_history.map((item, index) => {
            if (item.timestamp <= timestamp+86400) {
                total_shares = item.cumulative_shares
                lastDate=new Date(item.timestamp)
            }
        })
        if(props && props.data && props.data.number_of_shares){
            setShares(props.data.number_of_shares)
        } else {
            setShares(total_shares)
        }
        setCalculatedShares(total_shares)
        setCalculatedSharesDate(lastDate)
        //setAmountPerShare(amount/total_shares)

    }
    async function get_transaction_history(position_id) {
        console.log('get_transaction_history',position_id)
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }
        try {
            moondanceApi.fetchPositionTransactions(api_parameters, position_id).then(response => {
                console.log('get_transaction_history',response)
                if (response.data) {
                    set_transaction_history(response.data)
                } else {
                    set_transaction_history([])
                }
            })
        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {
        if(selectedDate && transaction_history !== undefined && transaction_history.length > 0){
            let sharesOnDate = get_total_shares_on_date(selectedDate)          
        } else {
            setCalculatedShares(0)
            setCalculatedSharesDate(null)
            setShares(0)
        }   
    }, [transaction_history]);
    useEffect(() => {
        if(selected_position !== null && selected_position !== undefined){
            let transactionHistory  =   get_transaction_history(selected_position)
            let positionDetails = fetchSelected_positionDetails(selected_position) 
        } else {
            set_transaction_history([])
        }
        setSubmitResult(false)
    }, [selected_position]);

    useEffect(() => {
        console.log('selected_position_details', selected_position_details)
        if (selected_position_details && selected_position_details.asset_currency) {
            setSelectedCurrency(selected_position_details.asset_currency)
            setCurrencyList([{ value: selected_position_details.asset_currency, id: selected_position_details.asset_currency, label: selected_position_details.asset_currency }])
        }
        if (selected_position_details && selected_position_details.position_nav_in_asset_currency) {

            if(props && props.data && props.data.nav_in_asset_currency){
                setAmount(props.data.nav_in_asset_currency)
            } else {
                setAmount(selected_position_details.position_nav_in_asset_currency)
            }
        }
        if (selected_position_details) {
            find_matching_position(selected_position)
        }
    }, [selected_position_details]);

    useEffect(() => { setAmountPerShare (Math.round((amount / shares)*100)/100) }, [shares, amount]);
    useEffect(() => {find_matching_position(selected_position)}, [selected_position, all_positions_list]);

    useEffect(() => {  
        console.log('useEffect: matching_position_list', matching_position_list) 
        if (matching_position_list && matching_position_list.length > 0) {
            let this_matching_position_update = []

            matching_position_list.map((item, index) => {

                let this_amount = amount * (item.position_commited_in_asset_currency / selected_position.position_commitment_in_asset_currency)
                let this_shares = shares * (item.position_commited_in_asset_currency / selected_position.position_commitment_in_asset_currency)
                this_matching_position_update[item.position_id] = {
                    id: item.position_id,
                    amount: this_amount,
                    shares: this_shares,
                    name: item.asset_name,
                    commitment: item.position_commited_in_asset_currency,
                    account_name: item.position_account_name,
                    update: (matching_position_update[index] ? matching_position_update[index] : true)
                }
            })
            console.log('this_matching_position_update', this_matching_position_update)
            set_matching_position_update(this_matching_position_update)
        }

    }, [matching_position_list]);

       

    async function fetchSelected_positionDetails(id) {
        var api_parameters = {
            account: buildAccountParameter(accountList),
            userCurrency: userCurrency,
        }
        try {
            const thisFund = await moondanceApi.fetchPositionDetails(api_parameters, id);
            set_selected_position_details(thisFund.data)

        } catch (error) {
            return false;
        }
    };
    async function fetch_all_position_list() {
        console.log('fetch_all_position_list', accountList)
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }
        if (accountList) {
            try {
                moondanceApi.fetchPositionsList(api_parameters).then(response => {
                    if (response.data.length > 0) {
                        set_all_positions_list(response.data)
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }

    }
    async function find_matching_position(this_position_id) {

      let this_matching_position_list = MoondanceLayout.findMatchingPositions(all_positions_list, this_position_id)

      console.log('find_matching_position', this_position_id,all_positions_list,this_matching_position_list)
      set_matching_position_list(this_matching_position_list)
    }
   

   
    function handleSelectCurrency(c) {
        setSubmitResult(false)
        setSelectedCurrency(c.id);
    }
    function handleAmountPerShareChange(e) {
        setSubmitResult(false)
        setAmount(e.target.value * shares)
    }
    function handleAmountChange(e) {
        setSubmitResult(false)
        setAmount(e.target.value);
    }
    function handleSaveForm() {
        const newErrors = {};
        const requestData = {}
        var isError = false
        requestData.shares = shares

        if (!selected_position) {
            newErrors.fund = 'Please select a position'
            isError = true
        } else {
            requestData.position_id = parseInt(selected_position)
        }
        if (!selectedDate) {
            requestData.date = formatDate(new Date(), 'yyyy-mm-dd')
            isError = true
        } else {
            var regex = /^\d{2}\/\d{2}\/\d{4}$/;
            if (!regex.test(selectedDate)) {
                requestData.date = formatDate(selectedDate, 'yyyy-mm-dd')

            } else {
                var parts = selectedDate.split('/');
                var newDateStr = `${parts[2]}-${parts[1]}-${parts[0]}`;
                requestData.date = newDateStr
            }
            console.log(requestData)
        }
        if (!selectedCurrency) {
            newErrors.currency = ' Please select a currency'
            isError = true
        } else {
            requestData.currency = selectedCurrency
        }
        if (!amount) {
            newErrors.amount = ' Please specify the amount'
            isError = true
        } else {
            requestData.nav = parseFloat(amount)
        }
        if (resetNav) {
            requestData.reset_nav = true
        }
        if (isError) {
            console.log('isError', newErrors)
            setErrors(newErrors)
        } else {

            console.log('updateNav requestData', requestData)
            if (navId) {
                //update existing nav
                requestData.id = navId
                console.log('updateNav requestData', requestData)
                moondanceApi.updateNav(requestData).then(response => {
                    setErrors({})
                    setSubmitResult(response)

                    setLastUpdate(Math.floor(Date.now() / 1000))
                    if (response.success) {
                        addAlert({
                            status: 'success',
                            title: 'Update NAV',
                            message: 'Net Asset Value has been updated for position ' + requestData.position_id + ' !'
                        })
                    } else {
                        addAlert({
                            status: 'danger',
                            title: 'Failed to update Net Asset Value for Position:' + requestData.position_id,
                            message: (response.error ? response.error : 'An error occured')
                        })
                    }

                    if (props && props.isModal) { handleCloseModal() }
                    //console.log(submitResult)
                })
                    .catch(error => {
                        setErrors({})
                        setSubmitResult(error)
                        console.log(submitResult)
                        addAlert({
                            status: 'danger',
                            title: 'Failed to update Net Asset Value for Position:' + requestData.position_id,
                            message: error
                        })

                        if (props && props.isModal) { handleCloseModal() }
                    });
            } else {

                matching_position_update.map((item, index) => {
                    let this_amount = amount * (item.commitment / selected_position_details.position_commitment_in_asset_currency)
                    let this_shares = shares * (item.commitment / selected_position_details.position_commitment_in_asset_currency)

                    let this_request_update = {
                        position_id: item.id,
                        nav: this_amount,
                        currency: requestData.currency,
                        date: requestData.date,
                        shares: this_shares,
                        reset_nav: requestData.reset_nav
                    }
                    console.log('this_request_update', this_request_update)
                    moondanceApi.postNav(this_request_update).then(response => {
                        setErrors({})
                        if (response.success) {
                            addAlert({
                                status: 'success',
                                title: 'Update NAV',
                                message: 'Net Asset Value has been updated for position ' + this_request_update.position_id + ' !'
                            })
                        } else {
                            addAlert({
                                status: 'danger',
                                title: 'Failed to update Net Asset Value for Position:' + this_request_update.position_id,
                                message: response
                            })
                        }
                    }).catch(error => {
                        addAlert({
                            status: 'danger',
                            title: 'Failed to update Net Asset Value for Position:' + this_request_update.position_id,
                            message: error
                        })
                    });
                })
                console.log('postNav', requestData)
                moondanceApi.postNav(requestData).then(response => {
                    if (response.success) {
                        setErrors({})
                        setSubmitResult(response)
                        setLastUpdate(Math.floor(Date.now() / 1000))
                        addAlert({
                            status: 'success',
                            title: 'Update NAV',
                            message: 'Net Asset Value has been updated for position ' + requestData.position_id + ' !'
                        })
                    } else {

                        console.log('response', response)
                        addAlert({
                            status: 'danger',
                            title: 'Update NAV Failed !',
                            message: response.error
                        })
                        setErrors({})
                        setSubmitResult(response)
                        setLastUpdate(Math.floor(Date.now() / 1000))


                    }
                    //console.log(submitResult)
                })
                    .catch(error => {
                        setErrors({})
                        setSubmitResult(error)
                        console.log(submitResult)
                        addAlert({
                            status: 'danger',
                            title: 'Update NAV Failed !',
                            message: error
                        })

                    });
            }
        }
    }
    useEffect(() => {
        // console.log('props', props)
        async function fetchData() {
            var api_parameters = {
                account: buildAccountParameter(selectedAccountList),
                status: '1,',
                userCurrency: userCurrency,
            }
            setCurrencyList(buildCurrencyList())

            try {
                moondanceApi.fetchPositionsList(api_parameters).then(response => {

                    if (response.data && response.data.length > 0) {
                        let filteredPositionList = response.data.filter(item => item.user_can_add_transaction === 1)
                        let positionList = groupAndSortPositions(filteredPositionList)
                        setPositionList(positionList)

                    }
                    
                    console.log('props', props)
                    if (props && props.data) {
                        if (props.data.id) {
                            setNavId(props.data.id)
                        }
                        if (props.data.timestamp) {
                            setSelectedDate(unixTimestampToDate(props.data.timestamp, false))
                        }
                        if (props.data.currency) {
                            setSelectedCurrency(props.data.currency)
                        }
                        if (props.data.nav_in_asset_currency) {
                            setAmount(props.data.nav_in_asset_currency)
                        }
                        if (props.data.number_of_shares) {
                            setShares(props.number_of_shares)
                        }

                        if (props.data.position_id) {
                            var position_id = props.data.position_id

                        } else if (testurl('/position/', window.location.href)) {
                            var position_id = parseInt(window.location.href.split('/position/')[1].split('/')[0])

                        } else {
                            var position_id = 0
                        }
                        set_selected_position(parseInt(position_id))

                    }
                    /*
                    if (props && props.data && props.data.number_of_shares) {
                        setShares(props.number_of_shares)
                    } else if (thisPosition && thisPosition.position_shares) {
                        setShares(parseFloat(thisPosition.shares))
                    }
                    if (props && props.data && props.data.nav_in_currency) {
                        setAmount(props.data.nav_in_currency)
                    } else if (thisPosition && thisPosition.nav) {
                        setAmount(thisPosition.nav)
                        setAmountPerShare(Math.round(10000 * thisPosition.nav / thisPosition.shares) / 10000)
                    }*/

                })
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
        fetch_all_position_list();
    }, [accountList, props]);


    return (

        <React.Fragment>

            <div className={(darkMode ? 'modal-header darkMode' : 'modal-header')}>
                <h5
                    className="modal-title mt-0"
                    id="myModalLabel"
                >
                    Update Net Asset Value {(navId ? '(Ref:' + navId + ')' : '')}
                </h5>

            </div>
            <div className={(darkMode ? 'modal-body darkMode' : 'modal-body')}>
                <form>
                    <Col lg="12">
                        <label>Date</label>
                        <InputGroup>
                            <Flatpickr

                                className="form-control d-block"
                                onChange={handleSelectDate}
                                value={selectedDate}
                                options={{
                                    clickOpens: false,  // ensure this is true
                                    allowInput: true,   // ensure direct input is allowed
                                    dateFormat: "d/m/Y",

                                }}

                                ref={flatpickrRef}
                            />
                            <div className="input-group-append" onClick={() => flatpickrRef.current.flatpickr.open()}>
                                <span className="input-group-text">
                                    <i className="mdi mdi-calendar"></i>
                                </span>
                            </div>
                        </InputGroup>
                        <br />
                    </Col>
                    <Col lg="12">

                        <label>Select Position </label>


                        <Select
                            id="floatingSelectFund"
                            value={positionList ?
                                positionList.flatMap(group => group.options).find(option => option.value === parseInt(selected_position))
                                : null}
                            onChange={(f) => {   set_selected_position(f.value); }}
                            placeholder="Select Position"
                            options={positionList}
                            formatOptionLabel={formatOptionLabel}
                            formatGroupLabel={formatGroupLabel}
                            styles={customSelectPattern}
                        />   {errors.fund && (<label className="error mt-2 text-danger">{errors.fund}</label>)}

                        {(selected_position_details ? <p>
                            <br/>Selected position :  {selected_position_details.asset_name} in account : {selected_position_details.account_name}<br />
                            This position has a commitment of  {MoondanceLayout.formatCurrency(selected_position_details.position_commitment_in_asset_currency, selected_position_details.asset_currency)}<br />

                            {(selected_position_details.position_nav_in_asset_currency>0 ?
                            <span>Last Net Asset Value : {MoondanceLayout.formatCurrency(selected_position_details.position_nav_in_asset_currency, selected_position_details.position_nav_currency)} ({selected_position_details.position_nav_date})<br/></span>
                            :'No previous NAV has been recorded for this position.')}
                            {(calculatedShares>0 ?
                            <p><br/>Number of shares owned at this date, based on transaction recorded up to this date: <label>{MoondanceLayout.formatNumber(calculatedShares)}</label></p>
                            : "")}
                        </p>:'')}
                      
                        <br />

                    </Col>
                    


                    <Col lg="12">
                        <label>Net Asset Value (per shares)</label>
                        <input className="form-control" type="number" value={amountPerShare} onChange={(e) => { handleAmountPerShareChange(e); }} />
                        {errors.amount && (<label className="error mt-2 text-danger">{errors.amountPerShare}</label>)}

                        <br />
                    </Col>
                    <Col lg="12">
                        <label>Net Asset Value (for total number of shares)</label>
                        <input className="form-control" type="number" value={amount} onChange={(e) => { handleAmountChange(e); }} />
                        {errors.amount && (<label className="error mt-2 text-danger">{errors.amount}</label>)}

                        <br />
                    </Col>
                    <Col lg="12">
                        <label id="add_transaction_currency-label" >Currency ({selectedCurrency})</label>
                        <Select
                            id="add_transaction_currency_id"
                            onChange={(c) => {
                                handleSelectCurrency(c);
                            }}

                            value={currencyList.find(option => option.value === selectedCurrency)}
                            defaultValue={{ label: "EUR - Euro", value: 'EUR' }}
                            options={currencyList}
                            formatOptionLabel={formatOptionLabel}
                            styles={customSelectPattern}
                        />
                        {errors.currency && (<label className="error mt-2 text-danger">{errors.currency}</label>)}

                        <br />
                    </Col>

                    <Col lg="12">
                        <br />
                        <input
                            type="checkbox"

                            onChange={handleResetNavChange}

                        />&nbsp;<label>Check this to recalculate all subsequent Net Asset Values (NAVs) based on transactions recorded after this date.</label>
                        <p>Any NAVs recorded after this date will be deleted and recalculated based on this new NAV and recorded transactions. </p>
                    </Col>
                    <Col lg="12">
                        {errors.date && (<label className="error mt-2 text-danger">{errors.date}</label>)}
                        {submitResult && !submitResult.success && (<div className="alert alert-danger text-center"><b>An error occured, the nav was NOT updated !!!</b></div>)}
                        {submitResult && submitResult.success && deleteStep !== 2 && (<div className="alert alert-success text-center"><b>The net asset value was updated, you can now close this window or update another nav</b></div>)}
                        {submitResult && submitResult.success && deleteStep == 2 && (<div className="alert alert-success text-center"><b>The net asset value was deleted, you can now close this window</b></div>)}

                    </Col>

                    {(!navId && matching_position_update && matching_position_update.length > 0 ?
                        < Form.Group as={Row} className="mb-3" >
                            <Col lg="12">
                                <h5>Similar Positions in Other Accounts</h5>
                                <p>There are similar positions available in different accounts to which you have access.
                                    You can add this transaction to those positions by selecting the corresponding checkboxes.
                                    The transaction amount will be recalculated based on the commitment of each position.</p>
                            </Col>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Update</th>
                                        <th>Account</th>
                                        <th>Shares </th>
                                        <th>Nav</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(selected_position_details ? 
                                        matching_position_update.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>         <input
                                                onChange={() => (handleChangeSimilarPositionCheck(item.id))}
                                                type="checkbox"
                                                checked={item.update !== false}
                                            >
                                            </input></td>
                                            <td>{item.id} - {item.account_name}<br />Commitment :{MoondanceLayout.formatCurrency(item.commitment, selectedCurrency)}</td>
                                            <td>{MoondanceLayout.formatNumber(shares * (item.commitment / selected_position_details.position_commitment_in_asset_currency), 4)}</td>

                                            <td> {MoondanceLayout.formatCurrency(amount * (item.commitment / selected_position_details.position_commitment_in_asset_currency), selectedCurrency)}</td>


                                        </tr>

                                        )): '')}
                                </tbody>
                            </table>
                        </Form.Group>
                        : ''

                    )}
                </form>
            </div>
            <div className={(darkMode ? 'modal-footer darkMode' : 'modal-footer')}>
                {(deleteStep == 0 && navId) && (
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="btn btn-danger "
                        data-dismiss="modal"
                    >
                        Delete
                    </button>)}
                {(deleteStep == 1 && navId) && (
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="btn btn-danger "
                        data-dismiss="modal"
                    >
                        Confirm Delete !
                    </button>)}


                {(!submitResult || !submitResult.success) && (<button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => { handleSaveForm() }}
                >
                    Save changes
                </button>)}
            </div>
        </React.Fragment >
    )
}

export default UpdateNav


/*<Col lg="12">
                        <label>Number of shares</label>
                        <input className="form-control" type="number" value={shares} onChange={(e) => { setShares(e.target.value); }} />
                        {errors.amount && (<label className="error mt-2 text-danger">{errors.shares}</label>)}
                        {((calculatedSharesDate && shares && calculatedSharesDate && calculatedShares != shares) ? <label className="error mt-2 text-danger">The number of shares ({shares}) does not match the calculated number of shares ({calculatedShares}) owned at this date based on recorded transactions <br/><button className="btn btn-sm btn-danger" onClick={() =>{setShares(calculatedShares)}}>Set number of shares to {calculatedShares}</button></label> : '')}
                         <br />
                    </Col>*/