
import React from "react";
import {Modal} from "reactstrap";

import AddTransactionShortForm from './addTransactionShortForm.js';



function TransactionForm(props) {
    const { isOpen, closeModal } = props;  
   
    function handleCloseModal() {
      
        closeModal();
    }





    return (

        <React.Fragment>

            <Modal isOpen={isOpen} >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myModalLabel"
                    >
                        Add Transaction
                    </h5>
                    <button
                        type="button"
                        onClick={handleCloseModal}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                <AddTransactionShortForm
                    show={true}
                    showOpenButton={false}
                    isModal ={true}
                    data={(props && props.data ? props.data : null)}
                    closeModal={handleCloseModal}
                  />
                </div>
                <div className="modal-footer">

                    <button
                        type="button"
                        onClick={handleCloseModal}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                   
                </div>
            </Modal>


        </React.Fragment >




    )

};

export { TransactionForm }