import React, { useState, useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import UseMoondanceApi from "../moondance/helpers/api";

const AuthProtected = (props) => {

  const moondanceApi = UseMoondanceApi();
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await moondanceApi.getLoggedinUser();
        setUserProfile(profile);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchUserProfile();
  }, []);
  /*
    redirect is un-auth access protected routes via url
    */
 
    
  if (loading) {
    //console.log('Loading ...')
    return null; // Render a loading state, or any other appropriate UI
  }
  if (!userProfile ) {
    console.log('Redirecting')
   return (
      <Navigate to={{ pathname: "/home", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} />  </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
