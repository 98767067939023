
import React, { useState, useContext, useEffect, useRef } from "react";
import Form from 'react-bootstrap/Form';
import { Card, CardBody, Row, Col, Input, InputGroup, Button, CardTitle } from "reactstrap";

import { Link } from 'react-router-dom';
import Select from "react-select";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { MoondanceContext } from '../../App.js';
import MoondanceLayout from "../helpers/layout.js";
import UseMoondanceApi from "../helpers/api.js";
import { buildAccountParameter, formatOptionLabel, buildCurrencyList, } from "../helpers/formatData.js";
import ProgressBar from 'react-bootstrap/ProgressBar';

import { useNavigate } from 'react-router-dom';



const EditPositionForm = (props) => {
    const navigate = useNavigate();
    const moondanceApi = UseMoondanceApi();
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const [assetList, setAssetList] = useState([]);

    // used to find marching positions
    const [matching_position_list, set_matching_position_list] = useState([]);
    const [matching_position_update, set_matching_position_update] = useState([]);
    const [all_positions_list, set_all_positions_list] = useState([]);
    const [selected_position_details, set_selected_position_details] = useState(null);

    //used for date selector
    const flatpickrRef = useRef(null);
    const flatpickrRef2 = useRef(null);

    //Used for notification
    const { addAlert } = useContext(MoondanceContext);
    const [createNewAsset, setCreateNewAsset] = useState(false)

    // 
    const customSelectPattern = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#0bb197' : 'white',
            color: state.isFocused ? 'white' : 'black',
        }),

    };

    /* CHECK IF NEEDED */
    /*
    const [currencyDefaultValue, setCurrencyDefaultValue] = useState([{ id: 'EUR', value: 'EUR', label: 'EUR' }]);
    const [positionList, setPositionList] = useState(null);
    const [selectedDate, setSelectedDate] = useState((props.data && props.data.date_int ? props.data.date_int : null));
    const [commitmentAmountInAssetCurrency, setCommitmentAmountInAssetCurrency] = useState(0)
    const [shares, setShares] = useState(1)
    */

    /* Generic lists ***********************************************************/
    const [currencyList, setCurrencyList] = useState([{ id: 'EUR', value: 'EUR', label: 'EUR' }]);
    const [selectedCurrency, setSelectedCurrency] = useState(userCurrency);

    const [editThisAsset, setEditThisAsset] = useState(true)
    const [positionLinkedToOtherAccountAsset, setPositionLinkedToOtherAccountAsset] = useState(false)
    const [assetSelectList, setAssetSelectList] = useState([]);

    const { accountList } = useContext(MoondanceContext);
    const [editableAccountList, setEditableAccountList] = useState([])
    const [edit_mode, set_edit_mode] = useState('edit')


    const [asset_is_linked, set_asset_is_linked] = useState(false);
    const [asset_is_saved, set_asset_is_saved] = useState(false);
    const [show_edit_asset, set_show_edit_asset] = useState(true);
    const [asset_is_duplicated, set_asset_is_duplicated] = useState(false);

    /*POSITION ***********************************************************/
    const [position_id, set_position_id] = useState(0)
    const [position_commitment, set_position_commitment] = useState(0)
    const [position_commitment_error, set_position_commitment_error] = useState('')
    const [position_shares, set_position_shares] = useState(0)
    const [position_subscription_date, set_position_subscription_date] = useState(new Date());
    const [position_account_id, set_position_account_id] = useState(0)
    const [position_account_error, set_position_account_error] = useState('')
    const [position_asset_id, set_position_asset_id] = useState('new')

    const [position_status, set_position_status] = useState(1)
    const [position_status_select, set_position_status_select] = useState([
        { id: 1, value: 1, label: 'Active' },
        { id: 2, value: 2, label: 'Upcoming' },
        { id: 4, value: 4, label: 'Closed' },
        { id: 100, value: 100, label: 'Deleted' }
    ])

    /*ASSET ***********************************************************/
    const initialAssetState = {
        asset_id: 0,
        asset_currency: userCurrency,
        asset_name: '',
        asset_emitter: '',
        asset_type: 'others',
        asset_feeder_name: '',
        asset_feeder_type: '',
        asset_intermediate: '',
        asset_description: '',
        asset_inception_date: new Date(),
        asset_vintage_year: MoondanceLayout.currentYear(),
        asset_investment_period: 5,
        asset_detention_period: 5,
        asset_expected_drawdown: 100,
        asset_expected_irr: 9.86,
        asset_expected_tvpi: 1.60,
        asset_geographies: [{ id: 'Global', percentage: 100 }],
        asset_sectors: [{ id: 'diversified', percentage: 100 }],
        asset_model_yearly_capitalCall: [-20, -20, -20, -20, -20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        asset_model_yearly_capitalcall_min: [-100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100],
        asset_model_yearly_capitalcall_max: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        asset_real_yearly_capitalCall: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        asset_sum_model_yearly_capitalcall: 0,
        asset_sum_real_yearly_capitalcall: 0,
        asset_sum_model_yearly_distribution: 0,
        asset_sum_real_yearly_distribution: 0,
        asset_model_yearly_distribution: [0, 0, 0, 0, 0, 32.2, 32.2, 32.2, 32.2, 32.2, 0, 0, 0, 0, 0, 0],
        asset_real_yearly_distribution: Array(15).fill(0),
        asset_model_yearly_distribution_min: Array(15).fill(0),
    };


    const [asset_id, set_asset_id] = useState(0);
    const [asset_status, set_asset_status] = useState(1);
    const [asset_name, set_asset_name] = useState('');
    const [asset_name_error, set_asset_name_error] = useState('');
    const [asset_emitter, set_asset_emitter] = useState('');
    const [asset_account_id, set_asset_account_id] = useState(0);
    const [asset_type, set_asset_type] = useState("others");
    const [asset_description, set_asset_description] = useState('');
    const [asset_currency, set_asset_currency] = useState(userCurrency);
    const [asset_inception_date, set_asset_inception_date] = useState(new Date());
    const [asset_investment_period, set_asset_investment_period] = useState(5);
    const [asset_detention_period, set_asset_detention_period] = useState(5);
    const [asset_expected_drawdown, set_asset_expected_drawdown] = useState(100);
    const [asset_expected_irr, set_asset_expected_irr] = useState(9.86);
    const [asset_expected_tvpi, set_asset_expected_tvpi] = useState(1.60);
    const [asset_intermediate, set_asset_intermediate] = useState(0);
    const [asset_feeder_name, set_asset_feeder_name] = useState("");
    const [asset_feeder_type, set_asset_feeder_type] = useState("");
    const [asset_vintage_year, set_asset_vintage_year] = useState(MoondanceLayout.currentYear);
    const [asset_geographies, set_asset_geographies] = useState([{ id: 'Global', percentage: '100' }]);
    const [asset_sectors, set_asset_sectors] = useState([{ id: 'diversified', percentage: '100' }]);
    /*Model *********************************************************/
    const [asset_model_yearly_capitalcall, set_asset_model_yearly_capitalCall] = useState([-20, -20, -20, -20, -20, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]);
    const [asset_model_yearly_capitalcall_min, set_asset_model_yearly_capitalcall_min] = useState([-100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100, -100,]);
    const [asset_model_yearly_capitalcall_max, set_asset_model_yearly_capitalcall_max] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]);
    const [asset_real_yearly_capitalcall, set_asset_real_yearly_capitalCall] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,]);
    const [asset_sum_model_yearly_capitalcall, set_asset_sum_model_yearly_capitalcall] = useState(0)
    const [asset_sum_real_yearly_capitalcall, set_asset_sum_real_yearly_capitalcall] = useState(0)
    const [asset_sum_model_yearly_distribution, set_asset_sum_model_yearly_distribution] = useState(0)
    const [asset_sum_real_yearly_distribution, set_asset_sum_real_yearly_distribution] = useState(0)
    const [asset_model_yearly_distribution, set_asset_model_yearly_distribution] = useState([0, 0, 0, 0, 0, 32.2, 32.2, 32.2, 32.2, 32.2, 0, 0, 0, 0, 0, 0])
    const [asset_real_yearly_distribution, set_asset_real_yearly_distribution] = useState(Array(15).fill(0));
    const [asset_model_yearly_distribution_min, set_asset_model_yearly_distribution_min] = useState(Array(15).fill(0));

    const [assetTypeList, setAssetTypeList] = useState(MoondanceLayout.buildAssetTypeSelectOptions(MoondanceLayout.assetType));
    const [item, setItem] = useState([]);
    const [percentageOption] = useState(() => {
        const options = [];
        for (let i = 0; i <= 100; i += 5) {
            options.push({
                id: i,
                label: `${i}%`
            });
        }
        return options;
    });
    /* Geographies *********************************************************/
    const total_geography_percentage = (asset_geographies ? asset_geographies.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0);
    const handle_geography_change = (index, value) => {
        const newFormValues = [...asset_geographies];
        newFormValues[index].id = value;
        set_asset_geographies(newFormValues);

    }
    const handle_geography_add_row = () => {
        set_asset_geographies(prev => [...prev, { id: null, percentage: null }]);
    };
    const handle_geography_remove_row = (indexToRemove) => {
        set_asset_geographies(prev => prev.filter((_, index) => index !== indexToRemove));
    };
    const handle_geography_percentage_change = (index, value) => {
        const newFormValues = [...asset_geographies];
        newFormValues[index].percentage = value;

        //console.log('set_asset_geographies', newFormValues)
        set_asset_geographies(newFormValues);
    };
    const get_available_geography_percentage_options = (currentIndex) => {
        const currentRowPercentage = asset_geographies[currentIndex]?.percentage || 0;
        const otherRowsPercentage = asset_geographies.reduce((acc, curr, index) => {
            if (index !== currentIndex) {
                return acc + (curr.percentage || 0);
            }
            return acc;
        }, 0);

        return percentageOption.filter(option => option.id + otherRowsPercentage <= 100 || option.id === currentRowPercentage);
    };
    /***************************************************** Geographies END*/

    /* sectors *********************************************************/
    const total_sector_percentage = (asset_sectors ? asset_sectors.reduce((acc, curr) => acc + (curr.percentage || 0), 0) : 0);
    const handle_sector_change = (index, value) => {
        const newFormValues = [...asset_sectors];
        newFormValues[index].id = value;
        set_asset_sectors(newFormValues);

    }
    const handle_sector_add_row = () => {
        if (asset_sectors && asset_sectors.length > 0) {
            set_asset_sectors(prev => [...prev, { id: null, percentage: null }]);
        } else {
            set_asset_sectors([{ id: null, percentage: 100 }]);
        }
    };
    const handle_sector_remove_row = (indexToRemove) => {
        set_asset_sectors(prev => prev.filter((_, index) => index !== indexToRemove));
    };
    const handle_sector_percentage_change = (index, value) => {
        const newFormValues = [...asset_sectors];
        newFormValues[index].percentage = value;

        //console.log('set_asset_sectors', newFormValues)
        set_asset_sectors(newFormValues);
    };
    const get_available_sector_percentage_options = (currentIndex) => {
        const currentRowPercentage = asset_sectors[currentIndex]?.percentage || 0;
        const otherRowsPercentage = asset_sectors.reduce((acc, curr, index) => {
            if (index !== currentIndex) {
                return acc + (curr.percentage || 0);
            }
            return acc;
        }, 0);

        return percentageOption.filter(option => option.id + otherRowsPercentage <= 100 || option.id === currentRowPercentage);
    };
    /***************************************************** Geographies END*/


    /************************************************************************************************************************
     *  obtain the position details
    *************************************************************************************************************************/
    useEffect(() => {
        
        fetchCurrencyList().then(() => {
            if (props.editMode) {
                set_edit_mode(props.editMode)
                if (props.editMode === 'new') {
                    set_asset_id('new')
                    set_show_edit_asset(true)
                }
            }
            if (props && props.data && props.data.summary && props.data.summary.position_id !== position_id) {

                set_position_id(props.data.summary.position_id)

                set_position_commitment(props.data.summary.position_commitment_in_asset_currency)
                set_position_shares(props.data.summary.position_shares)
                set_position_subscription_date(props.data.summary.position_subscription_date_formatted)
                set_position_account_id(props.data.summary.position_account_id)
                set_position_asset_id(props.data.summary.position_asset_id)
                set_position_status(props.data.summary.position_status)


                set_asset_id(props.data.summary.asset_id)
                set_asset_currency(props.data.summary.asset_currency)
                set_asset_name(props.data.summary.asset_name)
                set_asset_emitter(props.data.summary.asset_emitter)
                set_asset_type(props.data.summary.asset_type)
                set_asset_feeder_name(props.data.summary.asset_feeder_name)
                set_asset_feeder_type(props.data.summary.asset_feeder_type)
                set_asset_intermediate(props.data.summary.asset_intermediate)
                set_asset_description(props.data.summary.asset_description)
                set_asset_inception_date(props.data.summary.asset_inception_date)
                set_asset_vintage_year(props.data.summary.asset_vintage_year)
                set_asset_investment_period(props.data.summary.asset_investment_period)
                set_asset_detention_period(props.data.summary.asset_detention_period)
                set_asset_expected_drawdown(props.data.summary.asset_expected_drawdown * 100)
                set_asset_expected_irr(props.data.summary.asset_expected_irr * 100)
                set_asset_expected_tvpi(props.data.summary.asset_expected_tvpi)
                set_asset_geographies((props.data.geographies ? props.data.geographies : []))
                set_asset_sectors(props.data.sectors)

                const updated_asset_real_yearly_capitalcall = [...asset_real_yearly_capitalcall];
                const updated_asset_model_yearly_capitalcall = [...asset_model_yearly_capitalcall];
                const updated_asset_model_yearly_capitalcall_min = [...asset_model_yearly_capitalcall_min];

                const updated_asset_real_yearly_distribution = [...asset_real_yearly_distribution];
                const updated_asset_model_yearly_distribution = [...asset_model_yearly_distribution];
                const updated_asset_model_yearly_distribution_min = [...asset_model_yearly_distribution_min];

                props.data.perYear.map((value, index) => {



                    //Is there already a model for this year

                    updated_asset_real_yearly_capitalcall[index] = 0
                    updated_asset_real_yearly_distribution[index] = 0
                    updated_asset_model_yearly_capitalcall[index] = 0
                    updated_asset_model_yearly_distribution[index] = 0

                    //PAST
                    if (value.year < parseInt(MoondanceLayout.currentYear())) {
                        // is there already a REAL distribution
                        if (value.sum_real_distribution_in_asset_currency > 0) {
                            updated_asset_real_yearly_distribution[index] = 100 * value.sum_real_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }
                        // is there already a real capital call for this year
                        if (value.sum_real_capital_call_in_asset_currency < 0) {
                            updated_asset_real_yearly_capitalcall[index] = 100 * value.sum_real_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }
                        updated_asset_model_yearly_distribution[index] = 0;
                        updated_asset_model_yearly_capitalcall[index] = updated_asset_real_yearly_capitalcall[index];

                        //FUTURE
                    } else if (value.year >parseInt(MoondanceLayout.currentYear())) {
                      

                        if (value.sum_model_capital_call_in_asset_currency < 0) {
                            updated_asset_model_yearly_capitalcall[index] = 100 * value.sum_model_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }

                        if (value.sum_model_distribution_in_asset_currency > 0) {
                            updated_asset_model_yearly_distribution[index] = 100 * value.sum_model_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        }
                      //  console.log('future', value,   updated_asset_model_yearly_distribution[index])
                        //current year
                    } else {
                        //console.log('present', value,   updated_asset_model_yearly_distribution[index])
                        // current year :  
                        if (value.sum_real_distribution_in_asset_currency > 0) {
                            //Set real distribution
                            updated_asset_real_yearly_distribution[index] = 100 * value.sum_real_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                            //Min distributionn cannot be < real distribution
                            updated_asset_model_yearly_distribution_min[index] = updated_asset_real_yearly_distribution[index];
                        }

                        if (value.sum_real_capital_call_in_asset_currency < 0) {
                            //set real capital call
                            updated_asset_real_yearly_capitalcall[index] = 100 * value.sum_real_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                            // Min call cannot be less than real  (negative value )
                            updated_asset_model_yearly_capitalcall_min[index] = updated_asset_real_yearly_capitalcall[index];
                        }

                        //model capital  call exceed real capital call
                        if (value.sum_model_capital_call_in_asset_currency < value.sum_real_capital_call_in_asset_currency) {
                            updated_asset_model_yearly_capitalcall[index] = 100 * value.sum_real_capital_call_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        } else {
                            //Model capital call is at least = to real capital call
                            updated_asset_model_yearly_capitalcall[index] = updated_asset_real_yearly_capitalcall[index]
                        }

                        // model distribution exceed real distribution
                        if (value.sum_model_distribution_in_asset_currency > value.sum_real_distribution_in_asset_currency) {
                            updated_asset_model_yearly_distribution[index] = 100 * value.sum_model_distribution_in_asset_currency / props.data.summary.position_commitment_in_asset_currency;
                        } else {
                            //Model distribution is at least = to real distribution
                            updated_asset_model_yearly_distribution[index] = updated_asset_real_yearly_distribution[index];
                        }
                    }
                      
                      return null; // You should return something from the map function
                });

            
                set_asset_model_yearly_capitalCall(updated_asset_model_yearly_capitalcall);
                set_asset_real_yearly_capitalCall(updated_asset_real_yearly_capitalcall);

                set_asset_model_yearly_distribution(updated_asset_model_yearly_distribution);
                set_asset_real_yearly_distribution(updated_asset_real_yearly_distribution);
                set_asset_sum_real_yearly_capitalcall(updated_asset_real_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))
                set_asset_sum_model_yearly_capitalcall(updated_asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))

                set_asset_sum_real_yearly_distribution(updated_asset_real_yearly_distribution.reduce((acc, curr) => acc + curr, 0))
                set_asset_sum_model_yearly_distribution(updated_asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0) )
            }
        })

    }, [props]);



    function handleSelectDate(selectedDates, dateStr, instance) {
        set_position_subscription_date(dateStr);


    }
    function handleSelectInceptionDate(selectedDates, dateStr, instance) {
        set_asset_inception_date(dateStr);
        set_asset_vintage_year(new Date(dateStr).getFullYear());

    }
    async function handleSaveForm() {
        console.log('handleSaveForm')
        console.log('position_id', position_id);

        let isError = false;
        //check if account is set
        if (asset_name === '') {
            set_asset_name_error('Please specify the asset name')
            isError =true
        }

        if (position_account_id === 0) {
            set_position_account_error('Please select an account')
            isError =true
        }

        if (position_commitment=== 0) {
            set_position_commitment_error('Please specify the commitment to this position')
            isError =true
        }
        if(isError){
            addAlert({
                status: 'danger',
                title: 'Missing data',
                message: 'Please check your form for error or missing informations'
            });
            return false
        }
        //Case 1 Existing position and  ASSET
        if (position_id && position_id !== 0) {
            // save the position
            // this is an update

            if (edit_mode === 'edit') {

                //This is an edit ansd the asset is edited as well
                if (editThisAsset) {
                    if (await saveAsset()) {
                        if (savePosition()) { set_asset_is_saved(true) }
                    }
                } else {
                    //This is an edit BUT the asset is NOT edited

                    if (savePosition()) { set_asset_is_saved(true) }
                }


            }
        } else if (asset_is_linked) {
            savePosition()
        } else if (!asset_is_linked) {
            await saveAsset()

        }

        matching_position_update.forEach((item, index) => {
            console.log('matching_position_update', item)
            if (item.update) {
                saveAsset(item.asset_id);
            }
        });


    }
    async function savePosition(new_position_id = position_id, new_asset_id = 0) {
        //console.log('savePosition', new_position_id)
        var position_post_data = {
            'position_account_id': position_account_id,
            'position_asset_id': (asset_id !== 'new' ? asset_id : new_asset_id),
            'position_subscription_date': MoondanceLayout.formatDate_DMY_to_YMD(position_subscription_date),
            'position_shares': parseFloat(position_shares),
            'position_status': parseInt(position_status),
            'position_commitment': position_commitment,
        };

        //console.log('savePosition', position_post_data)
        if (position_id && position_id !== 0 && position_id !== 'new') {
            var updateMode = true
            try {
                if (position_id) {
                    updateMode = true
                    position_post_data.position_id = position_id
                    var result = await moondanceApi.updatePosition(position_id, position_post_data)
                } else {
                    var result = await moondanceApi.addNewPosition(position_post_data)

                }
                var accountName = accountList.find(option => option.value === position_account_id)

                if (result.success) {
                    addAlert({
                        status: 'success',
                        title: 'CreatingPosition',
                        message: 'The position ' + asset_name + ' has  been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                    })

                    navigate('/position/' + result.id);
                    
                    return true;
                } else {
                    addAlert({
                        status: 'danger',
                        title: 'Error',
                        message: 'The position ' + asset_name + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                    });
                    return false;
                }

            } catch (error) {
                addAlert({ status: 'danger', message: 'This position has NOT been updated' });

                //console.log(error);
            }
        } else {
            var updateMode = false
            try {
                if (asset_id) {
                    var result = await moondanceApi.addNewPosition(position_post_data)

                    var accountName = accountList.find(option => option.value === position_account_id)

                    if (result.success) {
                        addAlert({
                            status: 'success',
                            title: 'Position created',
                            message: 'The position ' + asset_name + ' has  been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                        })
                        console.log('savePosition', result)
                        navigate('/position/' + result.id);
                        return true;
                    } else {
                        addAlert({
                            status: 'danger',
                            title: 'Error',
                            message: 'The position ' + asset_name + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '') + (result.error ? ' - Error code : ' + result.error : '')
                        });
                        return false;
                    }
                } else {
                    addAlert({ status: 'danger', message: 'This position has NOT been updated' });

                }

            } catch (error) {
                addAlert({ status: 'danger', message: 'This position has NOT been updated' });

                //console.log(error);
            }
        }
    }

    async function saveAsset(this_asset_id = asset_id) {
      
        var asset_post_data = {
            'asset_status': asset_status,
            'asset_name': asset_name,
            'asset_emitter': asset_emitter,
            'asset_account_id': position_account_id,
            'asset_type': asset_type,
            'asset_currency': asset_currency,
            'asset_description': asset_description,
            'asset_inception_date': MoondanceLayout.formatDate_DMY_to_YMD(asset_inception_date),
            'asset_investment_period': asset_investment_period,
            'asset_detention_period': asset_detention_period,
            'asset_expected_drawdown': asset_expected_drawdown / 100,
            'asset_expected_irr': asset_expected_irr / 100,
            'asset_expected_tvpi': asset_expected_tvpi,
            'asset_intermediate': String(asset_intermediate),
            'asset_feeder_name': String(asset_feeder_name),
            'asset_feeder_type': String(asset_feeder_type),
            'asset_vintage_year': asset_vintage_year,
            'asset_geographies': asset_geographies,
            'asset_sectors': asset_sectors,
            'asset_model_capitalcall': asset_model_yearly_capitalcall,
            'asset_model_distribution': asset_model_yearly_distribution
        };

        console.log('saveAsset', asset_post_data)
        console.log('saveAsset',props.editMode, asset_id,this_asset_id)

        var updateMode = false
        try {
            if (this_asset_id !== 'new' && this_asset_id !== 0) {

                updateMode = true
                asset_post_data.asset_id = this_asset_id

                //console.log('asset_post_data', asset_post_data)
                var result = await moondanceApi.updateAsset(this_asset_id, asset_post_data)
            } else {

               

                //NEW ASSET
                console.log('NEW ASSET', asset_post_data)
                var result = await moondanceApi.createAsset(asset_post_data)
                //console.log('saveAsset', result)
                //console.log('saveAsset: Result ID', result.id)
                set_asset_id(result.id)
                savePosition(0, result.id)
            }

            var accountName = accountList.find(option => option.value === position_account_id)

            if (result.success) {
                addAlert({
                    status: 'success',
                    title: 'Position ' + (updateMode ? 'Update' : 'Created'),
                    message: 'The asset ' + asset_name + ' has been ' + (updateMode ? 'updated ' : 'created ') + (accountName.label ? 'in account ' + accountName.label : '')
                });

                return true;
            } else {
                addAlert({
                    status: 'danger',
                    title: (result.error ? ' ' + result.error : ''),
                    message: 'The asset ' + (asset_name ? asset_name : '') + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (accountName && accountName.label ? 'in account ' + accountName.label : '')
                });
                return false;
            }
        } catch (error) {
            addAlert({
                status: 'danger',
                title: ((result && result.error) ? 'Undefined Error ' + result.error : ''),
                message: 'The asset ' + (asset_name ? asset_name : '') + ' has  NOT been ' + (updateMode ? 'updated ' : 'created ') + (accountName && accountName.label ? 'in account ' + accountName.label : '')
            });
            return false;
        }

        
    }

    function handle_currency_change(selectedOption) {
         set_asset_currency(selectedOption.id);
    }
    function setStateWithFallback(setter, newValue, initialStateValue) {
        setter(newValue !== undefined ? newValue : initialStateValue);
    }
    function setAssetState(newValues) {
        // console.log('setAssetState', newValues)
        // Call setStateWithFallback for each property you want to set
        setStateWithFallback(set_asset_id, newValues.asset_id, initialAssetState.asset_id);
        setStateWithFallback(set_asset_currency, newValues.asset_currency, initialAssetState.asset_currency);
        setStateWithFallback(set_asset_name, newValues.asset_name, initialAssetState.asset_name);
        setStateWithFallback(set_asset_emitter, newValues.asset_emitter, initialAssetState.asset_emitter);
        setStateWithFallback(set_asset_type, newValues.asset_type, initialAssetState.asset_type);
        setStateWithFallback(set_asset_feeder_name, newValues.asset_feeder_name, initialAssetState.asset_feeder_name);
        setStateWithFallback(set_asset_feeder_type, newValues.asset_feeder_type, initialAssetState.asset_feeder_name);
        setStateWithFallback(set_asset_intermediate, newValues.asset_intermediate, initialAssetState.asset_intermediate);
        setStateWithFallback(set_asset_description, newValues.asset_description, initialAssetState.asset_description);
        setStateWithFallback(set_asset_inception_date, newValues.asset_inception_date, initialAssetState.asset_inception_date);
        setStateWithFallback(set_asset_vintage_year, newValues.asset_vintage_year, initialAssetState.asset_vintage_year);
        setStateWithFallback(set_asset_investment_period, newValues.asset_investment_period, initialAssetState.asset_investment_period);
        setStateWithFallback(set_asset_detention_period, newValues.asset_detention_period, initialAssetState.asset_detention_period);
        setStateWithFallback(set_asset_expected_drawdown, newValues.asset_expected_drawdown, initialAssetState.asset_expected_drawdown);
        setStateWithFallback(set_asset_expected_irr, newValues.asset_expected_irr, initialAssetState.asset_expected_irr);
        setStateWithFallback(set_asset_expected_tvpi, newValues.asset_expected_tvpi, initialAssetState.asset_expected_tvpi);
        setStateWithFallback(set_asset_geographies, newValues.asset_geographies, initialAssetState.asset_geographies);
        setStateWithFallback(set_asset_sectors, newValues.asset_sectors, initialAssetState.asset_sectors);
        setStateWithFallback(set_asset_model_yearly_capitalCall, newValues.asset_model_yearly_capitalCall, initialAssetState.asset_model_yearly_capitalCall);
        setStateWithFallback(set_asset_model_yearly_capitalcall_min, newValues.asset_model_yearly_capitalcall_min, initialAssetState.asset_model_yearly_capitalcall_min);
        setStateWithFallback(set_asset_model_yearly_capitalcall_max, newValues.asset_model_yearly_capitalcall_max, initialAssetState.asset_model_yearly_capitalcall_max);
        setStateWithFallback(set_asset_real_yearly_capitalCall, newValues.asset_real_yearly_capitalCall, initialAssetState.asset_real_yearly_capitalCall);
        setStateWithFallback(set_asset_sum_model_yearly_capitalcall, newValues.asset_sum_model_yearly_capitalcall, initialAssetState.asset_sum_model_yearly_capitalcall);
        setStateWithFallback(set_asset_sum_real_yearly_capitalcall, newValues.asset_sum_real_yearly_capitalcall, initialAssetState.asset_sum_real_yearly_capitalcall);
        setStateWithFallback(set_asset_sum_model_yearly_distribution, newValues.asset_sum_model_yearly_distribution, initialAssetState.asset_sum_model_yearly_distribution);
        setStateWithFallback(set_asset_sum_real_yearly_distribution, newValues.asset_sum_real_yearly_distribution, initialAssetState.asset_sum_real_yearly_distribution);
        setStateWithFallback(set_asset_model_yearly_distribution, newValues.asset_model_yearly_distribution, initialAssetState.asset_model_yearly_distribution);
        setStateWithFallback(set_asset_real_yearly_distribution, newValues.asset_sum_real_yearly_distribution, initialAssetState.asset_sum_real_yearly_distribution);
        setStateWithFallback(set_asset_model_yearly_distribution_min, newValues.asset_model_yearly_distribution_min, initialAssetState.asset_model_yearly_distribution_min);
    }
    function load_asset_data(id) {
        //console.log('load_asset_data')
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }

        try {
            moondanceApi.fetchPositionDetails(api_parameters, id).then(response => {

                console.log('load_asset_data', response)
                if (response.data) {
                    // moondanceApi.fetchPositionYearly(api_parameters, id).then(response => {
                    setAssetState(response.data)
                }
            })
        } catch (error) {
            //console.log(error);
        }
    }
    /* Capital calls & Distributions *****************************************************/
    function handleCapitalCallPerYear(key, event) {

        //fund the index of this
        const index = parseInt(key.split('-')[1]);
        const thisForm = key.split('-')[0];
        const updatedCapitalCallPerYear = [...asset_model_yearly_capitalcall];
        const update_asset_model_yearly_capitalcall_min = [...asset_model_yearly_capitalcall_min]
        const update_asset_model_yearly_capitalcall_max = [...asset_model_yearly_capitalcall_max]

        //console.log('index', thisForm)
        if (thisForm === 'capitalCallPerYear_form') {
            var value = event.target.value;
        } else {
            var value = event.target.value / 100;
        }
        if (value > 0) {
            value = -value * 100
        }
        //sum existing capital calls
        const sum_of_calls = -updatedCapitalCallPerYear.reduce((acc, curr) => acc + curr, 0) + updatedCapitalCallPerYear[index];

        update_asset_model_yearly_capitalcall_min[index] = (-asset_expected_drawdown + sum_of_calls < 0 ? -asset_expected_drawdown + sum_of_calls : 0)
        update_asset_model_yearly_capitalcall_max[index] = ((asset_vintage_year + index === MoondanceLayout.currentYear() && asset_real_yearly_capitalcall[index] < 0) ? asset_real_yearly_capitalcall[index] : 0)

        if (value > update_asset_model_yearly_capitalcall_max[index]) {
            value = update_asset_model_yearly_capitalcall_max[index];
            updatedCapitalCallPerYear[index] = update_asset_model_yearly_capitalcall_max[index];
        }
        if (value < update_asset_model_yearly_capitalcall_min[index]) {
            value = update_asset_model_yearly_capitalcall_min[index]
            updatedCapitalCallPerYear[index] = update_asset_model_yearly_capitalcall_min[index]
        } else {
            updatedCapitalCallPerYear[index] = parseFloat(value)
        }
        set_asset_sum_model_yearly_capitalcall(updatedCapitalCallPerYear.reduce((acc, curr) => acc + curr, 0))
        set_asset_model_yearly_capitalcall_min(update_asset_model_yearly_capitalcall_min)
        set_asset_model_yearly_capitalcall_max(update_asset_model_yearly_capitalcall_max)
        set_asset_model_yearly_capitalCall(updatedCapitalCallPerYear);
        
         // Calculate updatedDistributionPerYear based on updatedCapitalCallPerYear
         let updatedDistributionPerYear = [...asset_model_yearly_distribution]
         for (let i = 0; i < updatedDistributionPerYear.length; i++) {
             updatedDistributionPerYear[i] = 0
         }
             var updatedIndex =index + parseInt(asset_detention_period);
             if (updatedIndex < 15) {
                 if (updatedCapitalCallPerYear[index] !== 0 && asset_status !== '1' && asset_vintage_year + updatedIndex >= MoondanceLayout.currentYear()) {
                     updatedDistributionPerYear[updatedIndex] = Math.round(
                         - updatedCapitalCallPerYear[index] * Math.pow(1 + asset_expected_irr / 100, asset_detention_period)
                     );
                 } else {
                     updatedDistributionPerYear[updatedIndex] = 0
                 }
             }
         
        set_asset_model_yearly_distribution(updatedDistributionPerYear)
        set_asset_sum_model_yearly_distribution(updatedDistributionPerYear.reduce((acc, curr) => acc + curr, 0))
 


    }
    function recalculateDistributions() {
        // Calculate updatedDistributionPerYear based on updatedCapitalCallPerYear
        const updatedCapitalCallPerYear = [...asset_model_yearly_capitalcall];
        let updatedDistributionPerYear = [...asset_model_yearly_distribution]
        for (let i = 0; i < updatedDistributionPerYear.length; i++) {
            updatedDistributionPerYear[i] = 0
        }
        for (let i = 0; i < updatedDistributionPerYear.length; i++) {
            var currentValue = updatedCapitalCallPerYear[i];
            var updatedIndex = parseInt(i) + parseInt(asset_detention_period);
            if (updatedIndex < 15) {
                if (currentValue !== 0 && asset_status !== '1' && asset_vintage_year + updatedIndex >= MoondanceLayout.currentYear()) {
                    updatedDistributionPerYear[updatedIndex] = Math.round(
                        -currentValue * Math.pow(1 + asset_expected_irr / 100, asset_detention_period)
                    );
                } else {
                    updatedDistributionPerYear[updatedIndex] = 0
                }
            }
        }
        set_asset_model_yearly_distribution(updatedDistributionPerYear)

        set_asset_sum_model_yearly_distribution(updatedDistributionPerYear.reduce((acc, curr) => acc + curr, 0))

    }
    function handleDistributionPerYear(key, event) {
        //console.log('handleDistributionPerYear', event.target.value)
        //fund the index of this
        const index = parseInt(key.split('-')[1]);
        var value = event.target.value / 100;

        if (key.split('-')[0] === 'distributionPerYear_form') {
            var value = event.target.value * 100
        } else {
            var value = event.target.value
        }
        const updated_asset_model_yearly_distribution = [...asset_model_yearly_distribution];
        const updateddistributionPerYear_min = [...asset_model_yearly_distribution_min];

        //if current year
        //console.log('asset_real_yearly_distribution', asset_real_yearly_distribution[index])
        updateddistributionPerYear_min[index] = 0; //((asset_vintage_year + index === MoondanceLayout.currentYear() && asset_real_yearly_distribution[index] > 0) ? asset_real_yearly_distribution[index] : 0)

        //console.log('MIN', updateddistributionPerYear_min[index])
        if (value < updateddistributionPerYear_min[index]) {
            value = updateddistributionPerYear_min[index] * 100
        }

        updated_asset_model_yearly_distribution[index] = parseFloat(value / 100)
        //console.log('asset_sum_real_yearly_distribution', asset_sum_real_yearly_distribution)

        set_asset_sum_model_yearly_distribution(updated_asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0) )
        set_asset_model_yearly_distribution(updated_asset_model_yearly_distribution);
        set_asset_model_yearly_distribution_min(updateddistributionPerYear_min);
    }
    async function fetch_all_position_list() {
        //console.log('fetch_all_position_list', accountList)
        var api_parameters = {
            account: buildAccountParameter(accountList),
            status: 'All',
            userCurrency: userCurrency,
        }
        //console.log('api_parameters', api_parameters)

        try {
            const response = await moondanceApi.fetchPositionsList(api_parameters)

            if (response.data.length > 0) {
                set_all_positions_list(response.data)
                //console.log('fetch_all_position_list', response.data)
                return response.data

            } else {
                return []
            }

        } catch (error) {
            console.log(error);
            return []
        }


        return []

    }
    async function find_matching_position() {

        const all_positions_list = await fetch_all_position_list();


        let matchingPositions = all_positions_list.filter(position => {
            return (position.asset_name === asset_name || (position.asset_feeder_name !== 'undefined' && position.asset_feeder_name !== '' && position.asset_feeder_name === asset_feeder_name))
                && position.position_id !== position_id;
        });
        

        set_matching_position_list(matchingPositions)


        if (matchingPositions && matchingPositions.length > 0) {
            let this_matching_position_update = []

            matchingPositions.map((item, index) => {

                this_matching_position_update[item.position_id] = {
                    id: item.position_id,
                    asset_id: item.asset_id,
                    name: item.asset_name,
                    commitment: item.position_commited_in_asset_currency,
                    account_name: item.position_account_name,
                    update: (matching_position_update[index] ? matching_position_update[index] : false)
                }
            })
            //console.log('this_matching_position_update', this_matching_position_update)
            set_matching_position_update(this_matching_position_update)
            return this_matching_position_update;
        }
    }
    async function fetchCurrencyList() {
        try {
            var date = MoondanceLayout.formatDate(new Date(), 'yyyy-mm-dd')
            var data = await moondanceApi.getLastExchangeRate(date);
            ////console.log('fetchCurrencyList', data)
            setCurrencyList(buildCurrencyList(data.data))

        } catch (error) {
            //console.log(error);
        }
    }
    function buildAssetArray(assetArray) {


        ////console.log('currency list', currencyList)
        ////console.log('asset_array', assetArray)
        const options = assetArray
            .filter(
                (item) =>
                    item.asset_account_id !== position_account_id
                    || ((props && props.data && props.data.summary && props.data.summary.position_asset_id) ? item.asset_id === props.data.summary.position_asset_id : null)
                    || item.asset_id === position_asset_id)
            .map((item) => ({
                id: item.asset_id,
                value: item.asset_id,
                position_id: item.position_id,
                label: `${item.asset_name} (${item.asset_account_name} - ${item.asset_status_label}) `,
            }));
        // Add the first option
        options.unshift({ id: 'new', position_id: 0, value: 'new', label: 'Create a new asset' })

        ////console.log('options', options)
        return options;
    }
    function handle_irr_change(newIrr) {
        set_asset_expected_tvpi(Math.round(100 * ((1 + (newIrr / 100)) ** asset_detention_period)) / 100)
        set_asset_expected_irr(newIrr)
       
    }

    function handle_tvpi_change(newTvpi) {
        set_asset_expected_irr(Math.round(1000 * ((newTvpi ** (1 / asset_detention_period)) - 1) * 100) / 1000)
        set_asset_expected_tvpi(newTvpi)

       
    }
    function handle_detention_period_change(newDetentionPeriod) {

        if (newDetentionPeriod < 0) {
            newDetentionPeriod = 0
        }
        set_asset_expected_tvpi(Math.round(1000 * (1 + (asset_expected_irr / 100)) ** newDetentionPeriod) / 1000)
        set_asset_detention_period(newDetentionPeriod)

        //asset_model_yearly_capitalCall
        const updatedCapitalCallPerYear = [...asset_model_yearly_capitalcall];
        for (let i = 0; i < updatedCapitalCallPerYear.length; i++) {
            if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                if (i < asset_investment_period) {
                    updatedCapitalCallPerYear[i] = Math.round(-asset_expected_drawdown * 100 / asset_investment_period) / 100

                } else {
                    updatedCapitalCallPerYear[i] = 0
                }
            }

        }
        //console.log('updatedCapitalCallPerYear',asset_vintage_year +i, i, asset_expected_drawdown,updatedCapitalCallPerYear[i])
        set_asset_model_yearly_capitalCall(updatedCapitalCallPerYear);
        
    }
    function handle_investment_period_change(newInvestmentPeriod) {
        if (newInvestmentPeriod < 0) {
            newInvestmentPeriod = 0
        }
        set_asset_investment_period(newInvestmentPeriod)

        //asset_model_yearly_capitalCall
        const updatedCapitalCallPerYear = [...asset_model_yearly_capitalcall];
        for (let i = 0; i < updatedCapitalCallPerYear.length; i++) {
            if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                if (i < newInvestmentPeriod) {
                    updatedCapitalCallPerYear[i] = Math.round(-asset_expected_drawdown * 100 / newInvestmentPeriod) / 100

                } else {
                    updatedCapitalCallPerYear[i] = 0
                }
            }

        }
        //console.log('updatedCapitalCallPerYear',asset_vintage_year +i, i, asset_expected_drawdown,updatedCapitalCallPerYear[i])
        set_asset_model_yearly_capitalCall(updatedCapitalCallPerYear);
      

    }
    const fetchSelectedPositionDetails = async (id) => {
        var api_parameters = {
            account: buildAccountParameter(accountList),
            userCurrency: userCurrency,
        }
        try {
            const thisFund = await moondanceApi.fetchPositionDetails(api_parameters, id);
            return thisFund.data;

        } catch (error) {
            return false;
        }
    };
    function handleCapitalCallMatchDrawdown(mode) {

        let current_asset_model_yearly_capitalcall = [...asset_model_yearly_capitalcall]

        const update_asset_model_yearly_capitalcall_min = [...asset_model_yearly_capitalcall_min]
        const update_asset_model_yearly_capitalcall_max = [...asset_model_yearly_capitalcall_max]



        if (mode == 'calls') {
            let rem_asset_expected_drawdown =(parseFloat(asset_expected_drawdown) + parseFloat(asset_sum_real_yearly_capitalcall))
            let delta = parseFloat(asset_expected_drawdown) + current_asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0)
            //Multiply each future call by ratio
            //console.log('handleCapitalCallMatchDrawdown',asset_model_yearly_capitalcall,asset_vintage_year,MoondanceLayout.currentYear())
            for (let i = 0; i < current_asset_model_yearly_capitalcall.length; i++) {
                if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                    let this_ratio = current_asset_model_yearly_capitalcall[i] / rem_asset_expected_drawdown
                    current_asset_model_yearly_capitalcall[i] = current_asset_model_yearly_capitalcall[i] + (delta* this_ratio)
                }
            }
            set_asset_sum_model_yearly_capitalcall(current_asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))
            set_asset_model_yearly_capitalCall(current_asset_model_yearly_capitalcall);
            recalculateDistributions()
        } else {
            let ratio = asset_expected_drawdown / -current_asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0)
            let new_drawdown = asset_expected_drawdown / ratio
            set_asset_expected_drawdown(new_drawdown)
        }

    }
    function handleDistributionsMatchTvpi(mode) {

        if (mode == 'TVPI') {

            let ratio = (asset_sum_real_yearly_distribution + asset_sum_model_yearly_distribution) / (asset_expected_tvpi * asset_expected_drawdown / 100)
            //console.log('handleDistributionsMatchTvpi',ratio,asset_sum_real_yearly_distribution,asset_sum_model_yearly_distribution)
            let newTvpi = Math.round(asset_expected_tvpi * ratio * 1000) / 100000
            set_asset_expected_tvpi(newTvpi)
            set_asset_expected_irr(Math.round(1000 * ((newTvpi ** (1 / asset_detention_period)) - 1) * 100) / 1000)

        } else {

            let current_asset_model_yearly_distribution = [...asset_model_yearly_distribution]

            let model_expected_total_distrib = parseFloat(asset_expected_tvpi * asset_expected_drawdown)
            console.log('model_expected_total_distrib', model_expected_total_distrib)

            let model_already_distributed = parseFloat(asset_sum_real_yearly_distribution)
            console.log('model_already_distributed', model_already_distributed)

            let model_to_be_distributed = asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0)
            console.log('model_to_be_distributed', model_to_be_distributed)

            let delta = model_expected_total_distrib - model_already_distributed - model_to_be_distributed
            console.log('delta', delta)



            for (let i = 0; i < current_asset_model_yearly_distribution.length; i++) {
                if (asset_vintage_year + i >= MoondanceLayout.currentYear()) {
                    let pct_of_future_distrib = current_asset_model_yearly_distribution[i] / model_to_be_distributed
                    let new_distrib = current_asset_model_yearly_distribution[i] + (pct_of_future_distrib * delta)
                    current_asset_model_yearly_distribution[i] = new_distrib
                    //console.log(asset_vintage_year + i, 'now:',current_asset_model_yearly_distribution[i],'pct:', pct_of_future_distrib, 'to be added:',new_distrib, pct_of_future_distrib * delta, 'New value:',current_asset_model_yearly_distribution[i] + (pct_of_future_distrib * delta))
                }
            }
            set_asset_model_yearly_distribution(current_asset_model_yearly_distribution)
            set_asset_sum_model_yearly_distribution(current_asset_model_yearly_distribution.reduce((acc, curr) => acc + curr, 0))

        }
    }
    useEffect(() => {
        var result = buildAssetArray(assetList);
        setAssetSelectList(result)
    }, [position_account_id])

    useEffect(() => {
        set_asset_sum_model_yearly_capitalcall(asset_model_yearly_capitalcall.reduce((acc, curr) => acc + curr, 0))
       
    }, [asset_model_yearly_capitalcall])
    useEffect(() => {

        let matchingPosition = find_matching_position()
    }, [asset_name, asset_feeder_name])
    ///Fetch Asset list after position_id change 
    useEffect(() => {
        console.log('accountList',accountList,accountList.length)
        if (accountList.length > 1) {
            let filteredAccountList = accountList.filter(item => item.user_can_edit_position === 1)
            setEditableAccountList(filteredAccountList)


        } else   if (accountList.length <2 ) {
            console.log('set account as default')
            let filteredAccountList = accountList.filter(item => item.user_can_edit_position === 1)
            setEditableAccountList(filteredAccountList)
            set_position_account_id(accountList[0].value)
        }

        async function fetchData() {
            var api_parameters = {
                account: buildAccountParameter(accountList),
                status: 'All',
                userCurrency: userCurrency,
            }

            if (accountList) {
                try {
                    moondanceApi.fetchAssetsList(api_parameters).then(response => {

                        if (response.data && response.data.length > 0) {
                            setAssetList(response.data)
                            var result = buildAssetArray(response.data);
                            setAssetSelectList(result)

                        }
                    })
                } catch (error) {
                    //console.log(error);
                }
            }
        }

        fetchData();


    }, [accountList, props.data]);


    function handleChangeSimilarPositionCheck(id) {
        console.log('updatedMatchingPositionUpdate', id)

        const updatedMatchingPositionUpdate = matching_position_update.map(item => {
            if (item.id === id) {
                return { ...item, update: !item.update };
            }
            return item;
        });
        console.log('updatedMatchingPositionUpdate', updatedMatchingPositionUpdate)
        set_matching_position_update(updatedMatchingPositionUpdate);
    }





    /*  Return function **************************************************************************/

    function PositionForm() {
        return (

            <>


            </>

        )

    }


    return (
        <React.Fragment>

            <Card>
                <CardBody>

                    <Row>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Asset Name</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <input
                                        className="form-control"
                                        type="text"

                                        value={
                                            asset_name
                                                ? asset_name
                                                : ''
                                        }

                                        onChange={(e) => { set_asset_name(e.target.value); }}
                                        placeholder="Enter the asset name"
                                    />
                                </InputGroup>
                                {(asset_name_error ?  <p style={{ color: 'red' }}>{asset_name_error}</p> : '')}
                            </Col>


                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Account</Form.Label>
                            <Col sm="8">
                                <Select

                                    onChange={(e) => { set_position_account_id(e.value); }}

                                    value={(position_account_id ? accountList.find(option => option.value === position_account_id) : null)}
                                    placeholder="Select Account"
                                    formatOptionLabel={formatOptionLabel}

                                    styles={MoondanceLayout.select2customStyles}

                                    options={editableAccountList}
                                    classNamePrefix="select2-selection"
                                />
                                {(position_account_error ?  <p style={{ color: 'red' }}>{position_account_error}</p> : '')}

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Asset Currency</Form.Label>
                            <Col sm="8">
                                <Select
                                    onChange={(e) => { handle_currency_change(e) }}
                                    formatOptionLabel={formatOptionLabel}

                                    styles={MoondanceLayout.select2customStyles}
                                    value={currencyList.find(option => option.id === asset_currency)}
                                    options={currencyList}
                                />
                            </Col>


                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Commitment</Form.Label>


                            <Col sm="8">
                                <InputGroup>
                                    <input
                                        className="form-control"
                                        type="number"

                                        value={
                                            position_commitment
                                                ? position_commitment
                                                : 0}
                                        onChange={(e) => { set_position_commitment(parseInt(e.target.value)); }}
                                        placeholder="Commitment"
                                    />
                                    <div className="input-group-append" >
                                        <span className="input-group-text">
                                            {asset_currency}
                                        </span>
                                    </div>


                                </InputGroup>
                                {(position_commitment_error ?  <p style={{ color: 'red' }}>{position_commitment_error}</p> : '')}

                            </Col>


                        </Form.Group>

                    </Row>
                    <Row>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Shares</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <input
                                        className="form-control"
                                        type="number"

                                        value={position_shares ?
                                            position_shares
                                            : 0}
                                        onChange={(e) => { set_position_shares(e.target.value); }}
                                        placeholder="Shares"
                                    />


                                </InputGroup>
                            </Col>
                        </Form.Group>

                    </Row>
                    <Row>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Subscription date</Form.Label>
                            <Col sm="8">
                                <InputGroup>
                                    <Flatpickr
                                        allowInput={true}
                                        className="form-control "
                                        onChange={handleSelectDate}
                                        value={position_subscription_date}
                                        options={{
                                            clickOpens: false,  // ensure this is true
                                            allowInput: true,   // ensure direct input is allowed
                                            dateFormat: "d/m/Y",
                                        }}
                                        ref={flatpickrRef}

                                    />
                                    <div className="input-group-append" onClick={() => flatpickrRef.current.flatpickr.open()}>
                                        <span className="input-group-text">
                                            <i className="mdi mdi-calendar"></i>
                                        </span>
                                    </div>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">Position Status</Form.Label>
                            <Col sm="8">
                                <Select
                                    onChange={(e) => { set_position_status(e.value); }}
                                    value={(position_status ? position_status_select.find(option => option.value === position_status) : null)}
                                    placeholder="Select position Status"
                                    formatOptionLabel={formatOptionLabel}

                                    styles={MoondanceLayout.select2customStyles}

                                    options={position_status_select}
                                    classNamePrefix="select2-selection"
                                />
                            </Col>
                        </Form.Group>
                    </Row>
                </CardBody>
            </Card>

            <Row>




                <Card>
                    <CardBody>
                        <Row>



                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">General Partner</Form.Label>
                                <Col sm="8">
                                    <InputGroup>
                                        <input
                                            className="form-control"
                                            type="text"

                                            value={
                                                asset_emitter
                                                    ? asset_emitter
                                                    : ''
                                            }

                                            onChange={(e) => { set_asset_emitter(e.target.value); }}
                                            placeholder="Enter the asset emitter (issuer)"
                                        />
                                    </InputGroup>
                                </Col>


                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Asset Type</Form.Label>
                                <Col sm="8">
                                    <Select
                                        onChange={(e) => { set_asset_type(e.value); }}
                                        value={(asset_type ? assetTypeList.find(option => option.value === asset_type) : null)}
                                        placeholder="Select Asset type"
                                        formatOptionLabel={formatOptionLabel}

                                        styles={MoondanceLayout.select2customStyles}

                                        options={assetTypeList}
                                        classNamePrefix="select2-selection"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Feeder name</Form.Label>
                                <Col sm="8">
                                    <InputGroup>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={
                                                asset_feeder_name
                                                    ? asset_feeder_name
                                                    : ''
                                            }

                                            onChange={(e) => { set_asset_feeder_name(e.target.value); }}
                                            placeholder="Enter the asset feeder name"
                                        />
                                    </InputGroup>
                                </Col>


                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Legal Structure</Form.Label>
                                <Col sm="8">
                                    <InputGroup>
                                        <input
                                            className="form-control"
                                            type="text"

                                            v value={
                                                asset_feeder_type
                                                    ? asset_feeder_type
                                                    : ''
                                            }

                                            onChange={(e) => { set_asset_feeder_type(e.target.value); }}
                                            placeholder="Enter the asset feeder type"
                                        />
                                    </InputGroup>
                                </Col>


                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Intermediate</Form.Label>
                                <Col sm="8">
                                    <InputGroup>
                                        <input
                                            className="form-control"
                                            type="text"

                                            value={
                                                asset_intermediate
                                                    ? asset_intermediate
                                                    : ''
                                            }

                                            onChange={(e) => { set_asset_intermediate(e.target.value); }}
                                            placeholder="Enter the asset intermediate"
                                        />
                                    </InputGroup>
                                </Col>


                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Asset Description</Form.Label>
                                <Col sm="8">
                                    <InputGroup>
                                        <textarea
                                            value={(asset_description ? asset_description : '')}
                                            rows={5}
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => { set_asset_description(e.target.value); }}

                                            placeholder="Enter the asset description"
                                        />

                                    </InputGroup>
                                </Col>


                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Inception date</Form.Label>
                                <Col sm="8">
                                    <InputGroup>
                                        <Flatpickr
                                            allowInput={true}
                                            className="form-control "
                                            onChange={handleSelectInceptionDate}
                                            value={asset_inception_date}
                                            options={{
                                                clickOpens: false,  // ensure this is true
                                                allowInput: true,   // ensure direct input is allowed
                                                dateFormat: "d/m/Y",
                                            }}
                                            ref={flatpickrRef2}

                                        />
                                        <div className="input-group-append" onClick={() => flatpickrRef2.current.flatpickr.open()}>
                                            <span className="input-group-text">
                                                <i className="mdi mdi-calendar"></i>
                                            </span>
                                        </div>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Vintage (first Year)</Form.Label>
                                <Col sm="8">
                                    <InputGroup>
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={
                                                asset_vintage_year
                                                    ? asset_vintage_year
                                                    : ''
                                            }

                                            onChange={(e) => { set_asset_vintage_year(parseInt(e.target.value)); }}
                                            placeholder="Enter the asset vintage year"
                                        />
                                    </InputGroup>
                                </Col>

                            </Form.Group>
                        </Row>
                    </CardBody>
                </Card>
                <h5>Asset Geography</h5>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="6" md="6">
                                <label>Geography <span className="badge bg-light">Optional</span></label>
                            </Col>
                            <Col lg="3" md="3">
                                <label>% in this Geography <span className="badge bg-light">Optional</span></label>
                            </Col>

                        </Row>
                        {(asset_geographies ?
                            asset_geographies.map((row, index) => {

                                return (
                                    <Row key={'geography_' + index} style={{ marginBottom: "1em" }}>
                                        <Col lg="6" md="6">
                                            <Select
                                                value={MoondanceLayout.geographyList.find(option => option.id === row.id)}
                                                placeholder="Select geography (optional)"
                                                styles={MoondanceLayout.select2customStyles}
                                                formatOptionLabel={formatOptionLabel}
                                                options={MoondanceLayout.geographyList}
                                                onChange={(selected) => handle_geography_change(index, selected?.id)}
                                            />
                                        </Col>
                                        <Col lg="3" md="3">
                                            <Select
                                                value={percentageOption.find(option => option.id === parseInt(row.percentage))}
                                                placeholder="Select percentage"

                                                styles={MoondanceLayout.select2customStyles}
                                                formatOptionLabel={formatOptionLabel}
                                                options={get_available_geography_percentage_options(index)}

                                                onChange={(selected) => handle_geography_percentage_change(index, selected?.id)}
                                            />
                                        </Col>
                                        <Col lg="1">
                                            <button className="btn btn-outline-secondary btn-sm" onClick={() => handle_geography_remove_row(index)}>Remove</button>
                                        </Col>
                                    </Row>)
                            })
                            : '')}
                        <hr />
                        {total_geography_percentage < 100 && (
                            <button className="btn btn-outline-secondary btn-sm" onClick={handle_geography_add_row}>Add Geography</button>
                        )}

                    </CardBody>
                </Card>
                <h5>Asset Sector</h5>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="6" md="6">
                                <label>Sector <span className="badge bg-light">Optional</span></label>
                            </Col>
                            <Col lg="3" md="3">
                                <label>% in this sector <span className="badge bg-light">Optional</span></label>
                            </Col>

                        </Row>

                        {(asset_sectors ?
                            asset_sectors.map((row, index) => {

                                return (
                                    <Row key={'sector_' + index} style={{ marginBottom: "1em" }}>
                                        <Col lg="6" md="6">
                                            <Select
                                                value={MoondanceLayout.sectorList.find(option => option.id === row.id)}
                                                placeholder="Select Sector (optional)"

                                                styles={MoondanceLayout.select2customStyles}
                                                formatOptionLabel={formatOptionLabel}
                                                options={MoondanceLayout.sectorList}
                                                onChange={(selected) => handle_sector_change(index, selected?.id)}
                                            />
                                        </Col>
                                        <Col lg="3" md="3">
                                            <Select
                                                value={percentageOption.find(option => option.id === parseInt(row.percentage))}
                                                placeholder="Select percentage"

                                                styles={MoondanceLayout.select2customStyles}
                                                formatOptionLabel={formatOptionLabel}
                                                options={get_available_sector_percentage_options(index)}

                                                onChange={(selected) => handle_sector_percentage_change(index, selected?.id)}
                                            />
                                        </Col>
                                        <Col lg="1">
                                            <button className="btn btn-outline-secondary btn-sm" onClick={() => handle_sector_remove_row(index)}>Remove</button>
                                        </Col>
                                    </Row>)
                            })
                            : '')}
                        <hr />
                        {total_sector_percentage < 100 && (
                            <button className="btn btn-outline-secondary btn-sm" onClick={handle_sector_add_row}>Add Sector</button>
                        )}

                    </CardBody>
                </Card>
                <h4>Asset model</h4>
                <Card>
                    <CardBody>
                        <Row>

                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Investment Period</Form.Label>


                                <Col sm="8">

                                    <InputGroup>
                                        <input
                                            className="form-control"
                                            type="number"

                                            value={
                                                asset_investment_period
                                                    ? asset_investment_period
                                                    : 0}
                                            onChange={(e) => { handle_investment_period_change(parseInt(e.target.value)); }}
                                            placeholder="Investment period"
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                Years
                                            </span>
                                        </div>

                                    </InputGroup>
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Detention Period</Form.Label>


                                <Col sm="8">
                                    <InputGroup>

                                        <input
                                            className="form-control"
                                            type="number"

                                            value={
                                                asset_detention_period
                                                    ? asset_detention_period
                                                    : 0}
                                            onChange={(e) => { handle_detention_period_change(parseInt(e.target.value)); }}
                                            placeholder="Detention period"
                                        />
                                        <div className="input-group-append" >
                                            <span className="input-group-text">
                                                Years
                                            </span>
                                        </div>

                                    </InputGroup>
                                </Col>

                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Expected Drawdown</Form.Label>
                                <Col sm="2">
                                    <InputGroup>

                                        <Input
                                            className="form-control"
                                            type="number"
                                            value={asset_expected_drawdown ? asset_expected_drawdown : 0}
                                            onChange={(e) => { set_asset_expected_drawdown(e.target.value); }}
                                            placeholder="Detention period"
                                        />
                                        <div className="input-group-append" >
                                            <span className="input-group-text">
                                                %
                                            </span>
                                        </div>
                                    </InputGroup>
                                </Col>
                                <Col sm="6">
                                    <Input
                                        type="range"
                                        value={asset_expected_drawdown ? asset_expected_drawdown * 4 : 0}
                                        className="form-range"
                                        min="0"
                                        max="440"
                                        onChange={(e) => {
                                            set_asset_expected_drawdown(e.target.value / 4);
                                        }}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Expected TVPI</Form.Label>
                                <Col sm="2">
                                    <InputGroup>

                                        <Input
                                            className="form-control"
                                            type="number"
                                            value={
                                                asset_expected_tvpi
                                                    ? asset_expected_tvpi
                                                    : 0}
                                            onChange={(e) => { handle_tvpi_change(parseFloat(e.target.value)); }}
                                            placeholder="Detention period"
                                        />
                                        <div className="input-group-append" >
                                            <span className="input-group-text">
                                                X
                                            </span>
                                        </div>
                                    </InputGroup>
                                </Col>
                                <Col sm="6">
                                    <Input
                                        type="range"
                                        value={asset_expected_tvpi ? asset_expected_tvpi * 100 : 0}
                                        className="form-range"
                                        min="0"
                                        max="800"
                                        onChange={(e) => { handle_tvpi_change(parseFloat(e.target.value / 100)); }}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" >
                                <Form.Label column sm="2">Expected IRR</Form.Label>

                                <Form.Label column sm="2">{asset_expected_irr ? MoondanceLayout.formatPercentage(asset_expected_irr / 100, 2) : '0 %'}</Form.Label>

                            </Form.Group>
                        </Row>
                    </CardBody>
                </Card>

                <h4>Yearly Asset model</h4>
                <Col sm="4">
                    <Card>
                        <CardBody>
                            <Row> <h5>Capital calls</h5></Row>
                            {asset_model_yearly_capitalcall.map((value, index) => (
                                <>
                                    <Row>
                                        <Col sm="7">
                                            <p style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span>{asset_vintage_year + index}</span>
                                                <span style={{ textAlign: "right" }}>{(value != 0 ? MoondanceLayout.formatNumber((value / 100) * position_commitment, 0) + ' ' + asset_currency : '')}</span>
                                            </p>
                                        </Col>
                                        <Col sm="5"   >

                                            {(asset_vintage_year + index < MoondanceLayout.currentYear() ?
                                                <InputGroup className="d-flex justify-content-end">
                                                    <span className="input-group-text" style={{ height: "2em", maxWidth: "6em" }}> {MoondanceLayout.formatPercentage(value/100)}</span>
                                                </InputGroup>
                                                :
                                                <InputGroup className="d-flex justify-content-end" >
                                                    <Input
                                                        style={{ height: "2em", maxWidth: "6em" }}
                                                        className="form-control form-control-sm "
                                                        disabled={(asset_vintage_year + index >= MoondanceLayout.currentYear() ? false : true)}
                                                        type="number"
                                                        value={value} //{Math.round(value * 100) / 100}
                                                        onChange={(e) => { handleCapitalCallPerYear('capitalCallPerYear_form-' + index, e) }}

                                                    />
                                                    <div className="input-group-append small-input" >
                                                        <span className="input-group-text" style={{ height: "2em" }}> %</span>
                                                    </div>
                                                </InputGroup>)}

                                        </Col>
                                    </Row>
                                    <Row>
                                        {(asset_vintage_year + index <= MoondanceLayout.currentYear() ?
                                            <ProgressBar min={0} max={100}>
                                                <ProgressBar variant="light" now={parseInt(100 + Math.round(value))} />
                                                <ProgressBar variant="danger" label={(-parseInt(Math.round((value - asset_real_yearly_capitalcall[index]) * 100)) / 100) + "%"} now={-parseInt(value - asset_real_yearly_capitalcall[index])} />
                                                <ProgressBar variant="dark" label={"Called"} now={-parseInt(Math.round(asset_real_yearly_capitalcall[index]))} />

                                            </ProgressBar>
                                            : '')}
                                        {(asset_vintage_year + index >= MoondanceLayout.currentYear() ?
                                            <input
                                                type="range"
                                                id={`capitalCallPerYear-${index}`}

                                                disabled={(asset_vintage_year + index < MoondanceLayout.currentYear() ? true : false)}
                                                value={value * 100}
                                                className={'form-range ' + (asset_vintage_year + index < MoondanceLayout.currentYear() ? '' : 'danger-slider')}
                                                max="0"
                                                min="-10000"
                                                onChange={(e) => { handleCapitalCallPerYear('capitalCallPerYear-' + index, e) }}

                                            />


                                            : <br />)}

                                    </Row>


                                    <hr />
                                </>
                            ))}
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="4">
                    <Card>
                        <CardBody>
                            <Row> <h5>Distributions</h5></Row>
                            {asset_model_yearly_distribution.map((value, index) => (

                                <>
                                    <Row>
                                        <Col sm="7">
                                            <p style={{ display: "flex", justifyContent: "space-between" }}>
                                                <span>{asset_vintage_year + index}</span>
                                                {((asset_real_yearly_distribution[index] + asset_model_yearly_distribution[index]) > 0 ?
                                                    <span className="input-group-text" style={{ height: "2em", maxWidth: "10em" }}> {MoondanceLayout.formatNumber(((asset_model_yearly_distribution[index] + asset_real_yearly_distribution[index]) / 100) * position_commitment, 0) + ' ' + asset_currency}</span>
                                                    : 'No value')}
                                            </p>
                                        </Col>
                                        <Col sm="5">

                                            {((asset_vintage_year + index < MoondanceLayout.currentYear() && asset_real_yearly_distribution[index] > 0) ?
                                                <InputGroup className="d-flex justify-content-end">
                                                    <span className="input-group-text" style={{ height: "2em", maxWidth: "6em" }}> {MoondanceLayout.formatPercentage(asset_real_yearly_distribution[index] / 100)}</span>
                                                </InputGroup>
                                                : '')}


                                            {(asset_vintage_year + index >= MoondanceLayout.currentYear() ?
                                                <InputGroup className="d-flex justify-content-end">
                                                    {(asset_vintage_year + index === MoondanceLayout.currentYear() && asset_real_yearly_distribution[index]>0 ?
                                                        <div style={{ height: "2em" }} className="input-group-append small-input" > <span className="input-group-text" style={{ height: "2em", maxWidth: "6em" }}> {MoondanceLayout.formatPercentage(asset_real_yearly_distribution[index] / 100)} +</span> </div>
                                                        : '')}
                                                    <Input
                                                        style={{ height: "2em", maxWidth: "6em" }}
                                                        className="form-control form-control-sm "
                                                        disabled={(asset_vintage_year + index >= MoondanceLayout.currentYear() ? false : true)}
                                                        type="number"
                                                        value={Math.round(asset_model_yearly_distribution[index])}
                                                        onChange={(e) => { handleDistributionPerYear('distributionPerYear_form-' + index, e) }}

                                                    />
                                                    <div style={{ height: "2em" }} className="input-group-append small-input" >
                                                        <span className="input-group-text" style={{ height: "2em" }}> %</span>
                                                    </div>
                                                </InputGroup>
                                                : '')}

                                        </Col>
                                    </Row>
                                    <Row>
                                        {   (
                                            (
                                            asset_vintage_year + index < MoondanceLayout.currentYear() || 
                                            (asset_vintage_year + index === MoondanceLayout.currentYear() && asset_real_yearly_distribution[index]>0) 
                                            ) ?
                                            <ProgressBar min={0} max={100}>
                                                <ProgressBar variant="dark" label={'distributed'} now={parseInt(Math.round(asset_real_yearly_distribution[index]))} />
                                                {(asset_model_yearly_distribution[index] - asset_real_yearly_distribution[index] > 0 ?
                                                    <ProgressBar
                                                        variant="success"
                                                        label={(parseInt(Math.round(asset_model_yearly_distribution[index]))) + "%"}
                                                        now={parseInt(Math.round(asset_model_yearly_distribution[index]))}
                                                    />
                                                    : '')}

                                            </ProgressBar>
                                            : <br />)}
                                        {(asset_vintage_year + index >= MoondanceLayout.currentYear() ?

                                            <input
                                                type="range"
                                                id={`distributionPerYear-${index}`}

                                                value={(asset_model_yearly_distribution[index] * 100)}
                                                className='form-range '
                                                max="10000"
                                                min="0"
                                                onChange={(e) => { handleDistributionPerYear('distributionPerYear-' + index, e) }}

                                            />
                                            : <br />)}





                                    </Row>

                                    <hr />
                                </>



                            ))}
                        </CardBody>
                    </Card>

                </Col>

                {asset_sum_model_yearly_distribution + asset_sum_real_yearly_distribution > 0 ? (
                    <Col sm="4">
                        <Card>
                            <CardBody>
                                <CardTitle>Summary</CardTitle>

                                <Row>
                                    <Col sm="4"><b>Capital Calls</b></Col>
                                    <Col sm="4" style={{ textAlign: "right" }}><b>{asset_currency}</b> </Col>
                                    <Col sm="4"><b>% of Commitment</b></Col>
                                </Row>
                                <Row>
                                    <Col sm="4">Past</Col>
                                    <Col sm="4" style={{ textAlign: "right" }}>{MoondanceLayout.formatNumber(-asset_sum_real_yearly_capitalcall * position_commitment / 100)} {asset_currency}</Col>
                                    <Col sm="4">{MoondanceLayout.formatPercentage(-asset_sum_real_yearly_capitalcall / 100, 2)}</Col>
                                </Row>

                                <Row>
                                    <Col sm="4">Expected </Col>
                                    <Col sm="4" style={{ textAlign: "right" }}> {MoondanceLayout.formatNumber((-asset_sum_model_yearly_capitalcall + asset_sum_real_yearly_capitalcall) * position_commitment / 100)} {asset_currency}</Col>
                                    <Col sm="4">{MoondanceLayout.formatPercentage((-asset_sum_model_yearly_capitalcall + asset_sum_real_yearly_capitalcall) / 100, 2)}</Col>
                                </Row>
                                <Row>
                                    <Col sm="4"><b>Total</b></Col>
                                    <Col sm="4" style={{ textAlign: "right" }}><b>{MoondanceLayout.formatNumber((-asset_sum_model_yearly_capitalcall) * position_commitment / 100)} {asset_currency}</b></Col>
                                    <Col sm="4"><b>{MoondanceLayout.formatPercentage((-asset_sum_model_yearly_capitalcall) / 100, 2)}</b></Col>
                                </Row>
                                <Row>
                                    <Col sm="4">Expected Drawdowns </Col>
                                    <Col sm="4" style={{ textAlign: "right" }}> {MoondanceLayout.formatNumber(asset_expected_drawdown * position_commitment / 100)} {asset_currency}</Col>
                                    <Col sm="4">{MoondanceLayout.formatPercentage(asset_expected_drawdown / 100, 2)}</Col>
                                </Row>

                                <Row>
                                    {(Math.abs(Math.round((parseFloat(asset_expected_drawdown) + parseFloat(asset_sum_model_yearly_capitalcall)))) < 0.5 ?
                                        <p style={{ marginTop: "2em" }} className="alert alert-success">Modeled capital calls are aligned with anticipated drawdowns.</p>
                                        :
                                        <>
                                            {(asset_expected_drawdown - (-asset_sum_model_yearly_capitalcall) > 0 ?

                                                <p style={{ marginTop: "2em" }} className="alert alert-danger">Modeled capital calls fall short of the anticipated drawdowns.</p>
                                                : <p style={{ marginTop: "2em" }} className="alert alert-danger">Modeled capital calls are exceeding anticipated drawdowns.</p>
                                            )}
                                            <Col sm="12" md="6">
                                                {((asset_sum_model_yearly_capitalcall - asset_sum_real_yearly_capitalcall) !== 0 ?
                                                    <Button className="btn btn-outline-primary" onClick={() => { handleCapitalCallMatchDrawdown('calls') }}>Adjust future calls to match expected drawdown</Button>
                                                    : '')}
                                            </Col>
                                            <Col sm="12" md="6">
                                                <Button className="btn btn-outline-primary" onClick={() => { handleCapitalCallMatchDrawdown('draw') }}>Adjust expected drawdown to match future calls</Button>
                                            </Col>
                                            <hr />
                                        </>
                                    )}
                                </Row>
                                <br />
                                <Row>
                                    <Col sm="4"><b>Distributions</b></Col>
                                    <Col sm="4" style={{ textAlign: "right" }}><b>{asset_currency}</b> </Col>
                                    <Col sm="4"><b>% of Commitment</b></Col>
                                </Row>

                                <   Row>
                                    <Col sm="4">Past</Col>
                                    <Col sm="4" style={{ textAlign: "right" }}>{MoondanceLayout.formatNumber(asset_sum_real_yearly_distribution * position_commitment / 100)} {asset_currency}</Col>
                                    <Col sm="4">{MoondanceLayout.formatPercentage(asset_sum_real_yearly_distribution / 100, 2)} </Col>
                                </Row>

                                <Row>
                                    <Col sm="4">Expected</Col>
                                    <Col sm="4" style={{ textAlign: "right" }}> {MoondanceLayout.formatNumber((asset_sum_model_yearly_distribution) * position_commitment / 100)} {asset_currency}</Col>
                                    <Col sm="4">{MoondanceLayout.formatPercentage(asset_sum_model_yearly_distribution / 100, 2)} </Col>
                                </Row>
                                <Row>
                                    <Col sm="4"><b>Total</b></Col>
                                    <Col sm="4" style={{ textAlign: "right" }}><b>{MoondanceLayout.formatNumber((asset_sum_model_yearly_distribution + asset_sum_real_yearly_distribution) * position_commitment / 100)} {asset_currency}</b></Col>
                                    <Col sm="4"><b>{MoondanceLayout.formatPercentage((asset_sum_model_yearly_distribution + asset_sum_real_yearly_distribution) / 100, 2)}</b></Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col sm="4"><b>Total Called</b></Col>
                                    <Col sm="4" style={{ textAlign: "right" }}><b>{MoondanceLayout.formatNumber(-asset_sum_model_yearly_capitalcall * position_commitment / 100)} {asset_currency}</b></Col>
                                    <Col sm="4"><b>{MoondanceLayout.formatPercentage((-asset_sum_model_yearly_capitalcall) / 100, 2)}</b></Col>

                                </Row>
                                <   Row>
                                    <Col sm="4"> x Expected TVPI </Col>
                                    <Col sm="4" style={{ textAlign: "right" }}>{MoondanceLayout.formatNumber(-asset_sum_model_yearly_capitalcall * position_commitment * asset_expected_tvpi / 100)} {asset_currency}</Col>
                                    <Col sm="4"><b>{MoondanceLayout.formatPercentage((-asset_sum_model_yearly_capitalcall * asset_expected_tvpi) / 100, 2)}</b></Col>
                                </Row>

                                <Row>
                                    {(Math.abs(asset_sum_model_yearly_distribution + asset_sum_real_yearly_distribution - (asset_expected_drawdown / 100) * asset_expected_tvpi * 100) < 0.1 ?

                                        <p style={{ marginTop: "2em" }} className="alert alert-success">The modeled distributions align with the product of expected drawdowns and Total Value to Paid-In (TVPI)</p>
                                        :

                                        <>
                                            {((asset_sum_model_yearly_distribution + asset_sum_real_yearly_distribution) > (-asset_sum_model_yearly_capitalcall * asset_expected_tvpi) ?

                                                <p style={{ marginTop: "2em" }} className="alert alert-danger">The modeled distributions are exceeding the expected Total Value to Paid-In (TVPI)</p>
                                                :

                                                <p style={{ marginTop: "2em" }} className="alert alert-danger">The modeled distributions are below the expected Total Value to Paid-In (TVPI)</p>
                                            )}


                                            <Col sm="12" md="6">
                                                {(asset_sum_model_yearly_distribution>0 ?
                                                <Button className="btn btn-outline-primary" onClick={() => { handleDistributionsMatchTvpi('distribution') }}>Adjust future distributions to match expected TVPI</Button>
                                                :'Set at least 1 future distribution')}
                                            </Col>
                                            <Col sm="12" md="6">
                                                <Button className="btn btn-outline-primary" onClick={() => { handleDistributionsMatchTvpi('TVPI') }}>Adjust expected TVPI to match future distributions</Button>
                                            </Col>
                                            <hr />
                                        </>

                                    )}
                                </Row>
                                {matching_position_update && matching_position_update.length > 0 ?
                                    <Row>
                                        <h5>Similar Positions in other accounts</h5>
                                        <p>There are similar positions available in different accounts to which you have access.
                                            You can update these positions data and model by checking the corresponding checkboxes.<br />
                                            Account, commitement, number of shares, subscription date and status will not be copied.</p>


                                        <table>
                                            {matching_position_update.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <input
                                                            onClick={() => (handleChangeSimilarPositionCheck(item.id))}
                                                            type="checkbox"
                                                            checked={matching_position_update[item.id].update}
                                                        >
                                                        </input></td>
                                                    <td>#{item.id} - {item.account_name}<br />Commitment :{MoondanceLayout.formatCurrency(item.commitment, selectedCurrency)}</td>


                                                </tr>
                                            ))}
                                        </table>
                                    </Row>
                                    : ''}
                                <Row>

                                    <label id="add_transaction_note-label" >&nbsp;</label>
                                    <button
                                        style={{ marginBottom: '1em' }}
                                        type="button"
                                        className="btn btn-primary form-control"
                                        onClick={() => { handleSaveForm() }}
                                    >
                                        Save position
                                    </button>
                                    {(asset_is_saved && position_id ?
                                        <Link to={'/position/' + position_id} className="btn btn-outline-primary form-control">View position</Link>
                                        : '')}

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                ) : ''}
            </Row>






            <Card>
                <CardBody>

                    <Row>
                        < Form.Group as={Row} className="mb-3" >
                            <Col lg="12">

                                <label id="add_transaction_note-label" >&nbsp;</label>
                                <button
                                    style={{ marginBottom: '1em' }}
                                    type="button"
                                    className="btn btn-primary form-control"
                                    onClick={() => { handleSaveForm() }}
                                >
                                    Save position
                                </button>
                                <br />
                                {(asset_is_saved && position_id ?
                                    <Link to={'/position/' + position_id} className="btn btn-outline-primary form-control">View position</Link>
                                    : '')}
                            </Col>
                        </Form.Group>
                    </Row>
                </CardBody>
            </Card>


        </React.Fragment >


    );
    /*const [asset_first_year, set_asset_first_year] = useState((props.data.summary.asset_first_year ? props.data.summary.asset_first_year : 0));
        const [asset_expected_drawdown, set_asset_expected_drawdown] = useState((props.data.summary.asset_expected_drawdown ? props.data.summary.asset_expected_drawdown : 0));
        const [asset_expected_irr, set_asset_expected_irr] = useState((props.data.summary.asset_expected_irr ? props.data.summary.asset_expected_irr : 0));
        const [asset_expected_tvpi, set_asset_expected_tvpi] = useState((props.data.summary.asset_expected_tvpi ? props.data.summary.asset_expected_tvpi : 0));
        const [asset_intermediate, set_asset_intermediate] = useState((props.data.summary.asset_intermediate ? props.data.summary.asset_intermediate : 0));
        co*/



}



export default EditPositionForm

