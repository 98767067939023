import React, { useState, useContext, useEffect, useRef } from "react";
import { Card, Container, CardBody, CardTitle, Col, Row, } from "reactstrap";

import MoondanceLayout from "../helpers/layout";

import ReactApexChart from "react-apexcharts"


function NavChart(props) {
    const [loading, setLoading] = useState(true);
    const [DATA, setData] = useState({});
    const [fullScreen, setFullScreen] = useState(false)
    const chartRef = useRef(null);

    // External function that accesses the chart element
    async function downloadSVG(datatype) {
        const chartInstance = chartRef.current; // Access the chart DOM element

        if (!chartInstance || !chartInstance.chart || !chartInstance.chart.exports) {
            console.error("Chart instance or necessary properties are undefined");
            return;
        }

        // Use the correct method for exporting the chart based on the datatype
        if (datatype === "png") {
            await chartInstance.chart.exports.exportToPng();
        } else if (datatype === "svg") {
            await chartInstance.chart.exports.exportToSVG();
        } else {
            console.error("Unsupported datatype");
        }
    }


    useEffect(() => {
        //console.log('props.data', props.data)
        if (props.data) {

            var serie = []
            props.data.map((item) => {
                var thisDate = item.timestamp * 1000
                if (props.yscale === 'nav_per_share') {
                    serie.push([thisDate, item.nav_in_user_currency_per_shares])
                } else {
                    serie.push([thisDate, item.nav_in_user_currency])
                }
            })
            /*  props.data.map((item) => {
                            var thisDate = item.timestamp * 1000
                            serie.push([thisDate, item.nav_in_user_currency])
                         
                        })*/


            var returnData = {}
            returnData.series = [{
                name: 'NAV',
                data: serie
            }]

            returnData.options = {

                chart: {
                    height: 350,
                    type: 'area',
                    zoom: {
                        enabled: false
                    },
                },
                stroke: {
                    curve: 'smooth'
                },
                markers: {
                    size: 8,
                },
                colors: [

                    MoondanceLayout.colorScheme.success
                ],
                xaxis: {
                    type: 'datetime',
                },

                yaxis: {
                    labels: {
                        formatter: function (value) {

                            if (props.yscale === 'nav_per_share') {
                                return MoondanceLayout.formatNumber(value) + (props.currency ? MoondanceLayout.currencySymbol(props.currency) : '');
                            } else {
                                return MoondanceLayout.formatNumber(value / 1000) + ' k' + (props.currency ? MoondanceLayout.currencySymbol(props.currency) : '');

                            }
                        },
                    },
                }

            };
            setData(returnData);



        }

    }, [props.data, props.currency, props.display])


    function showGraph() {
        return (
            <>

                {(DATA.options ?
                    <>
                        <ReactApexChart
                            ref={chartRef}
                            key={chartRef}
                            options={DATA.options}
                            series={DATA.series}
                            height= {(fullScreen ? (window.innerHeight-100) : (props.height ?  props.height : 350))}
          
                            title={props.title}
                            className="apex-charts"
                        />
                        <button className="btn btn-link" onClick={() => { setFullScreen(!fullScreen) }} > {(fullScreen ? <><span className="bx bx-collapse"></span> Reduce</> : <><span className="bx bx-expand"></span> Full Screen</>)} </button>
                        <button className="btn btn-link" onClick={() => { downloadSVG('png') }} > <span className="bx bx-export"></span> Export </button>
                    </>

                    : '')}


            </>
        )

    }


    return (

        (fullScreen ?


            <div style={{ zIndex: 100000, width: '100%', height: '100%', backgroundColor: 'white', minWidth: '100%', minHeight: '100%', position: 'fixed', top: '0', left: '0' }}>
                {showGraph()}
            </div>

            :

            <>  {showGraph()}</>
        )
    )



}

export default NavChart;