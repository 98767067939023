
import React, { useState, useContext, useEffect } from "react";
import {
  Card, CardBody, CardTitle, Col, Container, Button, Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
} from "reactstrap";

import Chart from 'react-apexcharts';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { sectorList, geographyList, assetType, getStatusLabel, formatCurrency, statusList, formatNumber, formatPercentage, buildYearlyChart, buildFundDetail, buildFlatSerie } from "../helpers/formatData";

import UseMoondanceApi from "../helpers/api";
import MoondanceLayout from "../helpers/layout";

import AddTransactionShortForm from '../transactions/addTransactionShortForm.js';
import YearlyChart from '../helpers/yearlyChartcanvas';
import FlatChart from '../helpers/FlatChartCanvas';
import { MoondanceContext } from '../../App';
import loading_icon from "../../assets/images/loading_icon.gif";
import EditPositionForm from "./EditPositionForm.js"
import BarChart from "../helpers/barchart";
import PositionYtd from "./PositionYtd.js";
import WaterFallChart from "../helpers/WaterFallChart";

import Notes from "../notes/listNotes";
import TransactionsGraph from "../transactions/TransactionsGraph.js";
import NavChart from "../nav/navChart.js";
import PortraitAlert from "../helpers/portraitAlert.js";
import Loading from "../helpers/loading.js";
const PositionDetails = () => {


  document.title = "Dashboard | Moondance";

  const moondanceApi = UseMoondanceApi();

  const { portraitMode } = useContext(MoondanceContext);
  const { accountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
  const [actionDropDown, set_actionDropDown] = useState(false)
  const [showAddTransactionForm, setShowAddTransactionForm] = useState(false)
  const [nav_scale_dropdown, set_nav_scale_dropdown] = useState(false)
  const [model_scale_dropdown_1, set_model_scale_dropdown_1] = useState(false)
  const [model_scale_dropdown_2, set_model_scale_dropdown_2] = useState(false)
  const [model_scale, set_model_scale] = useState('cash')
  const [nav_scale, set_nav_scale] = useState('nav_per_share')
  const [model_scale_options, set_model_scale_options] = useState([
    { id: 'cash', label: 'Amount' },
    { id: 'cash_asset_currency', label: 'Amount in asset currency' },
    { id: 'pct_commited', label: '% of commitment' },
    { id: 'pct_called', label: '% of total called' },
  ])
  const [nav_scale_options, set_nav_scale_options] = useState([
    { id: 'nav', label: 'Total NAV' },
    { id: 'nav_per_share', label: 'NAV per share' }
  ])
  const [DATAPERGROUP, setDataPerGroup] = useState({});



  const navigate = useNavigate();

  const params = useParams();
  const id = params.id;
  const [DATA, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [cashflowChartDisplay, setCashflowChartDisplay] = useState('All');
  const [showCapitalCallsOnly, setShowCapitalCallsOnly] = useState(false)


  const [activeTab, setactiveTab] = useState("1");
  useEffect(() => {

    set_model_scale_options(
      [
        { id: 'cash', label: 'Amount in ' + userCurrency },
        (DATA && DATA.summary && DATA.summary.asset_currency && DATA.summary.asset_currency !== userCurrency ? { id: 'cash_asset_currency', label: 'Amount in ' + (DATA && DATA.summary && DATA.summary.asset_currency ? DATA.summary.asset_currency : 'asset currency') } : ''),
        { id: 'pct_commited', label: '% of commitment' },
        { id: 'pct_called', label: '% of total called' },
      ]
    )
  }, [userCurrency, DATA]);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };
  const fetchAndSetSectors = async (api_parameters, id) => {
    try {
      const sector = await moondanceApi.fetchPositionSectors(api_parameters, id);
      if (sector && sector.data && Array.isArray(sector.data)) {
        return sector.data;
      }

    } catch (error) {
      console.error("Error fetching sector:", error);
      return false;
    }
  };

  const fetchAndSetIrrSerie = async (api_parameters, id) => {
    try {
      const result = await moondanceApi.fetchPositionIrrSerie(api_parameters, id);

      if (result && result.data && Array.isArray(result.data)) {
        return result.data;
      }

    } catch (error) {
      console.error("Error fetching irr serie:", error);
      return false;
    }
  };

  const fetchThisFundNavHistory = async (api_parameters, id) => {
    try {
      const navHistory = await moondanceApi.fetchPositionNavHistory(api_parameters, id);
      if (navHistory && navHistory.data && Array.isArray(navHistory.data)) {
        return navHistory.data;
      }

    } catch (error) {
      console.error("Error fetching navHistory:", error);
      return false;

    }
  };

  const fetchAndSetGeography = async (api_parameters, id) => {
    try {
      const geography = await moondanceApi.fetchPositionGeography(api_parameters, id);
      if (geography && geography.data && Array.isArray(geography.data)) {
        return geography.data;
      }

    } catch (error) {
      console.error("Error fetching geography:", error);
      return false;

    }
  };
  const fetchAndSetTypes = async (api_parameters, id) => {
    try {
      const type = await moondanceApi.fetchPositionTypes(api_parameters, id);
      if (type && type.data && Array.isArray(type.data)) {
        return type.data;
      }

    } catch (error) {
      console.error("Error fetching types:", error);
      return false;

    }
  };

  const { toggleTransactionModal, closeTransactionModal } = useContext(MoondanceContext);
  const { transactionModalData, setTransactionModalData } = useContext(MoondanceContext);
  function openTransactionModal(data) {
    setTransactionModalData(data)
    toggleTransactionModal(true);
  }

  //########################################################################
  // Edit position 

  const { toggleNavModal, closeNavModal } = useContext(MoondanceContext);
  const { navModalData, setNavModalData } = useContext(MoondanceContext);
  const [showEditPosition, setEditPosition] = useState(false)
  function toggleEditPosition() {
    setEditPosition(!showEditPosition)
  }


  function changeChartDisplay(newValue) {
    setCashflowChartDisplay(newValue);
  }

  function editNav(data) {
    console.log('editNav', data)
    var url = window.location.href;
    setNavModalData(data)
    toggleNavModal(true);

  }
  useEffect(() => {
    //console.log('Position detail useEffect', id, lastUpdate)
    var api_parameters = {
      account: false,
      lastUpdate: lastUpdate,
      userCurrency: userCurrency,
    }
    var data = {};

    function filterDataPeryear(dataArray) {
      const currentYear = new Date().getFullYear();

      dataArray.forEach(item => {
        if (item.year < currentYear) {
          item['sum_remaining_capital_call_in_user_currency'] = 0;
          item['sum_remaining_distribution_in_user_currency'] = 0;
          item['sum_remaining_balance_in_user_currency'] = 0;
          item['sum_remaining_capital_call_in_asset_currency'] = 0;
          item['sum_remaining_distribution_in_asset_currency'] = 0;
          item['sum_remaining_balance_in_asset_currency'] = 0;
          Object.keys(item).forEach(key => {
            if (key.includes("model")) {
              item[key] = 0;
            }
          });
        } else {
          item['sum_remaining_capital_call_in_user_currency'] = (item['sum_model_capital_call_in_user_currency'] - item['sum_real_capital_call_in_user_currency'] < 0 ? item['sum_model_capital_call_in_user_currency'] - item['sum_real_capital_call_in_user_currency'] : 0);
          item['sum_remaining_distribution_in_user_currency'] = (item['sum_model_distribution_in_user_currency'] - item['sum_real_distribution_in_user_currency'] > 0 ? item['sum_model_distribution_in_user_currency'] - item['sum_real_distribution_in_user_currency'] : 0);
          item['sum_remaining_balance_in_user_currency'] = item['sum_model_distribution_in_user_currency'] + item['sum_model_capital_call_in_user_currency'];

          item['sum_remaining_capital_call_in_asset_currency'] = (item['sum_model_capital_call_in_asset_currency'] - item['sum_real_capital_call_in_asset_currency'] < 0 ? item['sum_model_capital_call_in_asset_currency'] - item['sum_real_capital_call_in_asset_currency'] : 0);
          item['sum_remaining_distribution_in_asset_currency'] = (item['sum_model_distribution_in_asset_currency'] - item['sum_real_distribution_in_asset_currency'] > 0 ? item['sum_model_distribution_in_asset_currency'] - item['sum_real_distribution_in_asset_currency'] : 0);
          item['sum_remaining_balance_in_asset_currency'] = item['sum_model_distribution_in_asset_currency'] + item['sum_model_capital_call_in_asset_currency'];
        }
      });
      return dataArray

    }

    async function fetchData() {

      try {
        var data_summary = await moondanceApi.fetchPositionDetails(api_parameters, id);
        // console.log('data_summary', data_summary)
        var yearly_data = await moondanceApi.fetchPositionYearly(api_parameters, id);
        //console.log('yearly_data', yearly_data)

        data = buildYearlyChart(yearly_data, data_summary.data.asset_name, 'k', false)
        if (data) {
          //console.log('tempdata', yearly_data)
          data.flat = buildFlatSerie(yearly_data, data_summary.data.n, 'k', false)

          data.summary = data_summary.data
          data.sectors = await fetchAndSetSectors(api_parameters, id);
          data.geographies = await fetchAndSetGeography(api_parameters, id);
          data.types = await fetchAndSetTypes(api_parameters, id);
          data.irrSerie = await fetchAndSetIrrSerie(api_parameters, id);

          data.perYear = filterDataPeryear(yearly_data.data)

          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();

          for (let i = 0; i < data.perYear.length; i++) {
            if (data.perYear[i].year === currentYear) {
              data.currentYear = data.perYear[i];
            }
          }


          data.overviewChart = MoondanceLayout.buildOverviewChart(data)
          data.callable = MoondanceLayout.buildCallableChart(data)


          data.cashflow = []
          data.cumulatedCashflow = []

          data.cashflow['Past'] = []
          data.cashflow['Future'] = []
          data.cashflow['All'] = []
          data.cumulatedCashflow['Past'] = []
          data.cumulatedCashflow['Future'] = []
          data.cumulatedCashflow['All'] = []


          {
            model_scale_options.length > 0 && model_scale_options.map((item, index) => {
              data.cashflow['Past'][item.id] = MoondanceLayout.buildCashFlowChart(data, 'Past', false, false, item.id)
              data.cashflow['Future'][item.id] = MoondanceLayout.buildCashFlowChart(data, 'Future', false, false, item.id)
              data.cashflow['All'][item.id] = MoondanceLayout.buildCashFlowChart(data, 'All', false, false, item.id)

              data.cumulatedCashflow['Past'][item.id] = MoondanceLayout.buildCumulatedCashFlowChart(data, 'Past', false, false, item.id)
              data.cumulatedCashflow['Future'][item.id] = MoondanceLayout.buildCumulatedCashFlowChart(data, 'Future', false, false, item.id)
              data.cumulatedCashflow['All'][item.id] = MoondanceLayout.buildCumulatedCashFlowChart(data, 'All', false, false, item.id)
            })
          }
          // console.log('data', data)
          var data_transactions = await moondanceApi.fetchPositionTransactions(api_parameters, id);
          data.transactions = data_transactions.data

          if (data.transactions && data.transactions.length > 0) {
            let tempDataPerType = data.transactions.reduce((acc, item) => {
              acc[item.type] = (acc[item.type] || 0) + item.value_in_user_currency;
              return acc;
            }, {});
            let dataPerType = Object.keys(tempDataPerType).map(key => ({
              type: key,
              value_in_user_currency: tempDataPerType[key]
            }));

            data.transactionsPerType = dataPerType
          }
          var data_navHistory = await fetchThisFundNavHistory(api_parameters, id);
          data.navHistory = data_navHistory





          //build model check
          data.modelCheck = []
          data.modelCheck.modelExpectedCalls = data.cashflow['All']['pct_commited'].rawdata['mcc'].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          data.modelCheck.sumPreviousCalls = data.cashflow['All']['pct_commited'].rawdata['rcc'].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          data.modelCheck.spread = data.modelCheck.modelExpectedCalls + data.modelCheck.sumPreviousCalls + data.summary.asset_expected_drawdown
          data.modelCheck.fix_coef = (data.modelCheck.spread !== 0 ? data.modelCheck.spread : 0)

          //console.log('data.modelCheck.fix_coef',data.modelCheck)  
          //Calculate stats based on cashflow
          data.stats = []
          data.stats.sumOfFutureCalls = 0
          data.stats.sumOfFutureDistributions = 0
          data.stats.futureAddedGain = 0
          data.stats.sumOfPastCalls = 0
          data.stats.sumOfPastDistributions = 0
          data.stats.pastAddedGain = 0

          data.stats.sumOfCalls = 0
          data.stats.sumOfDistributions = 0
          data.stats.addedGain = 0


          if (data.cashflow['Future'] && data.cashflow['Future'].cash && data.cashflow['Future'].cash.data[0] && data.cashflow['Future'].cash.data[0].data) {
            data.stats.sumOfFutureCalls = data.cashflow['Future'].cash.data[0].data.reduce((a, b) => a + b, 0)
          }
          if (data.cashflow['Future'] && data.cashflow['Future'].cash.data[1] && data.cashflow['Future'].cash.data[1].data) {
            data.stats.sumOfFutureDistributions = data.cashflow['Future'].cash.data[1].data.reduce((a, b) => a + b, 0)
          }
          data.stats.sumOfFutureCashFlows = data.stats.sumOfFutureCalls + data.stats.sumOfFutureDistributions

          if (data.cashflow['Past'] && data.cashflow['Past'].cash.data[0] && data.cashflow['Past'].cash.data[0].data) {
            data.stats.sumOfPastCalls = data.cashflow['Past'].cash.data[0].data.reduce((a, b) => a + b, 0)
          }
          if (data.cashflow['Past'] && data.cashflow['Past'].cash.data[1] && data.cashflow['Past'].cash.data[0].data) {
            data.stats.sumOfPastDistributions = data.cashflow['Past'].cash.data[1].data.reduce((a, b) => a + b, 0)
          }
          data.stats.sumOfPastCashFlows = data.stats.sumOfPastCalls + data.stats.sumOfPastDistributions
          data.stats.sumOfCalls = data.stats.sumOfPastCalls + data.stats.sumOfFutureCalls
          data.stats.sumOfDistributions = data.stats.sumOfPastDistributions + data.stats.sumOfFutureDistributions
          data.stats.sumOfCashFlows = data.stats.sumOfCalls + data.stats.sumOfDistributions

          if (data && data.summary && userCurrency !== data.summary.asset_currency) {

            data.stats.sumOfFutureCalls_in_asset_currency = 0
            data.stats.sumOfFutureDistributions_in_asset_currency = 0
            data.stats.futureAddedGain_in_asset_currency = 0
            data.stats.sumOfPastCalls_in_asset_currency = 0
            data.stats.sumOfPastDistributions_in_asset_currency = 0
            data.stats.pastAddedGain_in_asset_currency = 0

            data.stats.sumOfCalls_in_asset_currency = 0
            data.stats.sumOfDistributions_in_asset_currency = 0
            data.stats.addedGain_in_asset_currency = 0

            if (data && data.cashflow && data.cashflow['Future'] && data.cashflow['Future'].cash_asset_currency && data.cashflow['Future'].cash_asset_currency.data[0] && data.cashflow['Future'].cash_asset_currency.data[0].data) {
              data.stats.sumOfFutureCalls_in_asset_currency = data.cashflow['Future'].cash_asset_currency.data[0].data.reduce((a, b) => a + b, 0)
            }
            if (
              data &&
              data.cashflow
            ) {

              if (
                data.cashflow['Future'] &&
                data.cashflow['Future'].cash_asset_currency &&
                data.cashflow['Future'].cash_asset_currency.data &&
                data.cashflow['Future'].cash_asset_currency.data[1] &&
                data.cashflow['Future'].cash_asset_currency.data[1].data) {
                data.stats.sumOfFutureDistributions_in_asset_currency = data.cashflow['Future'].cash_asset_currency.data[1].data.reduce((a, b) => a + b, 0)
              }
              data.stats.sumOfFutureCashFlows_in_asset_currency = data.stats.sumOfFutureCalls_in_asset_currency + data.stats.sumOfFutureDistributions_in_asset_currency

              if (
                data.cashflow['Past'] &&
                data.cashflow['Past'].cash_asset_currency &&
                data.cashflow['Past'].cash_asset_currency.data) {


                if (
                  data.cashflow['Past'].cash_asset_currency.data[0] &&
                  data.cashflow['Past'].cash_asset_currency.data[0].data
                ) {
                  data.stats.sumOfPastCalls_in_asset_currency = data.cashflow['Past'].cash_asset_currency.data[0].data.reduce((a, b) => a + b, 0)
                }

                if (
                  data.cashflow['Past'].cash_asset_currency.data[1] &&
                  data.cashflow['Past'].cash_asset_currency.data[0].data
                ) {
                  data.stats.sumOfPastDistributions_in_asset_currency = data.cashflow['Past'].cash_asset_currency.data[1].data.reduce((a, b) => a + b, 0)
                }
              }


            }
            data.stats.sumOfPastCashFlows_in_asset_currency = data.stats.sumOfPastCalls_in_asset_currency + data.stats.sumOfPastDistributions_in_asset_currency
            data.stats.sumOfCalls_in_asset_currency = data.stats.sumOfPastCalls_in_asset_currency + data.stats.sumOfFutureCalls_in_asset_currency
            data.stats.sumOfDistributions_in_asset_currency = data.stats.sumOfPastDistributions_in_asset_currency + data.stats.sumOfFutureDistributions_in_asset_currency
            data.stats.sumOfCashFlows_in_asset_currency = data.stats.sumOfCalls_in_asset_currency + data.stats.sumOfDistributions_in_asset_currency



          }

          setData(data);
          setLoading(false)

        }


      } catch (error) {
        console.log(error);
      }
      //console.log('data', data)
    }
    fetchData();
  }, [id, lastUpdate, params]);


  if (loading) {
    return (
      <React.Fragment>
        <div className="page-content">
        <Loading/>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>

        <div className="page-content">
          <Container fluid={true}>

            <Row style={{ marginBottom: '1em' }}>

              <Col sm={12} md={4} xs={9}>
                <h1 >{DATA.summary.asset_name}</h1>
              </Col>
              {(!portraitMode ?
                <Col sm={12} md={7}>
                  <div className="badge bg-dark" style={{ textAlign: 'center', marginLeft: '0.5em', minWidth: '7em', maxWidth: '14em', fontSize: '1.5em', marginBottom: '0.4em', }}>
                    <p style={{ maxHeight: '0.5em', fontSize: '0.6em', lineHeight: '0.4em', margin: '0.2em' }} >Account</p>
                    <p style={{ marginBottom: '0.1em', color: 'white', fontSize: '1.5em' }}>{DATA.summary.position_account_name}</p>
                  </div>
                  <div className="badge bg-dark" style={{ textAlign: 'center', marginLeft: '0.5em', width: '7em', fontSize: '1.5em', marginBottom: '0.4em', }}>
                    <p style={{ maxHeight: '0.5em', fontSize: '0.6em', lineHeight: '0.4em', margin: '0.2em' }} >Vintage</p>
                    <p style={{ marginBottom: '0.1em', color: 'white', fontSize: '1.5em' }}>{DATA.summary.asset_inception_year}</p>
                  </div>
                  <div className="badge bg-dark" style={{ textAlign: 'center', marginLeft: '0.5em', width: '7em', fontSize: '1.5em', marginBottom: '0.4em', }}>
                    <p style={{ maxHeight: '0.5em', fontSize: '0.6em', lineHeight: '0.4em', margin: '0.2em' }} >DPI {(userCurrency !== DATA.summary.asset_currency ? '(' + userCurrency + ')' : '')}</p>
                    <p style={{ marginBottom: '0.1em', color: 'white', fontSize: '1.5em', color: (DATA.summary.position_dpi_in_user_currency > 0 ? MoondanceLayout.colorScheme.actual_green : MoondanceLayout.colorScheme.actual_red) }}>{MoondanceLayout.formatNumber(DATA.summary.position_dpi_in_user_currency)}x</p>
                  </div>
                  <div className="badge bg-dark" style={{ textAlign: 'center', marginLeft: '0.5em', width: '7em', fontSize: '1.5em', marginBottom: '0.4em', }}>
                    <p style={{ maxHeight: '0.5em', fontSize: '0.6em', lineHeight: '0.4em', margin: '0.2em' }} >TVPI {(userCurrency !== DATA.summary.asset_currency ? '(' + userCurrency + ')' : '')}</p>
                    <p style={{ marginBottom: '0.1em', color: 'white', fontSize: '1.5em', color: (DATA.summary.position_tvpi_in_user_currency > 1 ? MoondanceLayout.colorScheme.actual_green : MoondanceLayout.colorScheme.actual_red) }}>
                      {MoondanceLayout.formatNumber(DATA.summary.position_tvpi_in_user_currency)}x</p>
                  </div>
                  <div className="badge bg-dark" style={{ textAlign: 'center', marginLeft: '0.5em', width: '7em', fontSize: '1.5em', marginBottom: '0.4em', }}>
                    <p style={{ maxHeight: '0.5em', fontSize: '0.6em', lineHeight: '0.4em', margin: '0.2em' }} >IRR {(userCurrency !== DATA.summary.asset_currency ? '(' + userCurrency + ')' : '')}</p>
                    <p style={{ marginBottom: '0.1em', color: 'white', fontSize: '1.5em', color: (DATA.summary.position_irr_in_user_currency > 0 ? MoondanceLayout.colorScheme.actual_green : MoondanceLayout.colorScheme.actual_red) }}>{MoondanceLayout.formatPercentage(DATA.summary.position_irr_in_user_currency)}</p>
                  </div>


                </Col>
                : '')}


              {((DATA.summary.user_can_update_nav === 1 || DATA.summary.user_can_add_transaction === 1 || DATA.summary.user_can_edit_position === 1) ?

                <Col sm={12} md={1} xs={2}>

                  <Dropdown
                    isOpen={actionDropDown}
                    toggle={() => set_actionDropDown(!actionDropDown)}
                  >
                    <DropdownToggle className="btn btn-outline-primary" caret={true}>
                      Action <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>



                    <DropdownMenu>

                      {(DATA.summary.user_can_update_nav === 1 ?
                        <DropdownItem onClick={() => { toggleNavModal(true); }}>
                          <Link >Update NAV</Link>
                        </DropdownItem>
                        : '')}

                      {(DATA.summary.user_can_add_transaction === 1 ?
                        <DropdownItem onClick={() => { navigate('/addTransaction/' + id) }}>
                          <Link >Add Transaction</Link>
                        </DropdownItem>
                        : '')}
                      {(DATA.summary.user_can_edit_position === 1 ?
                        <>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => { navigate('/duplicatePosition/' + id) }}>
                            <Link >Duplicate</Link>
                          </DropdownItem>
                          {(!portraitMode &&
                          <DropdownItem onClick={() => { navigate('/editPosition/' + id) }}>
                            <Link>Edit</Link>
                          </DropdownItem>
                          )}
                        </> : '')}

                    </DropdownMenu>
                  </Dropdown>

                </Col> : '')}
            </Row>
            {(!portraitMode && DATA.stats.sumOfFutureDistributions - DATA.stats.sumOfFutureCalls < DATA.summary.position_nav_in_user_currency_current_rate && DATA.summary.user_can_edit_position === 1?

              MoondanceLayout.alertBlock('Health Check : Future cash flows are valued at less than the current Net Asset Value (NAV) !',
                <>
                  <p>The valuation of future cash flows at less than the current Net Asset Value (NAV) may suggest that either the future cash flows are underestimated or the current NAV is inaccurately reported.</p>
                  <p>Last Net Asset Value :<b>{MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_asset_currency, DATA.summary.asset_currency)}</b></p>
                  <p>Expected future cash flows   :<b>{MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureDistributions - DATA.stats.sumOfFutureCalls, DATA.summary.asset_currency)}</b></p>
                  <p>Verify the latest recorded NAV or reevaluate the expected future cash flows for this position.</p>
                  <br />
                  <Button className="btn btn-sm btn-info " onClick={() => { navigate('/editModel/' + id); }}>Edit Model</Button>&nbsp;
                  <Button className="btn btn-sm btn-info" onClick={() => { toggleNavModal(true) }}>Update NAV</Button>
                </>, "info")
              : '')}

            {(!portraitMode && DATA && DATA.modelCheck && DATA.modelCheck.spread > 0.1 && DATA.summary.user_can_edit_position === 1 ?

              MoondanceLayout.alertBlock(
                'Model is underestimating calls',
                <>
                  <p>The expected capital calls for this position in the current and following years appear to be lower than the remaining expected capital calls needed to reach the anticipated drawdown for this position. <b>This discrepancy suggests a potential underestimation of future capital calls.</b>
                    <br /> <br />Another possibility is that the expected capital calls for this position have not been updated to reflect new information. For instance, there might be a revised drawdown target for the position that hasn't been accurately reflected in the position details.
                    To address this issue, you can either adjust the model parameters or update the expected capital calls in the position details accordingly. Doing so will help align the forecasts with the latest information and targets, thereby eliminating this warning.</p>
                  <Button
                    className="btn btn-outline-info"
                    onClick={() => { navigate('/editPosition/' + id); }}
                  >
                    <i className="fas fa-edit"></i>{" "}
                    Edit Model
                  </Button>
                </>
                , 'info')


              : '')}



            {(!portraitMode && DATA && DATA.modelCheck && DATA.modelCheck.spread < -0.1  && DATA.summary.user_can_edit_position === 1 ?

              MoondanceLayout.alertBlock(
                'Model is overestimating calls',
                <>
                  <p>The expected capital calls for this position in the current and following years exceed the model's projected remaining capital calls necessary to achieve the anticipated drawdown for this position.<b>This indicates a potential overestimation of future capital calls.</b><br />
                    <br /> It's possible that the expected capital calls for this position have been updated based on new information, leading to a higher forecast than what the model predicts.
                    <br />For instance, there may have been changes in the position's strategy or market conditions that are not yet fully accounted for in the model.
                    <br /><br />To resolve this discrepancy, consider reviewing and potentially recalibrating the model to ensure it accurately reflects the latest data and market insights.
                    <br />Alternatively, if the updated expected capital calls are based on reliable and recent information, you might need to reassess the model's assumptions or inputs. Adjusting the model to align with the current expectations will help ensure more accurate and realistic forecasting, thereby mitigating the risk of overestimating future capital requirements.</p>
                  
                  <Button
                    className="btn btn-outline-info"
                    onClick={() => { navigate('/editPosition/' + id); }}
                  >
                    <i className="fas fa-edit"></i>{" "}
                    Edit Model
                  </Button>
                </>
                , 'info')


              : '')}


            <Row>
              <Nav tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={(activeTab === '1' ? "active nav-link " : "")}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    <i className="mdi mdi-account-cash"> </i>{" "}
                    Position
                  </NavLink>
                </NavItem>


                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}

                    className={(activeTab === '3' ? "active nav-link " : "")}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    <i className="fas fa-money-check-alt"></i>{" "}
                    Cash Flows
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}

                    className={(activeTab === '4' ? "active nav-link " : "")}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    <i className="mdi mdi-cash-multiple"></i>{" "}
                    Transactions
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}

                    className={(activeTab === '5' ? "active nav-link " : "")}
                    onClick={() => {
                      toggle("5");
                    }}
                  >
                    <i className="mdi mdi-gold"></i>{" "}
                    NAV
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}

                    className={(activeTab === '6' ? "active nav-link " : "")}
                    onClick={() => {
                      toggle("6");
                    }}
                  >
                    <i className="mdi mdi-gold"></i>{" "}
                    IRR
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}

                    className={(activeTab === 'notes' ? "active nav-link " : "")}
                    onClick={() => {
                      toggle("notes");
                    }}
                  >
                    <i className=" bx bx-notepad"></i>{" "}Notes
                  </NavLink>
                </NavItem>
                {(!portraitMode && DATA.summary.user_can_edit_position === 1 ?
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={(activeTab === '7' ? "active nav-link " : "")}

                      onClick={() => {
                        navigate('/editPosition/' + id);
                      }}
                    >
                      <i className="fas fa-edit"></i>{" "}
                      Edit
                    </NavLink>
                  </NavItem> : '')}
              </Nav>

            </Row>

            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1" id="Position">





                < Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>

                  <h3 style={{ flex: 8 }}>{DATA.summary.asset_name}  (Account: {DATA.summary.position_account_name})</h3>

                </Row>
                <Row id="detail_left_col">
                  <Col xl={6} sm={12} md={12}>
                    <Row>
                      {MoondanceLayout.card(
                        {
                          title: 'IRR',
                          value:
                            (userCurrency !== DATA.summary.asset_currency ?
                              userCurrency + ' : ' : ''
                            ) + MoondanceLayout.formatPercentage(DATA.summary.position_irr_in_user_currency, 4),
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? DATA.summary.asset_currency + ' : ' + MoondanceLayout.formatPercentage(DATA.summary.position_irr_in_asset_currency, 4)
                              : null
                          ),
                          subText: 'Internal Rate of Return.',
                          xlWidth: 4,
                          smWidth: 4,
                          mdWidth: 4,
                          xsWidth: 12
                        }
                      )}
                      {MoondanceLayout.card(
                        {
                          title: 'DPI',
                          value:
                            (userCurrency !== DATA.summary.asset_currency ?
                              userCurrency + ' : ' : ''
                            ) + MoondanceLayout.formatNumber(DATA.summary.position_dpi_in_user_currency, 2) + 'x',
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? DATA.summary.asset_currency + ' : ' + MoondanceLayout.formatNumber(DATA.summary.position_dpi_in_asset_currency, 2) + 'x'
                              : null
                          ),
                          subText: 'Distributions to Paid-In Capital',
                          xlWidth: 4,
                          smWidth: 4,
                          mdWidth: 4

                        }
                      )}

                      {MoondanceLayout.card(
                        {
                          title: 'TVPI',
                          value:
                            (userCurrency !== DATA.summary.asset_currency ?
                              userCurrency + ' : ' : ''
                            ) + MoondanceLayout.formatNumber(DATA.summary.position_tvpi_in_user_currency, 4) + 'x',
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? DATA.summary.asset_currency + ' : ' + MoondanceLayout.formatNumber(DATA.summary.position_tvpi_in_asset_currency, 4) + 'x'
                              : null
                          ),
                          subText: 'Total Value to Paid-In Capital',
                          xlWidth: 4,
                          smWidth: 4,
                          mdWidth: 4
                        }
                      )}

                    </Row>
                    <Row>
                      {MoondanceLayout.card(
                        {
                          title: 'Commitment',
                          value: MoondanceLayout.formatCurrency(DATA.summary.position_commitment_in_user_currency, userCurrency),
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? MoondanceLayout.formatCurrency(DATA.summary.position_commitment_in_asset_currency, DATA.summary.asset_currency)
                              : null
                          ),
                          subText: 'End of invest. period : ' + (DATA.summary.asset_inception_year + DATA.summary.asset_investment_period),


                          xlWidth: 4,
                          smWidth: 4,
                          xsWidth: 12
                        }
                      )}
                      {MoondanceLayout.card(
                        {
                          title: 'Called',
                          value: MoondanceLayout.formatCurrency(-DATA.summary.position_total_capital_call_in_user_currency, userCurrency),
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? MoondanceLayout.formatCurrency(-DATA.summary.position_total_capital_call_in_asset_currency, DATA.summary.asset_currency)
                              : null
                          ),
                          subText: '% called: ' + MoondanceLayout.formatPercentage(-DATA.summary.position_total_capital_call_in_asset_currency / DATA.summary.position_commitment_in_asset_currency, 2),

                          xlWidth: 4,
                          smWidth: 4,
                          xsWidth: 6
                        }
                      )}
                      {MoondanceLayout.card(
                        {
                          title: 'Expected Callable',

                          value:

                            <span className={
                              (
                                DATA.summary.position_expected_remaining_callable_capital_in_user_currency > 0
                                  ? "badge bg-danger me-1"
                                  : (DATA.summary.position_expected_remaining_callable_capital_in_user_currency < 0
                                    ? "badge bg-success me-1"
                                    : ''
                                  )
                              )
                            } >{MoondanceLayout.formatCurrency(DATA.summary.position_expected_remaining_callable_capital_in_user_currency, userCurrency)}</span>,

                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? MoondanceLayout.formatCurrency(DATA.summary.position_expected_remaining_callable_capital_in_asset_currency, DATA.summary.asset_currency)
                              : null
                          ),
                          subText: <>Expected Drawdown : {formatPercentage(DATA.summary.asset_expected_drawdown)}{(DATA.summary.asset_expected_drawdown < 1 ? <><br /><span>Total callable :{formatCurrency(DATA.summary.position_remaining_callable_capital_in_user_currency, userCurrency)}</span></> : '')}</>,


                          xlWidth: 4,
                          smWidth: 4,
                          xsWidth: 6
                        }
                      )}
                      {MoondanceLayout.card(
                        {
                          title: 'Distributed',
                          value: MoondanceLayout.formatCurrency(DATA.summary.position_total_distribution_in_user_currency, userCurrency),
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? MoondanceLayout.formatCurrency(DATA.summary.position_total_distribution_in_asset_currency, DATA.summary.asset_currency)
                              : null
                          ),

                          xlWidth: 4,
                          smWidth: 4,
                          xsWidth: 6
                        }
                      )}
                      {MoondanceLayout.card(
                        {
                          title: 'Nav',
                          value: MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_user_currency_current_rate, userCurrency),
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ?
                              MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_asset_currency, DATA.summary.asset_currency)
                              : null
                          ),
                          subText: DATA.summary.asset_currency + ' ' + DATA.summary.position_nav_date,

                          xlWidth: 4,
                          smWidth: 4,
                        }
                      )}
                      {MoondanceLayout.card(
                        {
                          title: 'Gain & Losses',
                          value: (DATA.summary.position_total_gain_loss_in_user_currency < 0 ?
                            <span className="badge bg-danger me-1">{formatCurrency(DATA.summary.position_total_gain_loss_in_user_currency, userCurrency)}</span>
                            :
                            <span className="badge bg-primary me-1">{formatCurrency(DATA.summary.position_total_gain_loss_in_user_currency, userCurrency)}</span>
                          ),


                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ? MoondanceLayout.formatCurrency(DATA.summary.position_total_gain_loss_in_asset_currency, DATA.summary.asset_currency)
                              : null
                          ),

                          xlWidth: 4,
                          smWidth: 4,
                          xsWidth: 12
                        }
                      )}
                    </Row>


                    <Row>
                      {MoondanceLayout.card(
                        {
                          title: 'Shares',
                          value: MoondanceLayout.formatNumber(DATA.summary.position_shares),

                          subText: "Number of shares",
                          xlWidth: 4,
                          smWidth: 12,
                        }
                      )}
                      {MoondanceLayout.card(
                        {
                          title: 'Value per share',
                          value: MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_user_currency / DATA.summary.position_shares, userCurrency, 2),
                          value_sub: (
                            userCurrency !== DATA.summary.asset_currency
                              ?
                              (DATA.summary.position_nav_in_asset_currency > 0 ? MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_asset_currency / DATA.summary.position_shares, DATA.summary.asset_currency, 2) : '')
                              : null
                          ),
                          xlWidth: 4,
                          smWidth: 12,
                        }
                      )}


                    </Row>
                  </Col>
                  <Col xl={6} sm={12} md={12}>
                    <Card >
                      <CardBody>
                        <CardTitle className="mb-4">Overview</CardTitle>
                        <WaterFallChart
                          title="Overview"
                          data={DATA.overviewChart.data}
                          options={DATA.overviewChart.options}

                          currency={DATA.summary.asset_currency}
                          height={480}

                        />

                      </CardBody>
                    </Card>
                  </Col>

                </Row>


                <Row>
                  <Col xl={12} sm={12}>

                    <Card >
                      <CardBody>
                        <CardTitle className="mb-4">Remarks:</CardTitle>


                        <p> Gains, losses, and multiples are calculated based on the most recent available net asset value and transactions.</p>
                        {(
                          userCurrency !== DATA.summary.asset_currency ?
                            <p>Amounts in different currencies are converted at the latest available exchange rate, except for transactions that are converted at their historical exchange rate.</p>
                            : ''
                        )}</CardBody>
                    </Card>
                  </Col>
                </Row>

                <h5>Asset Details</h5>

                <Row >


                  <Col xl={12} sm={12} >
                    <Card>

                      <CardBody>
                        <CardTitle>{DATA.summary.asset_name}</CardTitle>
                        <Row>
                          {MoondanceLayout.insideItem(
                            {
                              title: 'Currency',
                              value: DATA.summary.asset_currency,

                              xlWidth: 2,
                              smWidth: 2,

                            }
                          )}
                          {MoondanceLayout.insideItem(
                            {
                              title: 'Inception Year',
                              value: DATA.summary.asset_inception_year,

                              xlWidth: 2,
                              smWidth: 2,
                            }
                          )}


                          {MoondanceLayout.insideItem(
                            {
                              title: 'Intermediate',
                              value: DATA.summary.asset_intermediate,

                              xlWidth: 2,
                              smWidth: 2,
                            }
                          )}
                          {MoondanceLayout.insideItem(
                            {
                              title: 'Feeder',
                              value: DATA.summary.asset_feeder_name,
                              value_sub: DATA.summary.asset_feeder_type,

                              xlWidth: 4,
                              smWidth: 4,
                            }
                          )}

                          {MoondanceLayout.insideItem(
                            {
                              title: 'Status',
                              value: (MoondanceLayout.statusList[DATA.summary.position_status].label ? MoondanceLayout.statusList[DATA.summary.position_status].label : DATA.summary.position_status),

                              xlWidth: 2,
                              smWidth: 2,
                            }
                          )}



                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  {(DATA.summary.asset_description.length > 0 && DATA.summary.asset_description != '' ?
                    MoondanceLayout.card(
                      {
                        cardBody: <><CardTitle>Description</CardTitle><p>{DATA.summary.asset_description}</p></>,
                        xlWidth: 12,
                        smWidth: 12,
                        xsWidth: 12
                      }) : ''
                  )}



                </Row>
                <Row>
                  {(Array.isArray(DATA.types) && DATA.types.length > 0) ? (
                    <Col xl={12} sm={12}>

                      <Card >
                        <CardBody>

                          <CardTitle>Types :</CardTitle>

                          <Row>
                            <Col xl={6} sm={6}>
                              {DATA.types.map((row, index) => {
                                return (
                                  <p className="mb-3" key={index}>
                                    {(row.id && MoondanceLayout.assetType[row.id] && MoondanceLayout.assetType[row.id] && MoondanceLayout.assetType[row.id].label) ?
                                      MoondanceLayout.assetType[row.id].label
                                      : row.id
                                    }
                                    <span>: {formatPercentage(row.percentage / 100)}</span>
                                  </p>)
                              })}
                            </Col>

                          </Row>

                        </CardBody>
                      </Card>

                    </Col>
                  ) : null}
                  {(Array.isArray(DATA.geographies) && DATA.geographies.length > 0) ? (
                    <Col xl={12} sm={12}>

                      <Card >
                        <CardBody>

                          <CardTitle>Geographies :</CardTitle>

                          <Row>
                            <Col xl={6} sm={6}>
                              {DATA.geographies.map((row, index) => {
                                return (
                                  <p className="mb-3" key={index}>
                                    {(geographyList.find(option => option.id === row.id) ?
                                      (geographyList.find(option => option.id === row.id).label
                                        ? geographyList.find(option => option.id === row.id).label
                                        : 'id'
                                      )
                                      : 'id'
                                    )}
                                    <span>: {formatPercentage(row.percentage / 100)}</span>
                                  </p>)
                              })}
                            </Col>

                          </Row>

                        </CardBody>
                      </Card>

                    </Col>
                  ) : null}



                  <Col xl={12} sm={12}>
                    <Card>
                      <CardBody>
                        <CardTitle>Sectors :</CardTitle>

                        {(Array.isArray(DATA.sectors) && DATA.sectors.length > 0)
                          ? (DATA.sectors.map((row, index) => {
                            return (
                              <p className="mb-3" key={index}>
                                {((sectorList.find(option => option.id === row.id) && sectorList.find(option => option.id === row.id).label) ? sectorList.find(option => option.id === row.id).label : row.id)}: {formatPercentage(row.percentage / 100)}
                              </p>
                            );
                          })
                          ) :
                          <p className="mb-3" >Global : 100%</p>
                        }
                      </CardBody>
                    </Card>

                  </Col>

                </Row>

              </TabPane>

              <TabPane tabId="3" id="Model">
                <Row>
                  <PositionYtd id={id} data={DATA} />



                  {(!portraitMode &&
                    <Col xl={12} sm={12}>
                      <Card >
                        <CardBody>
                          <CardTitle>Model Hypothesis</CardTitle>
                          <Row><p>
                            <b><span className="mdi mdi-alert-outline text-danger"></span> Please be aware that these projections are based on hypothetical models.</b><br />
                            They do not guarantee that future amounts will align with these models.<br />
                            The expected cashflow is derived from the following assumptions provided during the creation or modification of this asset.<br />
                          </p>
                            <br />
                            <Row>
                              {MoondanceLayout.insideItem(
                                {
                                  title: 'Investment Period',
                                  value: DATA.summary.asset_investment_period + ' years',
                                  xlWidth: 2,
                                  smWidth: 2,

                                }
                              )}
                              {MoondanceLayout.insideItem(
                                {
                                  title: 'Detention Period',
                                  value: DATA.summary.asset_detention_period + ' years',
                                  xlWidth: 2,
                                  smWidth: 2,

                                }
                              )}
                              {MoondanceLayout.insideItem(
                                {
                                  title: 'Expected Drawdown',
                                  value: formatPercentage(DATA.summary.asset_expected_drawdown),
                                  xlWidth: 2,
                                  smWidth: 2,

                                }
                              )}
                              {MoondanceLayout.insideItem(
                                {
                                  title: 'Expected IRR',
                                  value: formatPercentage(DATA.summary.asset_expected_irr),
                                  xlWidth: 2,
                                  smWidth: 2,

                                }
                              )}
                              {MoondanceLayout.insideItem(
                                {
                                  title: 'Expected TVPI',
                                  value: formatNumber(DATA.summary.asset_expected_tvpi, 2) + 'x',
                                  xlWidth: 2,
                                  smWidth: 2,

                                }
                              )}
                            </Row>
                            <p>
                              <br />
                              Capital calls and distributions can be manually adjusted by editing this asset.<br />Future distributions are forecasted using actual capital calls and the hypotheses outlined above, or they can be manually set during the asset's modification.
                            </p>
                          </Row>

                        </CardBody>
                      </Card>
                    </Col>
                  )}



                  {DATA.cashflow && !portraitMode && (
                    <Col xl={12} sm={12}>
                      <Card >
                        <CardBody>
                          <CardTitle className="mb-4E d-flex justify-content-between align-items-center">
                            <div>
                              {cashflowChartDisplay === 'Future' && 'Expected Cashflow'}
                              {cashflowChartDisplay === 'Past' && 'Actual Cashflow'}
                              {cashflowChartDisplay === 'All' && <p>Actual and Expected Cashflow<br /><small>Current year mix actual and expected cashflows</small></p>}

                              <Dropdown
                                isOpen={model_scale_dropdown_1}
                                toggle={() => set_model_scale_dropdown_1(!model_scale_dropdown_1)}
                              >
                                <DropdownToggle className="btn btn-link" caret={true}>
                                  Y axis : {model_scale_options.find(y => y.id === model_scale).label}
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  {model_scale_options.length > 0 && model_scale_options.map((item, index) => (
                                    <DropdownItem key={item.id} onClick={() => { set_model_scale(item.id) }} ><Link>{item.label}</Link></DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                            <div
                              className="btn-group"
                              role="group"
                            >
                              <Button
                                type="Button"
                                className={cashflowChartDisplay === 'Past' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}

                                onClick={() => setCashflowChartDisplay('Past')} >Past</Button>
                              <Button
                                type="Button"
                                className={cashflowChartDisplay === 'Future' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                onClick={() => setCashflowChartDisplay('Future')}>Future</Button>

                              <Button
                                type="Button"
                                className={cashflowChartDisplay === 'All' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                onClick={() => setCashflowChartDisplay('All')}>All</Button>
                            </div>
                          </CardTitle>

                          <BarChart

                            chartKey={`cashflow-${cashflowChartDisplay}-${model_scale}-${DATA.summary.position_id}`}
                            title="Expected Cashflow"
                            data={DATA.cashflow[cashflowChartDisplay][model_scale].data}
                            options={DATA.cashflow[cashflowChartDisplay][model_scale].options}
                            stacked={true}
                            currency={(model_scale === 'cash' ? userCurrency : DATA.summary.asset_currency)}
                            scale={model_scale}

                          />
                          <Row>
                            <Col xl={12} sm={12}>
                              <table className="table table-condensed">
                                <thead>
                                  <tr style={{ textAlign: 'right' }}>
                                    <th></th>
                                    <th>Capital Calls</th>
                                    <th>Distribution</th>
                                    <th>Cash Flow</th>
                                    <th>NAV</th>
                                    <th>Gain</th>
                                    <th>TVPI</th>


                                  </tr>
                                </thead>


                                {(model_scale === 'cash' ?
                                  <tbody>
                                    <tr style={{ textAlign: 'right' }}>
                                      <td>Actual (Past)</td>
                                      <td >{MoondanceLayout.formatCurrency(DATA.stats.sumOfPastCalls, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.danger }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfPastDistributions, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.success }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfPastCashFlows, userCurrency)}</td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_user_currency_current_rate, userCurrency)}</td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_user_currency_current_rate + DATA.stats.sumOfPastDistributions + DATA.stats.sumOfPastCalls, userCurrency)}</td>
                                      <td> {MoondanceLayout.formatNumber((DATA.summary.position_nav_in_user_currency_current_rate + DATA.stats.sumOfPastDistributions) / -DATA.stats.sumOfPastCalls, 2)}x</td>

                                    </tr>
                                    <tr style={{ textAlign: 'right' }}>
                                      <td>Expected (Future)</td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureCalls, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureDistributions, userCurrency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureCashFlows, userCurrency)}</td>
                                      <td><i>Fully distributed</i></td>

                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureDistributions - DATA.summary.position_nav_in_user_currency_current_rate + DATA.stats.sumOfFutureCalls, userCurrency)}</td>
                                      <td> {MoondanceLayout.formatNumber((DATA.stats.sumOfFutureDistributions) / (DATA.summary.position_nav_in_user_currency_current_rate - DATA.stats.sumOfFutureCalls), 2)}x</td>
                                    </tr>


                                    <tr style={{ textAlign: 'right' }}>
                                      <td>All</td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfCalls, userCurrency)}<span style={{ width: '4em', }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfDistributions, userCurrency)}<span style={{ width: '4em', }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfCashFlows, userCurrency)}</td>
                                      <td><i>Fully distributed</i></td>

                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfDistributions + DATA.stats.sumOfCalls, userCurrency)}</td>
                                      <td>{MoondanceLayout.formatNumber((DATA.stats.sumOfDistributions) / -DATA.stats.sumOfCalls, 2)}x</td>
                                    </tr>
                                  </tbody> :
                                  <tbody>
                                    <tr style={{ textAlign: 'right' }}>
                                      <td>Actual (Past)</td>
                                      <td >{MoondanceLayout.formatCurrency(DATA.stats.sumOfPastCalls_in_asset_currency, DATA.summary.asset_currency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.danger }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfPastDistributions_in_asset_currency, DATA.summary.asset_currency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.success }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfPastCashFlows_in_asset_currency, DATA.summary.asset_currency)}</td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_asset_currency, DATA.summary.asset_currency)}</td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.summary.position_nav_in_asset_currency + DATA.stats.sumOfPastDistributions_in_asset_currency + DATA.stats.sumOfPastCalls_in_asset_currency, DATA.summary.asset_currency)}</td>
                                      <td> {MoondanceLayout.formatNumber((DATA.summary.position_nav_in_asset_currency + DATA.stats.sumOfPastDistributions_in_asset_currency) / -DATA.stats.sumOfPastCalls_in_asset_currency, 2)}x</td>

                                    </tr>
                                    <tr style={{ textAlign: 'right' }}>
                                      <td>Expected (Future)</td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureCalls_in_asset_currency, DATA.summary.asset_currency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureDistributions_in_asset_currency, DATA.summary.asset_currency)} <span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureCashFlows_in_asset_currency, DATA.summary.asset_currency)}</td>
                                      <td><i>Fully distributed</i></td>

                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfFutureDistributions_in_asset_currency - DATA.summary.position_nav_in_asset_currency + DATA.stats.sumOfFutureCalls_in_asset_currency, DATA.summary.asset_currency)}</td>
                                      <td> {MoondanceLayout.formatNumber((DATA.stats.sumOfFutureDistributions_in_asset_currency) / (DATA.summary.position_nav_in_asset_currency - DATA.stats.sumOfFutureCalls_in_asset_currency), 2)}x</td>
                                    </tr>


                                    <tr style={{ textAlign: 'right' }}>
                                      <td>All</td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfCalls_in_asset_currency, DATA.summary.asset_currency)}<span style={{ width: '4em', }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfDistributions_in_asset_currency, DATA.summary.asset_currency)}<span style={{ width: '4em', }}>&nbsp;&nbsp;&nbsp;</span></td>
                                      <td>{MoondanceLayout.formatCurrency(DATA.stats.sumOfCashFlows_in_asset_currency, DATA.summary.asset_currency)}</td>
                                      <td><i>Fully distributed</i></td>

                                      <td> {MoondanceLayout.formatCurrency(DATA.stats.sumOfDistributions_in_asset_currency + DATA.stats.sumOfCalls_in_asset_currency, DATA.summary.asset_currency)}</td>
                                      <td>{MoondanceLayout.formatNumber((DATA.stats.sumOfDistributions_in_asset_currency) / -DATA.stats.sumOfCalls_in_asset_currency, 2)}x</td>
                                    </tr>
                                  </tbody>

                                )}
                              </table>



                            </Col>
                          </Row>
                          <Row>


                          </Row>
                        </CardBody>
                      </Card>

                    </Col>
                  )}
                  {DATA.cumulatedCashflow && !portraitMode && (
                    <Col xl={12} sm={12}>
                      <Card >
                        <CardBody>
                          <CardTitle className="mb-4E d-flex justify-content-between align-items-center">

                            <div>
                              {cashflowChartDisplay === 'Future' && 'Expected Cumulated Cashflow'}
                              {cashflowChartDisplay === 'Past' && 'Actual Cumulated Cashflow '}
                              {cashflowChartDisplay === 'All' && <p>Actual and Expected Cumulated Cashflow <br /><small>Current year mix actual and expected cashflows</small></p>}
                              <Dropdown
                                isOpen={model_scale_dropdown_2}
                                toggle={() => set_model_scale_dropdown_2(!model_scale_dropdown_2)}
                              >
                                <DropdownToggle className="btn btn-link" caret={true}>
                                  Y axis : {model_scale_options.find(y => y.id === model_scale).label}
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  {model_scale_options.length > 0 && model_scale_options.map((item, index) => (
                                    <DropdownItem key={item.id} onClick={() => { set_model_scale(item.id) }} ><Link>{item.label}</Link></DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Basic mixed styles example"
                            >
                              <button
                                type="button"
                                className={cashflowChartDisplay === 'Past' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}

                                onClick={() => setCashflowChartDisplay('Past')} >Past</button>
                              <button
                                type="button"
                                className={cashflowChartDisplay === 'Future' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                onClick={() => setCashflowChartDisplay('Future')}>Future</button>

                              <button
                                type="button"
                                className={cashflowChartDisplay === 'All' ? 'btn btn-primary btn-sm' : 'btn btn-outline-primary btn-sm'}
                                onClick={() => setCashflowChartDisplay('All')}>All</button>
                            </div>
                          </CardTitle>

                          <BarChart
                            chartKey={`cumulatedcashflow-${cashflowChartDisplay}-${model_scale}-${DATA.summary.position_id}`}
                            title="Expected Cumulated Cashflow"
                            data={DATA.cumulatedCashflow[cashflowChartDisplay][model_scale].data}
                            options={DATA.cumulatedCashflow[cashflowChartDisplay][model_scale].options}
                            stacked={true}

                            currency={(model_scale === 'cash' ? userCurrency : DATA.summary.asset_currency)}
                            scale={model_scale}

                          />
                          <Row>
                            <Col xl={4} sm={4}>
                              <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.dark }}>&nbsp;&nbsp;&nbsp;</span> Actual incremental cash flow</p>
                            </Col>
                            <Col xl={4} sm={4}>
                              <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span> Expected incremental cash flow</p>
                            </Col>
                            <Col xl={4} sm={4}>
                              <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.success }}>&nbsp;&nbsp;&nbsp;</span> Cumulative Cashflow (Actual + Expected)</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {DATA.perYear && !portraitMode && (
                    <Col xl={12} sm={12}>
                      <Card>
                        <CardBody>

                          {(DATA.perYear && DATA.perYear.length > 0 ? (
                            <MoondanceLayout.MoondanceTable
                              rows={DATA.perYear}
                              defaultSort={{ key: 'year', direction: 'ascending' }}
                              columns={[
                                { label: 'Year', field: 'year', style: { borderRight: '1px dashed black' } },
                                { label: 'New Position', field: 'count_new_positions', format: 'number', align: 'right', showTotal: true },
                                { label: 'New Commitment', field: 'sum_new_commitment_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true, style: { borderRight: '1px dashed black' } },
                                { label: 'Actual Capital call', field: 'sum_real_capital_call_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                { label: 'Actual Distribution', field: 'sum_real_distribution_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                                { label: 'Actual Balance', field: 'sum_real_balance_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true, style: { borderRight: '1px dashed black' } },
                                { label: 'Expected Remaining Call', field: 'sum_remaining_capital_call_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true, },
                                { label: 'Expected Remaining Distribution', field: 'sum_remaining_distribution_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true, style: { borderRight: '1px dashed black' } },
                                { label: 'Expected Balance', field: 'sum_remaining_balance_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },



                              ]}


                            />
                          ) : < p>No Position found</p>
                          )}


                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>

              </TabPane>

              <TabPane tabId="4" id="Transactions">
                <Row>
                  <Col xl={12} sm={12}>
                    <div className="flex">

                      <h2>Transactions <button className="btn btn-sm btn-outline-primary"onClick={() => { navigate('/addTransaction/' + id) }}>Add Transaction</button></h2>


                    </div>
                  </Col>

                  <Col xl={12} sm={12}>
                    <AddTransactionShortForm
                      data={DATA}
                      show={showAddTransactionForm}
                      showOpenButton={false}
                    />
                  </Col>


                  {(!portraitMode && DATA.transactions && DATA.transactions.length > 0 ? (
                    <Col xl={8} sm={12}>
                      <Card>
                        <CardBody>
                          <TransactionsGraph data={DATA.transactions} />
                        </CardBody>
                      </Card>
                    </Col>
                  ) : '')}
                  {(DATA.transactionsPerType && DATA.transactionsPerType.length > 0 ?
                    <Col xl={4} sm={12}>
                      <Card>
                        <CardBody>
                          <MoondanceLayout.MoondanceTable
                            key={`table-${DATA.transactions}-${lastUpdate}-pergroup`}
                            title="Transactions per type"
                            rows={DATA.transactionsPerType}
                            columns={[
                              { label: 'Type', field: 'type', format: 'findInArray', formatUnit: MoondanceLayout.transactionType, align: 'left' },
                              { label: 'Value (' + userCurrency + ')', field: 'value_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                            ]}
                          />
                        </CardBody>
                      </Card>
                    </Col> : <></>)}
                  {(!portraitMode &&
                    <Col xl={12} sm={12}>
                      <Card>
                        <CardBody>
                          <CardTitle>List of transactions </CardTitle>

                          {(DATA.transactions && DATA.transactions.length > 0 ? (

                            <MoondanceLayout.MoondanceTable
                              rows={DATA.transactions}
                              defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                              columns={[
                                { label: 'Id', field: 'id' },
                                { label: 'Date', field: 'timestamp', format: 'timestampToDate' },
                                { label: 'type', field: 'type', format: 'findInArray', formatUnit: MoondanceLayout.transactionType, align: 'right' },
                                { label: 'Note', field: 'note', },
                                { label: 'Shares (+/-)', field: 'shares', customFormat: (row) => { return (MoondanceLayout.formatNumber(row['shares'], 2)) }, format: 'number', align: 'right', showTotal: true },
                                { label: 'Value/Share ', field: 'shares', customFormat: (row) => { return (row['shares'] !== 0 ? MoondanceLayout.formatCurrency(-row['value_in_user_currency'] / row['shares'], userCurrency, 2) : '-') }, showTotal: false, format: 'currency', formatUnit: userCurrency, align: 'right' },
                                { label: 'Total shares', field: 'cumulative_shares', customFormat: (row) => { return (MoondanceLayout.formatNumber(row['cumulative_shares'], 2)) }, field: 'value_in_user_currency', align: 'right', showTotal: false, format: 'number', formatUnit: userCurrency, align: 'right' },
                                { label: 'Value (' + userCurrency + ')', customFormat: (row) => { return (MoondanceLayout.formatCurrency(row['value_in_user_currency'], userCurrency, 2)) }, field: 'value_in_user_currency', align: 'right', showTotal: true, format: 'currency', formatUnit: userCurrency, align: 'right' },

                                { label: 'Bank', field: 'bank', align: 'right' },
                                { label: 'Currency', field: 'transaction_currency', align: 'right' },
                                (DATA.summary.asset_currency !== userCurrency ?
                                  { label: 'Value/Share ', field: 'shares', customFormat: (row) => { return (row['shares'] !== 0 ? MoondanceLayout.formatCurrency(-row['value_in_transaction_currency'] / row['shares'], row['transaction_currency'], 2) : '-') }, showTotal: false, format: 'currency', formatUnit: DATA.summary.asset_currency, align: 'right' }
                                  : ''),

                                (DATA.summary.asset_currency !== userCurrency ? { label: 'Value (' + DATA.summary.asset_currency + ')', field: 'value_in_transaction_currency', format: 'currency', formatUnit: DATA.summary.asset_currency, align: 'right', showTotal: true } : ''),
                                (DATA.summary.asset_currency !== userCurrency ? { label: 'Exchange rate', field: 'xrate', format: 'number', formatUnit: 4, align: 'right' } : ''),


                              ]}

                              actions={[{
                                label: 'Edit',
                                condition: 'user_can_edit_transaction',
                                icon: 'fas fa-edit',
                                onClick: (row) => openTransactionModal(row)
                              }]}

                            />
                          ) : "No Transaction found")}

                        </CardBody>
                      </Card>
                    </Col>
                  )}

                  <PortraitAlert />
                </Row>

              </TabPane>
              <TabPane tabId="5" id="NAV">
                <div className="flex">

                  <h2>Net Asset Value <button className="btn btn-sm btn-outline-primary" onClick={() => { toggle("5"); toggleNavModal(true); }}>Update Nav</button></h2>


                </div>

                {( DATA.navHistory && DATA.navHistory.length > 0 ? (
                  <Col xl={12} sm={12}>
                    <Card>
                      <CardBody>
                        <CardTitle>Nav History </CardTitle>
                        <Dropdown
                          isOpen={nav_scale_dropdown}
                          toggle={() => set_nav_scale_dropdown(!nav_scale_dropdown)}
                        >
                          <DropdownToggle className="btn btn-link" caret={true}>
                            Y axis : {nav_scale_options.find(y => y.id === nav_scale).label}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {nav_scale_options.length > 0 && nav_scale_options.map((item, index) => (
                              <DropdownItem key={item.id} onClick={() => { set_nav_scale(item.id) }} ><Link>{item.label}</Link></DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <NavChart data={DATA.navHistory} yscale={nav_scale} currency={userCurrency} />


                      </CardBody>
                    </Card>
                  </Col>

                ) : "No Nav History found")}
                {(!portraitMode &&
                <Col xl={12} sm={12}>
                  <Card>
                    <CardBody> <Col xl={12} sm={12}>

                    </Col>
                      <CardTitle>Nav History </CardTitle>




                      {(DATA.navHistory && DATA.navHistory.length > 0 ? (

                        <MoondanceLayout.MoondanceTable
                          rows={DATA.navHistory}
                          defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                          columns={[
                            { label: 'Id', field: 'id' },
                            { label: 'Date', field: 'timestamp', format: 'timestampToDate' },
                            // { label: 'Shares', format: 'number', field: 'number_of_shares', align: 'right' },

                            { label: 'Shares ', format: 'number', field: 'cumulative_shares', align: 'right' },
                            {
                              label: 'Nav/Shares (' + userCurrency + ')',
                              format: 'number',
                              field: 'cumulative_shares',
                              align: 'right',
                              customFormat: (row) => { return (MoondanceLayout.formatCurrency(row['nav_in_user_currency'] / row['cumulative_shares'], userCurrency, 2)) }, align: 'right'
                            },
                            {
                              label: 'Nav (' + userCurrency + ')', field: 'nav_in_user_currency',
                              field: 'nav_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right',

                            },
                            { label: 'Currency', field: 'asset_currency', align: 'right' },


                            (DATA.summary.asset_currency !== userCurrency ?
                              {
                                label: 'Nav/Shares (' + DATA.summary.asset_currency + ')',
                                format: 'number',
                                field: 'cumulative_shares',
                                align: 'right',
                                customFormat: (row) => { return (MoondanceLayout.formatCurrency(row['nav_in_asset_currency'] / row['cumulative_shares'], row['asset_currency'], 2)) }, align: 'right'
                              }

                              : ''),
                            (DATA.summary.asset_currency !== userCurrency ? {
                              label: 'Nav  (' + DATA.summary.asset_currency + ')',
                              field: 'nav_in_asset_currency',
                              customFormat: (row) => { return (MoondanceLayout.formatCurrency(row['nav_in_asset_currency'], row['asset_currency'])) }, align: 'right'
                            } : ''),


                            (DATA.summary.asset_currency !== userCurrency ? { label: 'Exchange rate', field: 'exchange_rate', format: 'number', formatUnit: 4, align: 'right' } : ''),


                          ]}
                          actions={[{
                            label: 'Edit',
                            icon: 'fas fa-edit',
                            condition: 'user_can_edit_nav',
                            onClick: (row) => editNav(row)
                          }]}

                        />
                      ) : "No Nav History found")}
                    </CardBody>
                  </Card>
                </Col>
              )}
              <PortraitAlert />
              </TabPane>

              <TabPane tabId="6" id="Irr calculation">
               

                  <p>Calculated on the basis of transactions and the latest net asset value provided.</p>
                  <Col xl={3} sm={3}>
                    <Card>
                      <CardBody>
                        <div className="d-flex text-muted">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="mb-1">IRR {(userCurrency !== DATA.summary.asset_currency ? '(' + userCurrency + ')' : '')}</p>
                            <p className="mb-3">
                              {formatPercentage(DATA.summary.position_irr_in_user_currency, 4)}
                            </p>
                            <p className="text-truncate mb-0">
                              {DATA.summary.position_nav_date ? DATA.summary.position_nav_date : ''}
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {(DATA.summary.currency !== userCurrency ?
                    <Col xl={3} sm={3}>
                      <Card>
                        <CardBody>
                          <div className="d-flex text-muted">
                            <div className="flex-grow-1 overflow-hidden">
                              <p className="mb-1">IRR ({DATA.summary.asset_currency})</p>
                              <p className="mb-3">{loading ? <Loading/> :
                                (
                                  formatPercentage(DATA.summary.position_irr_in_asset_currency)
                                )
                              }  </p>
                              <p className="text-truncate mb-0">{DATA.summary.position_nav_date ? DATA.summary.position_nav_date : ''}</p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col> : ''


                  )}

                  {(!portraitMode && 
                  <Col xl={12} sm={12}>
                    <Card>
                      <CardBody>
                        <CardTitle>Irr Data Serie </CardTitle>
                        {(DATA.irrSerie && DATA.irrSerie.length > 0 ? (

                          <MoondanceLayout.MoondanceTable
                            rows={DATA.irrSerie}
                            defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                            paginationSize={50}
                            step={50}
                            columns={[
                              { label: 'Date', field: 'month_year_timestamp', format: 'timestampToDate' },
                              { label: 'Value (' + userCurrency + ')', field: 'value_in_user_currency', format: 'currency', formatUnit: userCurrency, align: 'right', showTotal: true },
                              { label: 'Value (' + DATA.summary.asset_currency + ')', field: 'value_in_asset_currency', format: 'currency', formatUnit: DATA.summary.asset_currency, align: 'right', showTotal: true },

                            ]}

                          />

                        ) : " No IRR Data Serie found")}
                      </CardBody>
                    </Card>
                  </Col>
                  )}
                  <PortraitAlert />
                

              </TabPane>
              <TabPane tabId="7" id="Edit">
                {(activeTab === '7' && DATA.perYear && DATA.perYear.length > 0 ? (
                  <EditPositionForm data={DATA} />
                ) : "No Data found")}
              </TabPane>

              <TabPane tabId="notes" id="Notes">
                {(activeTab === "notes" ? <Notes position_id={id} /> : '')}
              </TabPane>
            </TabContent>
          </Container>
        </div>
      </React.Fragment >
    );
  }
};

export default PositionDetails;


/*

<Col xl={12} sm={12}>
                  <Card >
                    <CardBody>
                      <CardTitle className="mb-4E">Expected Cashflow</CardTitle>

                      <WaterFallChart

                        title="Expected Cashflow"
                        data={DATA.yearlyWaterfall.data}
                        options={DATA.yearlyWaterfall.options}
                      />

                    </CardBody>
                  </Card>
                </Col>*/