import React, { useState, useContext, useEffect,useRef ,useLayoutEffect} from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";


import { MoondanceContext } from '../../../App';
import { AlertContainer } from "../../../moondance/helpers/AlertContainer.js"; // Import the AlertContext

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { alertContent } = useContext(MoondanceContext);





  useEffect(() => {
    if (alertContent.length > 0) {
      setMenu(true);
    }
  }, [alertContent]);


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-notification-3-line" />
          {((alertContent && alertContent.length > 0) ? <span className="noti-dot"></span> : '')}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Button close onClick={() => setMenu(false)} />

              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: alertContent.length > 3 ? "340px" : `${120 * alertContent.length}px` }}>

            <AlertContainer   lastAlertToShow={10} />
          </SimpleBar>
        
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};