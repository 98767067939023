import React, { useEffect, useState, useContext } from "react";

import Form from 'react-bootstrap/Form';
import { UncontrolledCarousel } from "reactstrap";
import {
    Card, CardBody, CardTitle, Col, Row, Container, Nav, TabContent, TabPane, Button,
    NavItem,
    NavLink, InputGroup
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";
import ContactForm from "./contactForm.js";

import { MoondanceContext } from '../../App';
const Contact = () => {

    const { darkMode } = useContext(MoondanceContext);
    return (

        <React.Fragment >
            <div className={(darkMode ? 'page-content darkMode' : 'page-content')} >
                <Container fluid={true}>
                <Card >
              <CardBody>
                    <Row style={{ marginBottom: '1em' }}>


                        <ContactForm />
                    </Row>
                    </CardBody>
                    </Card>
                </Container>
            </div>

        </React.Fragment>

    )
}


export default Contact;
