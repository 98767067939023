
import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Col, Row, Container, Button, InputGroup } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Select from "react-select";
import UseMoondanceApi from "../helpers/api.js";
import MoondanceLayout from "../helpers/layout.js";
import { MoondanceContext } from '../../App.js';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { testurl, formatGroupLabel, groupAndSortPositions, buildAccountParameter, buildTransactionTypeList, formatOptionLabel, formatDate, } from "../helpers/formatData.js";

const EditNotes = () => {
    const moondanceApi = UseMoondanceApi();

    const params = useParams();
    const id = (params.id ? params.id : false);

    const [matching_position_update, set_matching_position_update] = useState([]);
    const [matching_position_list, set_matching_position_list] = useState([]);
    const [existing_linked_position, set_existing_linked_position] = useState([]);
    const { addAlert } = useContext(MoondanceContext);
    const [positionList, setPositionList] = useState();
    const [noteText, setNoteText] = useState('');
    const [noteTitle, setNoteTitle] = useState('');
    const { selectedAccountList } = useContext(MoondanceContext);
    const { selectedStatus } = useContext(MoondanceContext);
    const [notes, setNotes] = useState([]);
    const [selectedFund, setSelectedFund] = useState();
    const [selected_position_details, set_selected_position_details] = useState(null);

    const [submitResult, setSubmitResult] = useState(false);

    const { accountList } = useContext(MoondanceContext);
    const [all_positions_list, set_all_positions_list] = useState([]);
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);

    const [error, setError] = useState({});

    const handleChange = (html) => {
        setError({})
        setNoteText(html);
    };
    let modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };

    let formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    async function handle_select_position(f) {
        console.log('handle_select_position')
        setError({})
        setSelectedFund(f.value);
    }
    const fetchNoteById = async (id) => {
        var api_parameters = {
            note_id: id,
        }
        try {
            const result = await moondanceApi.getNotePerId(api_parameters);
            console.log('result getNotePerId', result)
            return result.data;

        } catch (error) {
            return false;
        }
    };


    async function fetch_position_list(selectedAccountOnly = true) {
        var api_parameters = {
            account: (selectedAccountOnly ? buildAccountParameter(selectedAccountList) : buildAccountParameter(accountList)),
            status: 'All',
            userCurrency: userCurrency,
        }
        console.log('api_parameters', selectedAccountOnly, api_parameters)

        try {
            const response = await moondanceApi.fetchPositionsList(api_parameters)

            if (response && response.data.length > 0) {
                if (selectedAccountOnly) {
                    let positionList = groupAndSortPositions(response.data)  ///SECURITY
                    setPositionList(positionList)
                } else {
                    console.log('all_positions_list', response.data)
                    set_all_positions_list(response.data)

                }
            } else {
                return []
            }

        } catch (error) {
            console.log('error', error);
            return []
        }


        return []

    }


    function handleChangeSimilarPositionCheck(id, checkStatus) {
        console.log('handleChangeSimilarPositionCheck', id, checkStatus);
        let newupdatedMatchingPositionUpdate = { ...matching_position_list };

        Object.entries(newupdatedMatchingPositionUpdate).forEach(([key, item]) => {
            if (item.id === id) {
                // Update the specific position update
                console.log('set', id, 'to', checkStatus);
                newupdatedMatchingPositionUpdate[key] = {
                    id: item.id,
                    account: item.account,
                    asset_name: item.asset_name,
                    update: checkStatus
                };
            }
        });

        set_matching_position_list(newupdatedMatchingPositionUpdate);
    }
    function validForm() {
        let newError = {};
        let isValid = true;
        console.log('validForm', selectedFund, noteTitle, noteText)

        if (!noteTitle) {
            newError.noteTitle = 'Please enter a title'
            isValid = false;
        }
        if (!noteText) {
            newError.noteText = 'Please enter a note'
            isValid = false;
        }
        setError(newError)
        return isValid;

    }

    async function postLinkNoteToPosition(note_id, position_id) {
        console.log('postLinkNoteToPosition', note_id, position_id)
        moondanceApi.postLinkNoteToPosition({ note_id: note_id, position_id: position_id }).then(response => {
            console.log('response', response)
            setSubmitResult(true)
            addAlert({
                status: 'success',
                title: 'Note Updated',
                message: 'This note has been linked to position #' + position_id
            })

        }).catch(error => {
            console.log(error);
            addAlert({
                status: 'danger',
                title: 'Failed to link note with position #' + position_id + ' !',
                message: error
            })
        })

    }
    function handleSaveForm() {

        if (validForm()) {
            let note = {
                title: noteTitle,
                text: noteText,
                note_id: id
            }
            moondanceApi.postNoteUpdate(note).then(response => {
                console.log('response', response)
                moondanceApi.postNoteClearLink(note).then(response => {
                        console.log('response', response)   
                        Object.entries(matching_position_list).map(([index, item]) => {

                            if (item.update) {
                                postLinkNoteToPosition(id, item.id)
                            }

                        });
                    }).catch(error => {
                    console.log(error);
                    addAlert({
                        status: 'danger',
                        title: 'Failed to clear link between note and position  !',
                        message: error
                    })
                    
            }).catch(error => {
                    console.log(error);
                    addAlert({
                        status: 'danger',
                        title: 'Failed to add note  !',
                        message: error
                    })
                })
            })
        }
    }
        useEffect(() => {
            fetch_position_list(true);
            fetch_position_list(false);
        }, [selectedAccountList, selectedStatus]);

        useEffect(() => {
            const updateMatchingPositions = () => {
                let matching_position_list = [];
                console.log('all_positions_list', all_positions_list);
                existing_linked_position.forEach((item) => {
                    let matchingPosition = all_positions_list.find(position => parseInt(position.position_id) === parseInt(item));

                    matching_position_list.push({
                        id: item,
                        account: matchingPosition ? matchingPosition.position_account_name : 'Unknown Account', // Handle undefined case
                        asset_name: matchingPosition ? matchingPosition.asset_name : 'Unknown Asset', // Handle undefined case
                        update: true
                    });
                });
                console.log('matchingPosition', matching_position_list);
                set_matching_position_list(matching_position_list);

            };

            if (existing_linked_position.length > 0 && all_positions_list.length > 0) {
                updateMatchingPositions();
            }
        }, [existing_linked_position, all_positions_list]);

        useEffect(() => {
            const loadNote = async () => {
                try {
                    fetchNoteById(id).then((result) => {
                        if (result[0]) {
                            setNoteText(result[0].note);
                            setNoteTitle(result[0].note_title);
                            console.log('listOfLinkedPosition', result[0].position_ids);
                            if (result[0].position_ids) {
                                const listOfLinkedPosition = result[0].position_ids.split(',');
                                console.log('listOfLinkedPosition', result[0].position_ids, listOfLinkedPosition)
                                set_existing_linked_position(listOfLinkedPosition);
                            } else {
                                set_existing_linked_position([]); // handle case when linked_positions is undefined
                            }
                        }
                    })
                } catch (error) {
                    console.log('error', error)
                }
            }
            if (id) {
                loadNote();
            }

        }, [id]);
        return (
            <React.Fragment >
                <div className='page-content' >
                    <Container fluid={true}>
                        <Card>
                            <CardBody >


                                <Row>
                                    <h5
                                        className="modal-title mt-0"
                                        id="myModalLabel"
                                    >
                                        Edit Note
                                    </h5>
                                </Row>
                                <Row>
                                    <Form.Group as={Row} className="mb-3" >
                                        {(selected_position_details ? <p>Account :{selected_position_details.position_account_name} <br />Commitment : {MoondanceLayout.formatCurrency(selected_position_details.position_commitment_in_asset_currency, selected_position_details.asset_currency)}</p> : '')}

                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label >This note is linked to the following positions :</Form.Label>
                                        {Object.entries(matching_position_list).map(([index, item]) => (
                                            <inputGroup key={index} className="d-flex align-items-center" style={{ marginTop: '0.5em', }}>
                                                <input
                                                    onClick={() => (handleChangeSimilarPositionCheck(item.id, !item.update))}
                                                    type="checkbox"
                                                    checked={item.update}
                                                    style={{ marginRight: '1em' }}
                                                ></input>
                                                <p style={{ marginBottom: '0px', }}>{item.asset_name} in account {item.account} </p>
                                            </inputGroup>
                                        ))}
                                        <p style={{ marginTop: '1em', }}>Check the box to link this note to the corresponding position. Any changes made to the note will automatically be applied to each linked position.</p>
                                    </Form.Group>




                                    < Form.Group as={Row} className="mb-3" >
                                        <Form.Label >Note Title</Form.Label>
                                        {error.noteTitle && (<label className="error mt-2 text-danger">{error.noteTitle}</label>)}

                                        <InputGroup>
                                            <input className="form-control" type="text" value={noteTitle} onChange={(e) => { console.log(e.target.value); setNoteTitle(e.target.value); setError({}) }} />
                                        </InputGroup>

                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" >
                                        <Form.Label >Your Note</Form.Label>

                                        {error.noteText && (<label className="error mt-2 text-danger">{error.noteText}</label>)}
                                        <ReactQuill

                                            style={{ minHeight: '45em', marginBottom: '3em' }}
                                            value={noteText}
                                            onChange={handleChange}
                                            modules={modules}
                                            formats={formats}
                                            rows={15}
                                        />



                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" >
                                        <button
                                            type="button"
                                            className="btn btn-primary form-control"
                                            onClick={() => { handleSaveForm() }}
                                        >
                                            Save Note
                                        </button>

                                    </Form.Group>
                                    <hr />
                                </Row>
                            </CardBody>
                        </Card>
                    </Container>

                </div>
            </React.Fragment >
        )

    }
    export default EditNotes;