import React, { useState, useEffect, useContext, } from "react";
import { p } from 'react-router-dom';
import { Modal, Col, Row, Button, NavItem, NavLink, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, } from "reactstrap";
import { MoondanceContext } from '../../App';
import { statusList, timestampToddmmyyyy } from "../helpers/formatData";
import { set } from "lodash";
import { DropdownDivider } from "react-bootstrap";


export default function Selector(props) {
  const { accountList } = useContext(MoondanceContext);
  const { selectedAccountList, setSelectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus, setSelectedStatus } = useContext(MoondanceContext);

  const [tmpSelectedAccountList, setTmpSelectedAccountList] = useState([]);
  const [tmpSelectedStatus, setTmpSelectedStatus] = useState([]);
  const [showRefresh, setShowRefresh] = useState(false);


  const [modal_open, set_modal_open] = useState(false)

  useEffect(() => {
    setTmpSelectedAccountList(selectedAccountList)
    setTmpSelectedStatus(selectedStatus)
  }, [selectedAccountList, selectedStatus]);

  const triggerRefresh = () => {
    console.log('triggerRefresh',tmpSelectedAccountList)
    setSelectedAccountList(tmpSelectedAccountList)
    setSelectedStatus(tmpSelectedStatus)


    localStorage.setItem('selectedAccount', JSON.stringify(tmpSelectedAccountList));
    localStorage.setItem('selectedStatus', JSON.stringify(tmpSelectedStatus));

    set_modal_open(false)
    setShowRefresh(false)

  }

  const selectAccountUpdate = (index, action, event) => {

    //console.log('selectAccountUpdate', index, action, event    )
    if (action === "select") {
      // Find the selected element in accountList
      const selectedElement = accountList[index];

      // Add it to the selectedAccountList (if it's not already present)
      if (!tmpSelectedAccountList.some((item) => item.value === selectedElement.value)) {
        setTmpSelectedAccountList((prevSelected) => [...prevSelected, selectedElement]);
      }
    } else if (action === "unselect") {
      // Find the selected element in selectedAccountList based on some unique identifier (e.g., value)
      const selectedElement = accountList[index];

      // Find the index of the element to remove from selectedAccountList
      const removeIndex = tmpSelectedAccountList.findIndex((item) => item.value === selectedElement.value);

      if (removeIndex !== -1) {
        setTmpSelectedAccountList((prevSelected) =>
          prevSelected.filter((item, idx) => idx !== removeIndex)
        );
      }
    }

    setShowRefresh(true)
  };
  const selectStatusUpdate = (index, action, event) => {

    if (action === "select") {
      // Find the selected element in statusList
      const selectedElement = statusList[index];

      // Add it to the selectedStatus (if it's not already present)
      if (!tmpSelectedStatus.some((item) => item.value === selectedElement.value)) {
        setTmpSelectedStatus((prevSelected) => [...prevSelected, selectedElement]);
      }
    } else if (action === "unselect") {
      // Find the selected element in selectedStatus based on some unique identifier (e.g., value)
      const selectedElement = statusList[index];

      // Find the index of the element to remove from selectedStatus
      const removeIndex = tmpSelectedStatus.findIndex((item) => item.value === selectedElement.value);

      if (removeIndex !== -1) {
        setTmpSelectedStatus((prevSelected) =>
          prevSelected.filter((item, idx) => idx !== removeIndex)
        );
      }
    }

    setShowRefresh(true)
  };

  return (

    <React.Fragment>
      <Button style={{backgroundColor:'transparent'}} className="hideOnMobile btn header-item" caret='true' onClick={() => { set_modal_open(true) }}>
        Account & Status<i className="mdi mdi-chevron-down" />
      </Button>

      <Modal isOpen={modal_open} >

        <div className="modal-header">
          <h5
            className="modal-title mt-0">
            Select Account and position status
          </h5>
          <button
            type="button"
            onClick={() => { set_modal_open(false) }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <h6 style={{ marginLeft: "1em" }}>Account:</h6>

          {accountList.length > 0 ? (
            (tmpSelectedAccountList && accountList && accountList.map((element, index) => (
              <div key={index} onClick={(e) => selectAccountUpdate(index, (tmpSelectedAccountList.some(selectedElement => selectedElement.value === element.value) ? 'unselect' : 'select'), e)} >
                <p >
                  {(tmpSelectedAccountList.some(selectedElement => selectedElement.value === element.value) ? <span style={{ width: '5em' }} className="dripicons-checkmark mr-2"></span> : <span style={{ width: '5em' }}  >&nbsp;&nbsp;&nbsp;</span>)}&nbsp;{element.label}</p>
              </div>
            )))) : (<p>No accounts found</p>)}

          <h6 style={{ marginLeft: "1em" }}>Status:</h6>
          {statusList && statusList.map((element, index) => (

            <div key={index} onClick={(e) => selectStatusUpdate(index, (tmpSelectedStatus.some(selectedElement => selectedElement.value === element.value) ? 'unselect' : 'select'), e)} >
              <p  >
                {(tmpSelectedStatus.some(selectedElement => selectedElement.value === element.value) ? <span style={{ width: '5em' }} className="dripicons-checkmark mr-2"></span> : <span style={{ width: '5em' }}  >&nbsp;&nbsp;&nbsp;</span>)}&nbsp;{element.label}</p>
            </div>

          ))}
          {(showRefresh === true ?
            <div onClick={() => triggerRefresh()} >
              <Button className="btn btn-success form-control" >Refresh results</Button>
            </div>
            : '')}

        </div>
        <div className="modal-footer">

          <button
            type="button"
            onClick={() => { set_modal_open(false) }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>

        </div>
      </Modal>
    </React.Fragment >)
}

/*<div>
          {accountList.length > 0 ? (
            <><p >Selected accounts :

              {tmpSelectedAccountList && accountList &&
                accountList.map((element, index) => (
                  (tmpSelectedAccountList.some(selectedElement => selectedElement.value === element.value) ?
                    <a href="#" className="badge bg-info me-1" key={index} onClick={() => selectAccountUpdate(index, 'unselect')}>{element.label}</a> :
                    <a href="#" className="badge bg-light me-1" key={index} onClick={() => selectAccountUpdate(index, 'select')}><s>{element.label}</s></a>
                  )
                ))}
            </p>
              <p>Selected Status :
                {statusList &&
                  statusList.map((element, index) => (
                    (tmpSelectedStatus.some(selectedElement => selectedElement.value === element.value) ?

                      <a href="#" className="badge bg-info me-1" key={index} onClick={() => selectStatusUpdate(index, 'unselect')}>{element.label}</a> :
                      <a href="#" className="badge bg-light me-1" key={index} onClick={() => selectStatusUpdate(index, 'select')}><s>{element.label}</s></a>
                    )
                  ))}
              </p>
              {showRefresh === true && <p><button className="btn btn-primary btn-sm" onClick={() => triggerRefresh()}>Refresh results</button></p>}
            </>
          ) : (<p>Nothing selected</p>)}
        </div>
      </Col>
      <Col xl={6} sm={6} className="text-end">
        {props.lastNavUpdate ? <p>Last NAV update : {(props.lastNavUpdate && props.lastNavUpdate !== 0 && timestampToddmmyyyy(props.lastNavUpdate))}</p> : ""}
        {props.lastTransaction ? <p>Last Transaction : {(props.lastTransaction && props.lastTransaction !== 0 && timestampToddmmyyyy(props.lastTransaction))}</p> : ""}

      </Col>*/