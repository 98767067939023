import React, { useState, useContext, useEffect } from "react";
import {
  Card, CardBody, CardTitle, Col, Container, Button, Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { useParams, Link, useNavigate } from 'react-router-dom';
import { MoondanceContext } from '../../App.js';
import UseMoondanceApi from "../helpers/api.js";
import MoondanceLayout from "../helpers/layout.js";
//import EditPositionForm from "./EditPositionForm.js"
import EditPositionForm from "./AddPositionFormWizard.js"

import { sectorList, geographyList, assetType, getStatusLabel, formatCurrency, statusList, formatNumber, formatPercentage, buildYearlyChart, buildFundDetail, buildFlatSerie } from "../helpers/formatData.js";

const AddPosition = () => {

  const moondanceApi = UseMoondanceApi();
  const { accountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);
  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);

  const [DATA, setData] = useState({});
  const params = useParams();
  const id = params.id;

  const [loading, setLoading] = useState(true);


  const fetchAndSetSectors = async (api_parameters, id) => {
    try {
      const sector = await moondanceApi.fetchPositionSectors(api_parameters, id);
      if (sector && sector.data && Array.isArray(sector.data)) {
        return sector.data;
      }

    } catch (error) {
      console.error("Error fetching sector:", error);
      return false;
    }
  };
  const fetchAndSetGeography = async (api_parameters, id) => {
    try {
      const geography = await moondanceApi.fetchPositionGeography(api_parameters, id);
      if (geography && geography.data && Array.isArray(geography.data)) {
        return geography.data;
      }

    } catch (error) {
      console.error("Error fetching geography:", error);
      return false;

    }
  };
  const fetchAndSetTypes = async (api_parameters, id) => {
    try {
      const type = await moondanceApi.fetchPositionTypes(api_parameters, id);
      if (type && type.data && Array.isArray(type.data)) {
        return type.data;
      }

    } catch (error) {
      console.error("Error fetching types:", error);
      return false;

    }
  };

  useEffect(() => {
    var api_parameters = {
      account: accountList,
      lastUpdate: lastUpdate,
      userCurrency: userCurrency,
    }
    var data = {};
    async function fetchData() {
      try {

        var data_summary = await moondanceApi.fetchPositionDetails(api_parameters, id);
        var yearly_data = await moondanceApi.fetchPositionYearly(api_parameters, id);
        data.summary = data_summary.data
        data.sectors = await fetchAndSetSectors(api_parameters, id);
        data.geographies = await fetchAndSetGeography(api_parameters, id);
        data.types = await fetchAndSetTypes(api_parameters, id);
        data.perYear = yearly_data.data

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        for (let i = 0; i < data.perYear.length; i++) {
          if (data.perYear[i].year === currentYear) {
            data.currentYear = data.perYear[i];
          }
        }

        console.log('data', data)
        setData(data);
        setLoading(false)
      } catch (error) {
        console.log(error);
      }
      console.log('data', data)
    }
    fetchData();
  }, [id, lastUpdate])

  return (
    <React.Fragment>

      <div className="page-content">

        <Row style={{ marginBottom: '1em' }}>

          <Col  sm={12} md={10} xs={12}> 
          {(DATA && DATA.summary && DATA.summary.asset_name ? <h1>{DATA.summary.asset_name} (EDIT)</h1> : <h1 >Edit position</h1>)}
          </Col>
       
          <Col  sm={12} md={2} xs={12}>
            <Link to={'/position/' + id} className="btn btn-outline-primary form-control">View position</Link>

          </Col>
        </Row>

        <div className="flex">

          <div>
          </div>
        </div>
        {(loading) ? <div className="loading"></div> :
          <   EditPositionForm editMode="edit" data={DATA} />
        }
      </div>
    </React.Fragment>
  )


}
export default AddPosition;