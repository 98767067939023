
import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Col, Row, Container, Button, InputGroup } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import { isValidEmail } from "../helpers/formatData";
import Select from "react-select";
import UseMoondanceApi from "../helpers/api";
import MoondanceLayout from "../helpers/layout";
import { MoondanceContext } from '../../App';
import BarChart from "../helpers/barchart";

import DOMPurify from 'dompurify';

import { buildTableData, buildAccountParameter } from "../helpers/formatData";





const ListNotes = () => {
    const moondanceApi = UseMoondanceApi();

    const params = useParams();
    const id = (params.id ? params.id : false)  ;
    const { userCurrency } = useContext(MoondanceContext);
    const { selectedAccountList } = useContext(MoondanceContext);
    const { selectedStatus } = useContext(MoondanceContext);
    const [notes, setNotes] = useState([]);

    const createMarkup = (html) => {
        return { __html: DOMPurify.sanitize(html) };
      };
    async function getNotes(account_id) {
        if ( parseInt(id)) {
            let result = await moondanceApi.getNoteForPosition({
                account: buildAccountParameter(selectedAccountList),
                status: buildAccountParameter(selectedStatus),
                id: id
            });
            setNotes(result.data);
        } else {
            let result = await moondanceApi.getNotes({
                account: buildAccountParameter(selectedAccountList),
                status: buildAccountParameter(selectedStatus),

            });
            setNotes(result.data);
        }
    };

    useEffect(() => {
        getNotes();
    }, [selectedAccountList, selectedStatus, id]);

    useEffect(() => {
        console.log('notes', notes)
    }, [notes]);

    return (
        <div >
            <div className="d-flex justify-content-between align-items-center" style={{marginBottom:'1em'}}>
             
                <Link className="btn btn-outline-primary" to={'../notes/new' +(id == parseInt(id) ? '/'+id :'')}>Add Note</Link>
            </div>
            {(notes && notes.length > 0) ?
                <div>



                    {notes.map(item => (
                        <Card> <CardBody >
                            <div className="d-flex justify-content-between align-items-center" >
                            <h6><Link to={'/position/' + item.position_id}>{item.asset_name} (account : {item.account_name})</Link></h6>
                             <Link className="btn-sm  waves-effect waves-light btn-outline-primary btn btn-secondary" to={'../notes/edit/' +item.note_id}> <span class="fas fa-edit"></span> Edit Note</Link>
                             </div>


                            <p dangerouslySetInnerHTML={createMarkup(item.note)}></p>
                            <hr />
                            <small className=" d-flex justify-content-end"> Note #{item.note_id} - {item.note_user_name} - Created : {item.note_date_created_date} - Updated : {item.note_date_updated_date}</small>

                        </CardBody>
                        </Card>
                    ))}

                </div>
                :
                <div>
                    <Card>
                        <CardBody >
                            <CardTitle>Notes</CardTitle>

                            <p>No Notes found </p>

                        </CardBody>
                    </Card>
                </div>}

        </div >
    )

}
export default ListNotes;