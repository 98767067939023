
import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, CardTitle, Col, Row, Container, Button, InputGroup } from "reactstrap";

import { isValidEmail } from "../helpers/formatData";
import Select from "react-select";
import UseMoondanceApi from "../helpers/api";
import MoondanceLayout from "../helpers/layout";
import { MoondanceContext } from '../../App';
import BarChart from "../helpers/barchart";


import { buildTableData, buildAccountParameter } from "../helpers/formatData";

const AccountBudget = () => {

    const moondanceApi = UseMoondanceApi();
    const { selectedAccountList } = useContext(MoondanceContext);
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const [thisAccountList, setThisAccountList] = useState([]);

    const { addAlert } = useContext(MoondanceContext);
    const { selectedStatus } = useContext(MoondanceContext);
    const [budget, setBudget] = useState([]);
    const [loading, setLoading] = useState(true);
    const [budget_DATA, setBudgetData] = useState({});

    const [budget_chart_data, set_budget_chart_data] = useState({});
    const [budget_chart_option, set_budget_chart_option] = useState({});
    const [showBudgetItem, setShowBudgetItem] = useState('Table');
    const [api_parameters, setApiParameters] = useState({});

    async function getBudget(account_id) {
        var api_parameters = {
            account_id: account_id,
            userCurrency: userCurrency,
        };
        let result = await moondanceApi.getBudget(api_parameters);
       // console.log('getBudget', result.data)
        return result.data;
    };

    useEffect(() => {
       //console.log('budget_data',budget_DATA)
        let new_api_parameters = {
            account: buildAccountParameter(selectedAccountList),
            status: buildAccountParameter(selectedStatus),
            userCurrency: userCurrency,
        }

        setApiParameters(new_api_parameters);
        
        fetchBudgets();
    }, [selectedAccountList, selectedStatus, userCurrency]);

    //load expected cashflow from API
    useEffect(() => {
        
        async function fetchData() {
            if (api_parameters.account && api_parameters.status) {
                try {
                    var returnData = {}
                    //Fetch summary aggregated data to display on the dashboard
                    var summarydata = await moondanceApi.fetchDashboardSummary(api_parameters);
                    //Fetch yearly aggregated data to display on the dashboard
                    var yearlyData = await moondanceApi.fetchYearly(api_parameters);
                    if (yearlyData.data.length > 0) {

                        returnData.perYear = MoondanceLayout.filterDataPeryear(yearlyData.data)
                        returnData.summary = summarydata.data
                        returnData.budget = budget
                        returnData.budgetCashFlow = MoondanceLayout.calculateBudget(returnData.budget)
                        returnData.cashflow = MoondanceLayout.buildCashFlowChart(returnData, 'Future', false, true)
                        returnData.cumulatedCashflow = MoondanceLayout.buildCumulatedCashFlowChart(returnData, 'Future', false, true)
                       // console.log('budget', returnData)
                        returnData.cumulatedCashflowTable = []
                        if (returnData.cumulatedCashflow.categories) {
                            returnData.cumulatedCashflow.categories.map((category, index) => {
                                returnData.cumulatedCashflowTable.push({
                                    year: category,
                                    commitment: (returnData.budgetCashFlow.commitment[category] ? returnData.budgetCashFlow.commitment[category] : 0),
                                    already_commited: (returnData.budgetCashFlow.already_commited[category] ? returnData.budgetCashFlow.already_commited[category] : 0),
                                    expected: (returnData.cumulatedCashflow.rawdata.expected[index] ? returnData.cumulatedCashflow.rawdata.expected[index] : 0),
                                    budgeted: (returnData.cumulatedCashflow.rawdata.budget_balance[index] ? returnData.cumulatedCashflow.rawdata.budget_balance[index] : 0),
                                    annual: (returnData.cumulatedCashflow.rawdata.budget_balance[index] ? returnData.cumulatedCashflow.rawdata.budget_balance[index] : 0) + (returnData.cumulatedCashflow.rawdata.expected[index] ?  returnData.cumulatedCashflow.rawdata.expected[index] : 0),
                                    balance: (returnData.cumulatedCashflow.rawdata.expected[index] ? returnData.cumulatedCashflow.rawdata.expected[index] : 0),
                                    cumulativeExpected: (returnData.cumulatedCashflow.rawdata.real_aggr[index] ? returnData.cumulatedCashflow.rawdata.real_aggr[index] : 0),
                                    cumulativeBudgeted: (returnData.cumulatedCashflow.rawdata.budget_aggr[index] ? returnData.cumulatedCashflow.rawdata.budget_aggr[index] : 0),
                                })
                            });
                        }
                        set_budget_chart_data(returnData.cashflow.data)
                        set_budget_chart_option(returnData.cashflow.options)
                        setBudgetData(returnData)
                    } else {
                        setBudgetData('nodata')

                    }
                    setLoading(false)
                } catch (error) {
                    //console.log(error);
                }


            }
            console.log('returnData', returnData)
            
        }
        fetchData();
        setLoading(true)
        
    }, [api_parameters,budget, showBudgetItem]);

    async function fetchBudgets() {
        if (selectedAccountList.length > 0) {
            let new_budget = {};
            selectedAccountList.map(account => {
                new_budget[account.value] = generateDefaultBudget(account.value); // Make a copy of the current budget
            }
            );
            const budgetPromises = selectedAccountList.map(account =>
                getBudget(account.value)
            );
            const budgetResults = await Promise.all(budgetPromises);
            budgetResults.forEach((thisBudget) => {
                if (thisBudget.length > 0) {
                    thisBudget.forEach(yearBudget => {
                        new_budget[yearBudget.account_id][yearBudget.year] = yearBudget
                    });
                }
            });
            //console.log('selectedAccountList', selectedAccountList);
            setBudget(new_budget);
            setThisAccountList(selectedAccountList);
        }
    }

  

    function generateDefaultBudget(account_id) {
        const currentYear = new Date().getFullYear();
        const endYear = currentYear + 12;
        const defaultBudget = [];

        for (let year = currentYear; year <= endYear; year++) {
            defaultBudget[year] = {
                year: year,
                currency: userCurrency,
                account_id: account_id,
                commitment: 0, // Default value
                invest_period: 5, // Default value
                detention_period: 5, // Default value
                expected_irr: 0.1 // Default value
            };

        }

        return defaultBudget;
    }


    //Handle changes in the budget table
    function handleValueChange(account_id, year, key, value) {
        //console.log('handleValueChange', account_id, year, key, value)
        let new_budget = { ...budget };

        new_budget[account_id][year][key] = value;
        setBudget(new_budget);
    }

    function copyToAll(account_id, row) {
        //console.log('copyToAll', account_id, row)
        let existingBudget = { ...budget };

        existingBudget[account_id].map((yearBudget, index) => {

            if (yearBudget.year > row.year) {
            existingBudget[account_id][index].commitment = row.commitment;
            existingBudget[account_id][index].invest_period = row.invest_period;
            existingBudget[account_id][index].detention_period = row.detention_period;
            existingBudget[account_id][index].expected_irr = row.expected_irr;
            } 
        });
        setBudget(existingBudget);
    }
    async function saveBudget(account_id) {
        //console.log('saveBudget', account_id, budget[account_id])
        let success = true;

        try {
            // Using Promise.all to wait for all the promises to resolve
            await Promise.all(budget[account_id].map(async (yearBudget) => {
                const result = await moondanceApi.saveBudget(yearBudget);
                if (!result.success) {
                    success = false;
                }
            }));

            if (success) {
                addAlert({
                    status: 'success',
                    title: 'Update Budget',
                    message: 'Budget has been saved successfully!'
                });
            } else {
                addAlert({
                    status: 'warning',
                    title: 'Update Budget',
                    message: 'Some budget entries could not be saved.'
                });
            }
        } catch (error) {
            console.error('Error saving budget:', error);
            addAlert({
                status: 'error',
                title: 'Update Budget',
                message: 'An error occurred while saving the budget.'
            });
        }
        fetchBudgets()

    }

    return (
        <div >
            <h2>Budget :</h2>
            {(!thisAccountList ? <p>No account selected</p> :
               
                <Card>
                    <CardBody >
                        <div className="flex" style={{ marginBottom: "1em" }}>
                         
                            <div
                                className="btn-group"
                                role="group"
                            >
                                <button type="button" className={showBudgetItem === 'Graph' ? 'btn  btn-sm btn-primary' : 'btn btn-sm btn-outline-primary'} onClick={() => { setShowBudgetItem('Graph') }}>Show Graph</button>
                                <button   type="button" className={showBudgetItem === 'Table' ? 'btn btn-primary btn-sm' : 'btn btn-sm btn-outline-primary'} onClick={() => { setShowBudgetItem('Table') }}>Show Table</button>
                                <button   type="button" className={showBudgetItem === 'Form' ? 'btn btn-primary btn-sm' : 'btn btn-sm btn-outline-primary'} onClick={() => { setShowBudgetItem('Form') }}>Edit Budget</button>
                            </div>
                        </div>
                        {(showBudgetItem == 'Form' ?
                            (thisAccountList.map((account, index) => (

                                (budget && budget[account.value] && budget[account.value].length > 0 ?
                                    <Row>
                                        <CardTitle ><h6>{account.label} {account.value}</h6></CardTitle>
                                        <MoondanceLayout.MoondanceTable
                                            rows={budget[account.value]}
                                            defaultSort={{ key: 'timestamp', direction: 'ascending' }}
                                            columns={[

                                                {
                                                    label: 'Year',
                                                    field: 'year',

                                                    style: {
                                                        maxWidth: '2em',
                                                    },

                                                    customFormat: (row) => {
                                                        return (

                                                                <>
                                                                    <span>{row.year}</span>
                                                                    <Button className="btn btn-sm btn-outline-primary" style={{ marginLeft: "1em" }}
                                                                        onClick={() => { copyToAll(row.account_id, row); }}

                                                                    >Apply to following years</Button>
                                                                </>
                                                               
                                                        )
                                                    }
                                                },
                                                {
                                                    label: 'Commitment',
                                                    field: 'commitment',
                                                    customFormat: (row) => {
                                                        return (

                                                            <InputGroup>


                                                                <input
                                                                    key={account.value + '-' + row.year + '-commitment'}
                                                                    className="form-control "
                                                                    type="number"
                                                                    size="8"

                                                                    onChange={(e) => { handleValueChange(row.account_id, row.year, 'commitment', e.target.value); }}
                                                                    value={budget[row.account_id][row.year].commitment}
                                                                />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text">
                                                                        {(row.currency !== userCurrency ? <span className="text-danger"><b>{row.currency}</b></span> : row.currency)}
                                                                    </span>
                                                                </div>



                                                            </InputGroup>
                                                        )
                                                    },

                                                    style: {
                                                        maxWidth: '6em',
                                                        borderRight: '1px dashed black'
                                                    },
                                                },

                                                {
                                                    label: 'Investment Period',
                                                    field: 'invest_period',
                                                    customFormat: (row) => {
                                                        return (
                                                            <InputGroup>
                                                                <input

                                                                    key={account.value + '-' + row.year + '-invest_period'}
                                                                    className="form-control "
                                                                    type="number"
                                                                    size="3"

                                                                    onChange={(e) => { handleValueChange(row.account_id, row.year, 'invest_period', e.target.value); }}
                                                                    value={budget[row.account_id][row.year].invest_period}
                                                                />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text">
                                                                        Y
                                                                    </span>
                                                                </div>  </InputGroup>
                                                        )
                                                    },
                                                    style: {
                                                        maxWidth: '2em',
                                                    },
                                                },
                                                {
                                                    label: 'Detention Period',
                                                    field: 'detention_period',
                                                    customFormat: (row) => {
                                                        return (
                                                            <InputGroup>
                                                                <input
                                                                    key={account.value + '-' + row.year + '-detention_period'}
                                                                    className="form-control "
                                                                    type="number"
                                                                    size="2"

                                                                    onChange={(e) => { handleValueChange(row.account_id, row.year, 'detention_period', e.target.value); }}
                                                                    value={budget[row.account_id][row.year].detention_period}
                                                                />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text">
                                                                        Y
                                                                    </span>
                                                                </div>  </InputGroup>
                                                        )
                                                    },
                                                    style: {
                                                        maxWidth: '2em',
                                                    },
                                                },
                                                {
                                                    label: 'Expected IRR',
                                                    field: 'expected_irr',
                                                    customFormat: (row) => {
                                                        return (
                                                            <InputGroup>

                                                                <input
                                                                    key={account.value + '-' + row.year + '-expected_irr'}
                                                                    className="form-control "
                                                                    type="number"
                                                                    size="2"

                                                                    onChange={(e) => { handleValueChange(row.account_id, row.year, 'expected_irr', e.target.value / 100); }}
                                                                    value={budget[row.account_id][row.year].expected_irr * 100}

                                                                />
                                                                <div className="input-group-append" >
                                                                    <span className="input-group-text">
                                                                        %
                                                                    </span>
                                                                </div>  </InputGroup>
                                                        )
                                                    },
                                                    style: {
                                                        maxWidth: '2em',
                                                    },
                                                },

                                            ]}
                                        />

                                        <Button className="btn btn-primary " onClick={() => { saveBudget(account.value) }}><i className="fas fa-edit"></i>{" "}Save</Button>

                                    </Row>
                                    : <p>No budget data available</p>
                                )
                            )))
                            :
                            (showBudgetItem == 'Table' ?
                    
                                    (budget_DATA && budget_DATA.cumulatedCashflowTable && budget_DATA.cumulatedCashflowTable.length > 0 ?
                                        <MoondanceLayout.MoondanceTable
                                            rows={budget_DATA.cumulatedCashflowTable}
                                            defaultSort={{ key: 'year', direction: 'ascending' }}
                                            paginationSize={25}
                                            columns={[
                                                {
                                                    label: 'year',
                                                    field: 'year',
                                                    style: {
                                                        maxWidth: '6em',
                                                    },
                                                },
                                                {
                                                    label: 'Commited',
                                                    field: 'already_commited',
                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.already_commited, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',

                                                    },
                                                    format: 'currency',
                                                    formatUnit: userCurrency,
                                                    showTotal: true,

                                                },
                                                {
                                                    label: 'Expected Annual cashflow',
                                                    field: 'expected',

                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.expected, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',
                                                    },
                                                    format: 'currency',
                                                    formatUnit: userCurrency, 
                                                    showTotal: true,
                                                },

                                                {
                                                    label: 'Expected Cumulated Cashflow',
                                                    field: 'cumulativeExpected',
                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.cumulativeExpected, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',
                                                        borderRight: '1px dashed black'
                                                    },
                                                    format: 'currency',
                                                    formatUnit: userCurrency, 

                                                },
                                               
                                                {
                                                    label: 'Budgeted New Commitment',
                                                    field: 'commitment',
                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.commitment, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',

                                                    },
                                                    format: 'currency',
                                                    formatUnit: userCurrency,
                                                    showTotal: true,

                                                },
                                                {
                                                    label: 'Budgeted Annual Cashflow',
                                                    field: 'budgeted',
                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.budgeted, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',

                                                    },
                                                    format: 'currency',
                                                    formatUnit: userCurrency,
                                                    showTotal: true,

                                                },
                                                {
                                                    label: 'Budgeted Cumulated Cashflow',
                                                    field: 'budgeted',
                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.cumulativeBudgeted-row.cumulativeExpected, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',

                                                        borderRight: '1px dashed black'
                                                    },
                                                    format: 'currency',
                                                    formatUnit: userCurrency,
                                                    showTotal: true,

                                                },
                                                {
                                                    label: 'Global annual cashflow',
                                                    field: 'annual',
                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.annual, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',
                                                    },
                                                    format: 'currency',                                                   
                                                    formatUnit: userCurrency,
                                                    showTotal: true,

                                                },
                                                {
                                                    label: 'Global cumulated cashflow',
                                                    field: 'cumulativeBudgeted',
                                                    customFormat: (row) => { return (MoondanceLayout.formatCurrency(row.cumulativeBudgeted, row.currency)) },
                                                    style: {
                                                        maxWidth: '6em',
                                                        textAlign: 'right',
                                                    },

                                                },
                                            ]}
                                        />
                                        : <p>No budget data available</p>
                                    )
                                
                                : (showBudgetItem == 'Graph' ?

                                    <>
                                        {(loading ? <p>Loading data ...</p> :
                                            (budget_DATA.cumulatedCashflow && budget_chart_data  && budget_chart_option ?

                                                <Col xl={12} sm={12}>

                                                    <Card >
                                                        <CardBody>
                                                            <BarChart
                                                                chartKey="budget-cashflow-${account.value}-${showBudgetItem}"
                                                                title="Expected Cashflow"
                                                                data={budget_chart_data}
                                                                options={budget_chart_option}
                                                                stacked={true}
                                                                currency={(userCurrency ? userCurrency : 'EUR')}
                                                                height={500}
                                                            />
                                                            <Row>
                                                                <Col xl={3} sm={3}>
                                                                    <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span> Expected Capital Calls or distribution</p>
                                                                </Col>
                                                                <Col xl={3} sm={3}>
                                                                    <p><span style={{ width: '4em', borderWidth: '1px', borderStyle: 'solid', borderColor: MoondanceLayout.colorScheme.info }}>&nbsp;&nbsp;&nbsp;</span> Budgeted Capital Calls or distribution</p>
                                                                </Col>
                                                                <Col xl={3} sm={3}>
                                                                    <p><span style={{ width: '4em', color: MoondanceLayout.colorScheme.dark }} className="mdi mdi-circle"></span> Balance ( Expected + Budgeted)</p>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                : <p>No data</p>)
                                        )}

                                        {(loading ? <p>Loading data ...</p> :
                                            (budget_DATA.cumulatedCashflow ?

                                                <Col xl={12} sm={12}>

                                                    <Card >
                                                        <CardBody>
                                                            <BarChart


                                                                chartKey="budget-cumulated"
                                                                title="Expected Cashflow"
                                                                data={budget_DATA.cumulatedCashflow.data}
                                                                options={budget_DATA.cumulatedCashflow.options}
                                                                stacked={false}
                                                                height={500}
                                                            />
                                                            <Row>
                                                                <Col xl={3} sm={3}>
                                                                    <p><span style={{ width: '4em', backgroundColor: MoondanceLayout.colorScheme.expected_neutral }}>&nbsp;&nbsp;&nbsp;</span> Expected Annual Cash Flow</p>
                                                                </Col>
                                                                <Col xl={3} sm={3}>
                                                                    <p><span style={{ width: '4em', borderWidth: '1px', borderStyle: 'solid', borderColor: MoondanceLayout.colorScheme.info }}>&nbsp;&nbsp;&nbsp;</span> Budgeted Annual Cash Flow</p>
                                                                </Col>
                                                                <Col xl={3} sm={3}>
                                                                    <p><span style={{ width: '4em', color: MoondanceLayout.colorScheme.success }} className="mdi mdi-circle"></span>Expected Cumulated Cashflow (ex budget)</p>
                                                                </Col>
                                                                <Col xl={3} sm={3}>
                                                                    <p><span style={{ width: '4em', color: MoondanceLayout.colorScheme.dark }} className="mdi mdi-circle"></span>Global Cumulated Cashflow</p>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                : <p>No data</p>)
                                        )}
                                    </>
                                    : '')

                            )
                        )}

                    </CardBody>
                </Card>
            )}



        </div>
    )

}

export default AccountBudget;

/*  returnbudget_DATAcumulatedCashflowTable.push({
                                    year: category,
                                    expected: returnData.cumulatedCashflow.data[0].data[index],
                                    budgeted: returnData.cumulatedCashflow.data[1].data[index],
                                    balance: returnData.cumulatedCashflow.data[2].data[index],
                                    cumulativeExpected: returnData.cumulatedCashflow.data[3].data[index],
                                    cumulativeBudgeted: returnData.cumulatedCashflow.data[4].data[index],
                                })*/

/*
{(row.year > new Date().getFullYear() ?
                                                                  <div className="input-group-append" style={{ marginLeft: "1em" }}>
                                                                        <Button className="btn btn-light"
                                                                            onClick={() => { handleValueChange(row.account_id, row.year, 'commitment', budget[row.account_id][row.year - 1].commitment); }}

                                                                        >=</Button>
                                                                    </div> : ''
                                                                )}


                                                                 {
                                                    label: 'Total Budgeted Cashflow',
                                                    field: null,
                                                    customFormat: (row) => {
                                                        return (
                                                            <span>{(DATA && DATA.budgetCashFlow && DATA.budgetCashFlow.balance && DATA.budgetCashFlow.balance[row.year] ? MoondanceLayout.formatCurrency(DATA.budgetCashFlow.balance[row.year],'EUR') :'')}</span>
                                                        )
                                                    }, 
                                                },
                                                {
                                                    label: 'Total Cumulated Budgeted Cashflow',
                                                    field: null,
                                                    customFormat: (row) => {
                                                        return (
                                                            <span>{(DATA && DATA.budgetCashFlow && DATA.budgetCashFlow.cumulativeBalance && DATA.budgetCashFlow.cumulativeBalance[row.year] ? MoondanceLayout.formatCurrency(DATA.budgetCashFlow.cumulativeBalance[row.year],'EUR') :'')}</span>
                                                   
                                                        )
                                                    }, 
                                                }
                                                                
*/
