
import React, { useState, useContext, useEffect } from "react";
import {
    Card, CardBody, CardTitle, Col, Container, Button, Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane, Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    ButtonDropdown,
} from "reactstrap";
import { useParams, Link, useNavigate } from 'react-router-dom';
import { sectorList, geographyList, assetType, getStatusLabel, formatCurrency, statusList, formatNumber, formatPercentage, buildYearlyChart, buildFundDetail, buildFlatSerie } from "../helpers/formatData";

import MoondanceLayout from "../helpers/layout.js";
import UseMoondanceApi from "../helpers/api";

import { MoondanceContext } from '../../App';



const AssetModel = (props) => {

    const moondanceApi = UseMoondanceApi();
    const { accountList } = useContext(MoondanceContext);
    const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
    const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);
    const [data, setData] = useState({});

    const { anyModal, setAnyModal } = useContext(MoondanceContext);
    const [showTable,setShowTable] = useState(false);

    const { addAlert } = useContext(MoondanceContext);
    const params = useParams();
    const id = params.id;


    //load asset Model 
    // compare remaining model capital calls with remaining capital calls
    // if remaining model capital calls > remaining  capital calls, Model is overestimated
    // if remaining model capital calls < remaining  capital calls, Model is underestimated 
    // calculate correction factor to have Remaining model capital calls = remaining  capital calls
    // apply correction factor to all future capital calls in the model
    // recalculate distribution based on new capital calls, expected irr and detention period
    // Save New Model

    async function loadAssetModel(position_id) {
        var api_parameters = {
            account: accountList,
            lastUpdate: lastUpdate,
            userCurrency: userCurrency,
        }
        let new_data = {}
        let summary = await moondanceApi.fetchPositionDetails(api_parameters, position_id);
        let yearly = await moondanceApi.fetchPositionYearly(api_parameters, position_id);
        new_data.summary = summary.data;
        new_data.yearly = yearly.data;
        new_data.model = []
        new_data.fixed_capital_call = []
        new_data.fixed_distribution = []
        new_data.table = []
        new_data.sumcheck = {
            actual_call_pct:0,
            model_call_pct:0,
            total_call_pct:0,
            model_call_deficit:0
            }

        new_data.yearly.map((yearData,index) => {

            let new_row = { year: yearData.year, real_call: 0,real_call_pct: 0, real_distribution: 0,real_distribution_pct: 0, model_call: 0, model_call_pct: 0, model_distribution: 0,model_distribution_pct: 0,corrected_model_call_pct:0,corrected_model_distribution:0,corrected_model_distribution_pct:0 }





            if (parseInt(yearData.year) < MoondanceLayout.currentYear()) {
                new_row.real_call = -parseInt(yearData.sum_real_capital_call_in_asset_currency)

                new_row.real_distribution = parseInt(yearData.sum_real_distribution_in_asset_currency)

                new_row.model_call = 0
                new_row.model_distribution = 0



            } else if (parseInt(yearData.year) > MoondanceLayout.currentYear()) {
                new_row.real_call = 0
                new_row.real_distribution = 0
                new_row.model_call = -parseInt(yearData.sum_model_capital_call_in_asset_currency)
                new_row.model_distribution = parseInt(yearData.sum_model_distribution_in_asset_currency)

            } else if (parseInt(yearData.year) == MoondanceLayout.currentYear()) {
                new_row.real_call = -parseInt(yearData.sum_real_capital_call_in_asset_currency)
                new_row.real_distribution = parseInt(yearData.sum_real_distribution_in_asset_currency)

                //MODEL < ACTUAL
                if ( parseInt(-yearData.sum_model_capital_call_in_asset_currency) <  parseInt(-yearData.sum_real_capital_call_in_asset_currency)) {
                    new_row.model_call = 0
                } else {
                    new_row.model_call =  parseInt(-yearData.sum_model_capital_call_in_asset_currency) - parseInt(-yearData.sum_real_capital_call_in_asset_currency) 
                }

                
                if (yearData.sum_model_distribution_in_asset_currency > yearData.sum_real_distribution_in_asset_currency) {
                    new_row.model_distribution = parseInt(yearData.sum_model_distribution_in_asset_currency) -yearData.sum_model_distribution_in_asset_currency
                } else {
                    new_row.model_distribution = parseInt(yearData.sum_real_distribution_in_asset_currency)
                }

            }

            new_row.real_call_pct = new_row.real_call /parseInt(new_data.summary.position_commitment_in_asset_currency)
            new_row.real_distribution_pct = new_row.real_distribution /parseInt(new_data.summary.position_commitment_in_asset_currency)
            new_row.model_call_pct = new_row.model_call /parseInt(new_data.summary.position_commitment_in_asset_currency)
            new_row.model_distribution_pct = new_row.model_distribution /parseInt(new_data.summary.position_commitment_in_asset_currency)
            
            new_data.sumcheck.actual_call_pct += new_row.real_call_pct
            new_data.sumcheck.model_call_pct += new_row.model_call_pct
            new_data.sumcheck.total_call_pct += new_row.real_call_pct+ new_row.model_call_pct


           
            new_data.table.push(new_row)

        })
        new_data.sumcheck.model_call_delta =  new_data.summary.asset_expected_drawdown-new_data.sumcheck.total_call_pct
        new_data.sumcheck.fix =  ((new_data.summary.asset_expected_drawdown - new_data.sumcheck.actual_call_pct)/new_data.sumcheck.model_call_pct)


        new_data.table.map((row,index) => {
            new_data.table[index].corrected_model_call_pct =  new_data.table[index].model_call_pct* new_data.sumcheck.fix 
        })

        
        let total_return = (1+parseFloat(new_data.summary.asset_expected_irr))**parseInt(new_data.summary.asset_detention_period) 
         
        new_data.table.map((row,index) => {
            if( index >= parseInt(new_data.summary.asset_detention_period))  {
                let new_value = (  new_data.table[index -parseInt(new_data.summary.asset_detention_period) ].corrected_model_call_pct
                                    +   new_data.table[index -parseInt(new_data.summary.asset_detention_period) ].real_call_pct
                                    )*total_return

                                  
                 if(row.year == MoondanceLayout.currentYear() && new_value < row.real_distribution) {
                    new_data.table[index].corrected_model_distribution_pct = row.real_distribution_pct
                 } else {
                    new_data.table[index].corrected_model_distribution_pct =new_value
                 }


            } else {
                new_data.table[index].corrected_model_distribution = 0
            }
            new_data.table[index].corrected_model_distribution = new_data.table[index].corrected_model_distribution*parseInt(new_data.summary.position_commitment_in_asset_currency)
         });


         new_data.table.map((row,index) => {
            if(row.corrected_model_call_pct ==0 && row.real_call_pct != 0) {
                new_data.fixed_capital_call.push(-parseInt(row.real_call_pct*10000)/100 )
            } else {
                new_data.fixed_capital_call.push(-parseInt(row.corrected_model_call_pct*10000)/100 )
            }
            if(row.corrected_distribution_pct ==0 && row.real_distribution_pct != 0) {
                new_data.fixed_distribution.push(parseInt(row.real_distribution_pct*10000)/100 )
            } else {
                new_data.fixed_distribution.push(parseInt(row.corrected_model_distribution_pct*10000)/100 )
            }

         })
        console.log(new_data);
        setData(new_data);
    }
  

    useEffect(() => {
        console.log(props)
        if (props.id && props.id != '') {
            loadAssetModel(props.id);
        }


    }, [props]);
    function applyFix(){
        let AssetModelRequestData = {
           mc:data.fixed_capital_call,
            md:data.fixed_distribution
        }
        console.log(AssetModelRequestData)
        moondanceApi.saveAssetModel(data.summary.asset_id, AssetModelRequestData).then((response) => {
            setLastUpdate(Math.floor(Date.now()))
           if(response.success){
                addAlert({
                    status: 'success',
                    title: 'Update model',
                    message: 'Model has been updated !'
                })
           } else {
            addAlert({
                status: 'danger',
                title: 'Update model',
                message: 'Model has NOT been updated !'
            })
           }

           setAnyModal(false)
        })
        
        loadAssetModel(props.id);


        
    }
    return (

        <React.Fragment>
           


                    {(data.table) &&
                        <div >
                                <p>{data.summary.asset_name}</p>
                                <p>Model proposed Correction : x{MoondanceLayout.formatNumber(data.sumcheck.fix)}<br/>
                                <br/><b>This coefficient should be applied to future calls in order to achieve the expected drawdown ({MoondanceLayout.formatPercentage(data.summary.asset_expected_drawdown)} of commitment).<br/><br/>
                                Upon applying this adjustment, the model will recalculate its distribution to reflect the new capital call hypothesis, based on the set model IRR and detention period.<br/>
                                This approach allows for the adaptation of the model, or alternatively, you can manually modify the model's assumptions for more precise calibration.</b></p>
                               

                                <Button className ="btn btn-danger" 
                                        onClick={() => applyFix()}>Apply Correction</Button> 
                                <Link to={'/editPosition/' + props.id} className ="btn btn-primary" style={{marginLeft:'1em'}}>Edit Model</Link>
                                <hr/>
                                 
{(showTable ?                 <>  <MoondanceLayout.MoondanceTable
                                    rows={data.table}
                                    defaultSort={{ key: 'year', direction: 'ascending' }}
                                    columns={[
                                        { label: 'year', field: 'year',  align: 'right'  },
                                   
                                        { label: 'Actual Call %', field: 'real_call_pct',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['real_call_pct']))}, align: 'right',showTotal:true , format: 'percentage' },
                                        { label: 'Current Model Call %', field: 'model_call_pct',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['model_call_pct']))}, align: 'right',showTotal:true , format: 'percentage' },
                                        { label: 'Corrected Model Call %', field: 'corrected_model_call_pct',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['corrected_model_call_pct']))}, align: 'right',showTotal:true , format: 'percentage' },
                                        { label: 'Actual Distribution %', field: 'real_distribution_pct',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['real_distribution_pct']))}, align: 'right',showTotal:true , format: 'percentage' },
                                        { label: 'Current Model Distribution %', field: 'model_distribution_pct',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['model_distribution_pct']))}, align: 'right',showTotal:true , format: 'percentage' },
                                        { label: 'Corrected Model Distribution %', field: 'corrected_model_distribution_pct',customFormat: (row) => { return (MoondanceLayout.formatPercentage(row['corrected_model_distribution_pct']))}, align: 'right',showTotal:true , format: 'percentage' },
                                   
                                    ]}
                                />
                                <Button className ="btn btn-outline-primary" onClickCapture={()=>{setShowTable(false)}}>Hide Details</Button>  
                                </>
              :<Button className ="btn btn-outline-primary" onClickCapture={()=>{setShowTable(true)}}>Show Details</Button>)}               
                            </div>
                           }
                 
        </React.Fragment>

    )


};


export default AssetModel;