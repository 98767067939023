
import React, { useState, useContext, useEffect } from "react";
import {
  Card, CardBody, CardTitle, Col, Row, Container, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Select from "react-select";
import PositionChart from '../list_positions/positionChart.js';
import PositionChart2 from '../list_positions/positionChart2.js';
import UseMoondanceApi from "../helpers/api.js";
import { MoondanceContext } from '../../App.js';
import { buildAccountParameter, positionPerGroup, positionPerGroupGeographies, positionPerGroupSectors } from "../helpers/formatData.js";
import loading_icon from "../../assets/images/loading_icon.gif";

import { Link } from 'react-router-dom';
import PositionsSummary from '../list_positions/PositionSummary.js';
import PositionsCard from '../list_positions/list_positions.js';

import MoondanceLayout from "../helpers/layout.js";
import { set } from "lodash";
const Summary = () => {

  const moondanceApi = UseMoondanceApi();
  document.title = "List Positions | Moondance";
  const { selectedAccountList } = useContext(MoondanceContext);
  const { selectedStatus } = useContext(MoondanceContext);
  const [y_option, set_y_option] = useState([
    { label: 'Per Position', id: 'asset_name' },
    { label: 'Asset type', id: 'asset_type' },
    { label: 'Vintage', id: 'asset_vintage_year' },
    { label: 'Account', id: 'position_account_name' },
    { label: 'Currency', id: 'asset_currency' },
    { label: 'Intermediate', id: 'asset_intermediate' },
    { label: 'Geography', id: 'geography' },
    { label: 'Sectors', id: 'sectors' }
  ]);
  const [chartOption] = useState([

    { id: 'commit_geo', label: 'Commitement Per Geography', x: 'geography', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: true },
    { id: 'commit_sectors', label: 'Commitement Per Sector', x: 'sectors', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: true },
    { id: 'commit_type', label: 'Commitement Per Type', x: 'asset_type', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: true },
    { id: 'commit_vintage', label: 'Commitement Per Vintage', x: 'asset_vintage_year', y: 'position_commited_in_user_currency_at_inception_rate', horizontal: false },
    { id: 'nav_geo', label: 'NAV Per Geography', x: 'geography', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_sectors', label: 'NAV Per Sector', x: 'sectors', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_type', label: 'NAV Per Type', x: 'asset_type', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_vintage', label: 'NAV Per Vintage', x: 'asset_vintage_year', y: 'position_last_nav_in_user_currency', horizontal: false },
    { id: 'nav_geo', label: 'NAV Per Geography', x: 'geography', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_sectors', label: 'NAV Per Sector', x: 'sectors', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_type', label: 'NAV Per Type', x: 'asset_type', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_position', label: 'NAV Per Position', x: 'asset_name', y: 'position_last_nav_in_user_currency', horizontal: true },
    { id: 'nav_irr', label: 'IRR Per Position', x: 'asset_name', y: 'position_irr_in_user_currency', horizontal: true },
    { id: 'nav_tvpi', label: 'TVPI Per Position', x: 'asset_name', y: 'position_tvpi_in_user_currency', horizontal: true },

  ]);
  const [selected_chartOption, set_selected_chartOption] = useState('nav_vintage');


  const [x_option, set_x_option] = useState([
    { label: 'Calls and distributions (stacked)', id: 'a' },
    { label: 'Internal Rate of Return (IRR)', id: 'b' },
    { label: 'Total Value to Paid-In (TVPI)', id: 'c' },
    { label: 'Distributions to Paid-In (DPI)', id: 'f' },
    { label: 'Commitment', id: 'd' },
    { label: 'Estimated open Commitment', id: 'e' }
  ]);
  const [selected_y, set_selected_y] = useState('asset_name');
  const [selected_x, set_selected_x] = useState('a');
  const [DATA, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [chart_type_dropdown, set_chart_type_dropdown] = useState(false)

  const [actionDropDown_y, set_actionDropDown_y] = useState(false)
  const [y_key, set_y_key] = useState('position_account_name')
  const [y_key_label, set_y_key_label] = useState('Account')

  const [actionDropDown_x, set_actionDropDown_x] = useState(false)
  const [x_key, set_x_key] = useState('position_account_name')
  const [x_key_label, set_x_key_label] = useState('Account')

  const { userCurrency, setUserCurrency } = useContext(MoondanceContext);
  const [total, setTotal] = useState(null);
  const [average, setAverage] = useState(null);

  const { lastUpdate, setLastUpdate } = useContext(MoondanceContext);




  useEffect(() => {
    async function fetchData() {
      //console.log(selected_y)
      var api_parameters = {
        account: buildAccountParameter(selectedAccountList),
        status: buildAccountParameter(selectedStatus),
        calculateIrr: true,
        userCurrency: userCurrency,
      }
      try {
        if (api_parameters.account && api_parameters.account.length > 0) {
          var returnData = {}
          var data = await moondanceApi.fetchPositionsList(api_parameters);

          //console.log('MoondanceApi.fetchPositionsList from /Positions', data)

          if (data.data.length > 0) {
            returnData.listPositions = data.data
            if (selected_y === 'geography') {
              returnData.byGeographies = await moondanceApi.fetchAssetsGeographiesList(api_parameters);
              returnData.byGeographies = returnData.byGeographies.data
              returnData.statsPerType = positionPerGroupGeographies(returnData)

            } else if (selected_y === 'sectors') {
              returnData.bySectors = await moondanceApi.fetchAssetsSectorsList(api_parameters);
              returnData.bySectors = returnData.bySectors.data
              returnData.statsPerType = positionPerGroupSectors(returnData)

            } else {

              returnData.statsPerType = positionPerGroup(data.data, selected_y)

            }



            console.log('returnData', returnData)
            setData(returnData)
          }
          setLoading(false)
        }
      } catch (error) {
        console.log(error);
      }

    }
    fetchData();
  }, [selected_y, selectedAccountList, selectedStatus, selected_x, lastUpdate]);





  return (
    <React.Fragment>


      {DATA.listPositions && (
        <Row>
          <PositionsSummary data={DATA} />
        </Row>
      )}
    </React.Fragment>
  );

};// 

export default Summary;
